import React, { useState, useRef, useEffect } from "react";
import FileUploadContainer from "../common/fileUploader";
import { toastConfirm, toastError } from "../utils/toasts";
import { FileIcon, defaultStyles } from "react-file-icon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getFiles, renameFile, uploadFiles, deleteFile, createFolder } from "../../services/filesService";

const FileBrowser = ({ status, recordId, filesUrl, serviceFolder, onChange, canCreateFolder }) => {
  const [currentFolder, setCurrentFolder] = useState("");
  const [isEditingFolderName, setIsEditingFolderName] = useState(false);
  const [editFolderNameIndex, setEditFolderNameIndex] = useState(0);
  const [originalName, setOriginalName] = useState("");
  const [archivos, setArchivos] = useState([]);

  const nameRef = useRef(null);

  const onGetFiles = async () => {
    const files = await getFiles(recordId, serviceFolder, currentFolder);
    if (files) {
      setArchivos(files);
    }
  };

  const changeFolder = (newUrl) => {
    var current = currentFolder;
    if (newUrl.substr(newUrl.length - 1) !== "/" && newUrl.length > 1) newUrl += "/";
    current = newUrl;
    setIsEditingFolderName(false);
    setEditFolderNameIndex(-1);
    setCurrentFolder(current);
  };

  const onFileDelete = async (index) => {
    let files = archivos;
    const archivo = files[index].archivo;
    files.splice(index, 1);
    setArchivos(files);
    try {
      await deleteFile(recordId, serviceFolder, currentFolder, archivo);
      onGetFiles();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) toastError("Ese archivo ya fue eliminado");
    }
  };

  const changeFolderName = (index, fileName) => {
    setOriginalName(fileName);
    setIsEditingFolderName(true);
    setEditFolderNameIndex(index);
  };

  const nameChanged = async (e) => {
    const archivos = await renameFile(recordId, serviceFolder, currentFolder, originalName, e.target.value);
    if (archivos) setArchivos(archivos);
    setOriginalName("");
    setIsEditingFolderName(false);
    setEditFolderNameIndex(-1);
  };

  const onCreateFolder = async () => {
    const files = await createFolder(recordId, serviceFolder, currentFolder);
    if (files) {
      setArchivos(files);
    }
  };

  const onUploadFiles = async (uploadData, subfolder) => {
    const archivos = await uploadFiles(recordId, serviceFolder, subfolder, uploadData);
    if (archivos) setArchivos(archivos);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      nameChanged(e);
    }
  };

  useEffect(() => {
    onGetFiles();
  }, []);

  useEffect(() => {
    onGetFiles();
  }, [currentFolder]);

  useEffect(() => {
    if (isEditingFolderName) {
      if (nameRef.current) nameRef.current.focus();
    }
  }, [isEditingFolderName]);

  const breadCrumbs = "/" + currentFolder;

  return (
    <div className="col-6">
      <div className="card ">
        <div className="card-header">
          <div className="title-block">
            <span className="title">Archivos Asociados</span>
            <span className="subtitle">
              {breadCrumbs.split("/").map((item, index) => {
                if (index === 0)
                  return (
                    <a key="bread0" className="bread" onClick={() => changeFolder("")}>
                      Home /{" "}
                    </a>
                  );
                if (index + 2 === breadCrumbs.split("/").length) return <a key={"bread" + index}>{item} / </a>;
                if (index + 1 != breadCrumbs.split("/").length)
                  return (
                    <a key={"bread" + index} className="bread" onClick={() => changeFolder((breadCrumbs.split("/", index + 1).join("/") + "/").substr(1))}>
                      {item} /{" "}
                    </a>
                  );
              })}
            </span>
          </div>
          <div className="card-tools">
            {status != "C" && canCreateFolder && (
              <OverlayTrigger placement="top" overlay={<Tooltip>Nueva Carpeta</Tooltip>} delay={{ show: 250, hide: 400 }}>
                <button type="button" className="btn btn-tool">
                  <i className="fas fa-folder" onClick={onCreateFolder} />
                  {/* <i className="fas fa-folder" /> */}
                </button>
              </OverlayTrigger>
            )}
            <button type="button" className="btn btn-tool" data-card-widget="collapse">
              <i className="fas fa-minus" />
            </button>
            <button type="button" className="btn btn-tool" data-card-widget="maximize">
              <i className="fas fa-expand" />
            </button>
          </div>
        </div>
        {/* /.card-header */}
        {archivos.length > 0 && (
          <div className="card-body ">
            <div className="icons">
              {archivos.map((file, index) => {
                const extension = file.archivo.split(".").pop();
                if (!file.isFolder)
                  return (
                    <div key={"file" + index} className="icon">
                      <a href={filesUrl + recordId + "/" + currentFolder + file.archivo} target="_blank">
                        <FileIcon extension={extension} {...defaultStyles[extension]} />
                      </a>
                      {status != "C" && (
                        <i
                          className="fas fa-times text-danger-disabled top-right"
                          onClick={() =>
                            toastConfirm("¿ Eliminar archivo ?", () => {
                              onFileDelete(index);
                            })
                          }
                        ></i>
                      )}
                      {file.titulo.substr(0, 10) + (file.titulo.length > 10 ? "..." : "")}
                    </div>
                  );
                else
                  return (
                    <div className="icon" key={"file" + index}>
                      <a className="clickable" onClick={() => changeFolder(currentFolder + file.archivo)}>
                        <img src="/dist/img/folder.svg" style={{ width: "48px" }} />
                      </a>
                      {!isEditingFolderName && <span onClick={() => changeFolderName(index, file.archivo)}>{file.titulo.substr(0, 10) + (file.titulo.length > 10 ? "..." : "")}</span>}
                      {isEditingFolderName && index === editFolderNameIndex && (
                        <span>
                          <input ref={nameRef} type="text" id="folderName" name="folderName" placeholder="" className="form-control form-control-xs" onBlur={nameChanged} onKeyDown={onKeyDown} />
                        </span>
                      )}
                    </div>
                  );
              })}
            </div>
          </div>
        )}
        {/* /.card-body */}
        {status != "C" && (
          <div className="card-footer">
            <FileUploadContainer onUpload={(files) => onUploadFiles(files, currentFolder)} multiple={true} />
          </div>
        )}
        {/* /.card-footer*/}
      </div>
      {/*/.direct-chat */}
    </div>
  );
};

export default FileBrowser;

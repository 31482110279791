import React from "react";
import Form from "../common/newForm";
import { withRouter } from "react-router-dom";
import Joi from "joi-browser";
import { getPersona, savePersona } from "../../services/personasService";
import { getCondicionesIvaSelect } from "../../services/condicionesIvaService";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import moment from "moment";
import { tiposDocumentos, tiposTelefonos, tiposCargos } from "../utils/utils";
import { getConfig } from "../../config/config.js";
import Avatar from "react-avatar";
import AvatarEdit from "react-avatar-edit";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer/dist/ReactTabHotkeyReplacer";
import RoundButton from "../common/roundButton";
import ChildArray from "../common/childArray";
import FileBrowser from "../fileBrowser/fileBrowser";

// Gneral Focus Hook
// const utilizeFocus = () => {
//   const ref = React.createRef();
//   const setFocus = () => {
//     ref.current && ref.current.focus();
//   };
//   return { setFocus, ref };
// };

class PersonasForm extends Form {
  title = "Persona";

  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Personas",
      link: "/personas",
    },
    {
      title: "Datos Personales",
    },
  ];

  validateForm = true;

  representantesFormat = [
    { label: "Cargo", width: "20%", column: "cargo", type: "combo", options: tiposCargos },
    { label: "Apellido", width: "15%", column: "apellido", type: "text", options: [] },
    { label: "Nombre", width: "15%", column: "nombre", type: "text", options: [] },
    { label: "Teléfono ", width: "15%", column: "telefono", type: "phone", options: [] },
    { label: "Documento ", width: "5%", column: "tipoDocumento", type: "combo", options: tiposDocumentos },
    { label: "Nº", width: "15%", column: "documento", type: "dni", options: [] },
  ];

  telefonosFormat = [
    { label: "Tipo", width: "20%", column: "tipo", type: "combo", options: tiposTelefonos },
    { label: "Nº", width: "15%", column: "numero", type: "phone", options: [] },
  ];

  state = {
    menuLink: "/personas",
    action: "C",
    representante: {
      apellido: "",
      nombre: "",
      cargo: tiposCargos[0].value,
      tipoDocumento: tiposDocumentos[0].value,
      telefono: "",
      documento: "",
    },
    telefono: {
      tipo: tiposTelefonos[0].value,
      numero: "",
    },
    archivo: {
      titulo: "",
      archivo: "",
    },
    data: {
      tratamiento: "Sr.",
      personaJuridica: false,
      representantes: [],
      avatar: "",
      apellido: "",
      nombre: "",
      cuit: "",
      condicionIvaId: "",
      tipoDocumento: "",
      documento: "",
      email: "",
      domicilio: "",
      localidad: "",
      provincia: "",
      pais: "",
      telefonos: [],
      estadoCivil: "",
      fechaNacimiento: "",
      nombrePadre: "",
      nombreMadre: "",
      notas: "",
      archivos: [],
    },
    errors: {},
    condicionesIva: [],
    // condicionIvaId: "",
  };

  customJoi = Joi.extend((joi) => ({
    base: joi.string(),
    name: "string",
    language: {
      validCuit: "debe ser un CUIT/CUIL válido",
    },
    pre(value, state, options) {
      return value;
    },
    rules: [
      {
        name: "validCuit",
        setup(params) {},
        validate(params, value, state, options) {
          let lGoodCuit = true;
          const cCuit = value.trim().replace(/-/g, "").replace(/\//g, "");
          if (cCuit === "") return lGoodCuit;
          if (cCuit.length < 11) lGoodCuit = false;
          const nDni = parseInt(cCuit.substring(2, 10));
          const nHeader = parseInt(cCuit.substring(0, 2));
          if (nDni === 0) lGoodCuit = false;
          if (![20, 23, 24, 27, 30, 33, 34].includes(nHeader)) lGoodCuit = false;
          const cReversedCuit = cCuit.split("").reverse().join("");
          let nSum = 0;
          for (let i = 0; i < 7; i++) nSum += parseInt(cReversedCuit.substr(i, 1)) * (i + 1);
          for (let i = 7; i < 11; i++) nSum += parseInt(cReversedCuit.substr(i, 1)) * (i - 5);
          if (nSum % 11 !== 0) lGoodCuit = false;

          if (!lGoodCuit) return this.createError("string.validCuit", { v: value }, state, options);
          return value;
        },
      },
    ],
  }));

  schema = {
    _id: Joi.string(),
    tratamiento: this.customJoi.string().allow("").label("Tratamiento"),
    apellido: Joi.string()
      .label("Apellido / Razón Social")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            // case "string.min":
            //   return { message: "first msg" };
            // case "string.max":
            //   return { message: "second msg" };
            case "any.empty":
              return { message: "Debe ingresar apellido/razón social" };
          }
        });
      }),
    avatar: Joi.string().allow(null, "").label("Avatar"),
    nombre: Joi.string().allow(null, "").label("Nombre"),
    cuit: this.customJoi.string().allow("").label("CUIT").validCuit(),
    condicionIvaId: Joi.string()
      .label("Condición de Iva")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar Condición de IVA" };
          }
        });
      }),
    fechaNacimiento: Joi.string().allow("").label("Fecha de Nacimiento"),
    estadoCivil: Joi.string().allow("").label("Estado Civil"),
    tipoDocumento: Joi.string().allow("").label("Tipo de Documento"),
    documento: Joi.string().allow("").label("Documento"),
    domicilio: Joi.string().allow("").label("Domicilio"),
    localidad: Joi.string().allow("").label("Localidad"),
    provincia: Joi.string().allow("").label("Provincia"),
    pais: Joi.string().allow("").label("Pais"),
    email: Joi.string().email().optional().allow("").label("e-mail"),
    nombrePadre: Joi.string().allow("").label("Nombre del Padre"),
    nombreMadre: Joi.string().allow("").label("Nombre de la Madre"),
    personaJuridica: Joi.boolean(),
    notas: Joi.string().allow("").label("Notas"),
    representantes: Joi.array(),
    archivos: Joi.array(),
    telefonos: Joi.array(),
  };

  render() {
    const config = getConfig();
    const serviceFolder = "personas";
    const filesUrl = config.filesUrl + "/" + serviceFolder + "/";
    const { testArray, action, condicionesIva, telefono, representante, telefonoStatus, representanteStatus, editItem, editRepresentante } = this.state;
    const { nombre, apellido, avatar, personaJuridica, tratamiento, telefonos, representantes, archivos } = this.state.data;
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}

        {this.renderContentHeader(this.title, this.breadcrumbs, this.goBack)}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary mt-2">
                  <div className="card-header">
                    <h3 className="card-title">
                      <RoundButton onClick={() => this.goBack()} iconClass="fas fa-arrow-left" className="btn-transparent mr-2" tooltip="Volver" />
                      Datos Personales
                    </h3>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-2 text-center">
                          <div className="avatarContainer">
                            <Avatar className="avatar" name={nombre + " " + apellido} size="150" round="100%" src={avatar} />
                            {this.state.data._id && action != "C" && (
                              <div className="camera" data-toggle="modal" data-target="#modal-default">
                                <i className="fas fa-camera fa-4x "></i>
                              </div>
                            )}
                          </div>
                          {/* <img
                            src={`../../dist/img/user.jpg`}
                            style={{ borderRadius: "50%" }}
                            alt="Usuario"
                          /> */}
                        </div>
                        <div className="col-10">
                          <ReactTabHotkeyReplacer>
                            <div className="row mb-2">
                              <div className="col-3">
                                {!personaJuridica && (
                                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label className={"btn btn-secondary " + (tratamiento === "Sr." ? "focus active" : "")}>
                                      <input type="radio" disabled={action === "C" ? true : false} name="tratamiento" value="Sr." checked={tratamiento === "Sr."} onChange={this.handleChangeTratamiento} /> Sr
                                    </label>
                                    <label className={"btn btn-secondary " + (tratamiento === "Sra." ? "focus active" : "")}>
                                      <input type="radio" disabled={action === "C" ? true : false} name="tratamiento" value="Sra." selected checked={tratamiento === "Sra."} onChange={this.handleChangeTratamiento} /> Sra
                                    </label>
                                    <label className={"btn btn-secondary " + (tratamiento === "Srta." ? "focus active" : "")}>
                                      <input type="radio" disabled={action === "C" ? true : false} name="tratamiento" value="Srta." checked={tratamiento === "Srta."} onChange={this.handleChangeTratamiento} /> Srta
                                    </label>
                                    <label className={"btn btn-secondary " + (tratamiento === "" ? "focus active" : "")}>
                                      <input type="radio" disabled={action === "C" ? true : false} name="tratamiento" id="" checked={tratamiento === ""} onChange={this.handleChangeTratamiento} /> Otro
                                    </label>
                                  </div>
                                )}
                              </div>
                              <div className="col-6"></div>
                              {action === "C" && (
                                <div className="col-3">
                                  <BootstrapSwitchButton
                                    checked={this.state.data.personaJuridica}
                                    disabled
                                    style="w-100 mb-2"
                                    onstyle="dark"
                                    offstyle="secondary"
                                    onlabel="Persona Jurídica"
                                    offlabel="Persona Física"
                                    onChange={(value) => this.handleChangeSwitch(value)}
                                  />
                                </div>
                              )}
                              {action !== "C" && (
                                <div className="col-3">
                                  <BootstrapSwitchButton
                                    checked={this.state.data.personaJuridica}
                                    style="w-100 mb-2"
                                    onstyle="dark"
                                    offstyle="secondary"
                                    onlabel="Persona Jurídica"
                                    offlabel="Persona Física"
                                    onChange={(value) => this.handleChangeSwitch(value)}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="row">
                              {personaJuridica &&
                                this.renderInput("apellido", "Razón Social", "text", {
                                  float: true,
                                  columns: 12,
                                  autoFocus: true,
                                })}
                              {!personaJuridica &&
                                this.renderInput("apellido", "Apellido", "text", {
                                  float: true,
                                  columns: 6,
                                  autoFocus: true,
                                })}
                              {!personaJuridica &&
                                this.renderInput("nombre", "Nombre", "text", {
                                  float: true,
                                  columns: 6,
                                })}
                            </div>
                            {this.state.data._id && (
                              <React.Fragment>
                                <div className="row">
                                  <div className="col-5">
                                    {this.renderInput("domicilio", "Domicilio", "text", {
                                      float: true,
                                    })}
                                  </div>
                                  <div className="col-7">
                                    <div className="row">
                                      <div className="col-4">
                                        {this.renderInput("localidad", "Localidad", "text", {
                                          float: true,
                                        })}
                                      </div>
                                      <div className="col-4">
                                        {this.renderInput("provincia", "Provincia", "text", {
                                          float: true,
                                        })}
                                      </div>
                                      <div className="col-4">
                                        {this.renderInput("pais", "Pais", "text", {
                                          float: true,
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  {personaJuridica && <ChildArray data={telefonos} blankItem={telefono} editable={action === "C" ? false : true} format={this.telefonosFormat} label="Telefono(s)" onChange={this.handleChangeTelefonos} />}
                                </div>
                                <div className="row ">
                                  {personaJuridica && <>{this.renderChildArray(representantes, representante, action === "C" ? false : true, this.representantesFormat, "Representante(s)", this.handleChangeRepresentantes)}</>}
                                  {!personaJuridica && (
                                    <React.Fragment>
                                      {this.renderInput("tipoDocumento", "Tipo Documento", "combo", {
                                        float: true,
                                        columns: 2,
                                        options: tiposDocumentos,
                                      })}
                                      {this.renderInput("documento", "Documento", "dni", {
                                        float: true,
                                        columns: 2,
                                      })}
                                      {this.renderInput("email", "e-mail", "text", {
                                        float: true,
                                        columns: 8,
                                      })}
                                    </React.Fragment>
                                  )}
                                </div>

                                <div className="row ">
                                  <div className="col-6">
                                    {this.renderInput("notas", "Notas", "textarea", {
                                      float: true,
                                      rows: 10,
                                    })}
                                  </div>
                                  <FileBrowser
                                    status={action}
                                    recordId={this.state.data._id}
                                    serviceFolder={serviceFolder}
                                    filesUrl={filesUrl}
                                    canCreateFolder={true}
                                    // archivos={archivos}
                                    // onFileDelete={(index, subfolder) => this.handleFileDelete(index, subfolder)}
                                    // onFileUpload={(files, subfolder) => this.handleFileUpload(files, subfolder)}
                                    // onCreateFolder={this.handleCreateFolder}
                                    // onChangeFolder={(url) => this.handleFolderChange(url)}
                                    // onRename={(url, original, newName) => this.handleRename(url, original, newName)}
                                  />
                                </div>
                              </React.Fragment>
                            )}
                          </ReactTabHotkeyReplacer>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">{this.renderSubmit(this.state.data._id ? "Guardar" : "Continuar")}</div>
                  </form>
                </div>
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.container-fluid */}

          <div id="modal-default" className="modal fade">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-body justify-content-center">
                  <AvatarEdit
                    label="Seleccione Imagen"
                    width="100%"
                    height={400}
                    onCrop={this.handleAvatarCrop}
                    onClose={this.handleAvatarClose}
                    onBeforeFileLoad={this.onBeforeFileLoad}
                    // src={this.state.data.avatar}
                  />
                </div>
                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.handleSaveChild}
                  >
                    Guardar
                  </button>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
        </section>
      </React.Fragment>
    );
  }

  handleChangeRepresentantes = (array) => {
    const { data } = this.state;
    data.representantes = array;
    this.setState({ data });
  };

  handleChangeTelefonos = (array) => {
    const { data } = this.state;
    data.telefonos = array;
    this.setState({ data });
  };

  handleAvatarCrop = (preview) => {
    const data = { ...this.state.data };
    data.avatar = preview;
    this.setState({ data });
  };

  handleAvatarClose = () => {
    const data = { ...this.state.data };
    data.avatar = null;
    this.setState({ data });
  };

  handleChangeSwitch = (value) => {
    const data = { ...this.state.data };
    data.personaJuridica = value;
    if (value) data.tratamiento = "";
    else data.tratamiento = "Sr.";
    this.setState({ data });
  };

  handleChangeTratamiento = (event) => {
    const data = { ...this.state.data };
    data.tratamiento = event.target.value;
    this.setState({ data });
  };

  componentDidMount = async () => {
    this.hookMounted();

    const { onLoading, onDoneLoading } = this.props;
    const recordId = this.props.match.params.id;
    let record = {};

    onLoading();
    const condicionesIva = await getCondicionesIvaSelect();
    // this.apellido.setFocus();
    if (recordId !== "nuevo" && recordId !== "nueva") record = await getPersona(recordId);
    onDoneLoading();
    if (recordId === "nuevo" || recordId === "nueva") {
      const consumidorFinal = condicionesIva.find((item) => item.label === "Consumidor Final");
      const data = { ...this.state.data };
      data.condicionIvaId = consumidorFinal.value;
      data.localidad = "La Plata";
      data.provincia = "Buenos Aires";
      data.pais = "Argentina";
      this.setState({
        condicionesIva,
        data,
      });
      this.defaultValues();
      return;
    } else {
      if (!record) return this.props.history.replace("/notfound");
      const selectedCondicionIva = {
        label: record.condicionIva.descripcion,
        value: record.condicionIva._id,
      };
      this.setState({
        data: this.mapToViewModel(record),
        condicionesIva,
        condicionIvaId: selectedCondicionIva,
      });
    }
  };

  mapToViewModel = (persona) => {
    return {
      _id: persona._id,
      avatar: persona.avatar,
      personaJuridica: persona.personaJuridica,
      tratamiento: persona.tratamiento,
      apellido: persona.apellido,
      nombre: persona.nombre,
      cuit: persona.cuit,
      condicionIvaId: persona.condicionIva._id,
      fechaNacimiento: moment(persona.fechaNacimiento).format("DD/MM/YYYY"),
      estadoCivil: persona.estadoCivil,
      domicilio: persona.domicilio,
      localidad: persona.localidad,
      provincia: persona.provincia,
      pais: persona.pais,
      tipoDocumento: {
        label: persona.tipoDocumento,
        value: persona.tipoDocumento,
      },
      documento: persona.documento,
      email: persona.email,
      nombrePadre: persona.nombrePadre,
      nombreMadre: persona.nombreMadre,
      notas: persona.notas,
      telefonos: persona.telefonos,
      representantes: persona.representantes,
      archivos: persona.archivos,
    };
  };

  doSubmit = async () => {
    try {
      const newId = await savePersona(this.state.data);
      if (this.state.data._id) this.props.history.push("/personas");
      else {
        if (newId) {
          const data = { ...this.state.data };
          this.setState({
            data: { ...data, _id: newId },
          });
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };
}

export default withRouter(PersonasForm);

import React, { useState, useEffect } from "react";
import Input from "../common/input";

const PillList = ({ action, label, options, pillList, onChange, placeholder }) => {
  const [opts, setOpts] = useState(options);
  const [list, setList] = useState(pillList);
  const [selectValue, setSelectValue] = useState("");
  const [extras, setExtras] = useState([]);
  const [extra1, setExtra1] = useState();
  const [extra2, setExtra2] = useState();
  const [extra3, setExtra3] = useState();
  const [extra4, setExtra4] = useState();
  const [extra5, setExtra5] = useState();

  const handleSelectChange = (selectedOption, name) => {
    if (selectedOption.extras.length > 0) {
      setSelectValue(selectedOption);
      setExtras(selectedOption.extras);
    } else {
      setExtras([]);
      const newList = [...list];
      newList.push(selectedOption);
      setList(newList);
      const newOpts = [...opts];
      const index = newOpts.findIndex((item) => item.value === selectedOption.value);
      newOpts.splice(index, 1);
      setOpts(newOpts);
      setSelectValue("");
      onChange(newList);
    }
  };

  const handleKeyPress = (e) => {
    if (!((e.keyCode === 13 || e.keyCode === 9) && extras)) setExtras([]);
  };

  const handleSelectChangeExtra = (selectedOption, name) => {
    // console.log(selectedOption.value);
    // console.log(name.name);
    switch (name.name) {
      case "extra1":
        setExtra1(selectedOption);
        break;
      case "extra2":
        setExtra2(selectedOption);
        break;
      case "extra3":
        setExtra3(selectedOption);
        break;
      case "extra4":
        setExtra4(selectedOption);
        break;
      case "extra5":
        setExtra5(selectedOption);
        break;
    }
    // console.log(extra1);
    // console.log(extra2);
    // console.log(extra3);
    // console.log(extra4);
    // console.log(extra5);
  };

  const handleAddWithExtras = () => {
    const newList = [...list];
    const newItem = selectValue;
    delete newItem.extras;
    newItem.extra = (extra1 ? extra1 : "") + (extra2 ? " " + extra2 : "") + (extra3 ? " " + extra3 : "") + (extra4 ? " " + extra4 : "") + (extra5 ? " " + extra5 : "");
    newList.push(newItem);
    setList(newList);
    const newOpts = [...opts];
    const index = newOpts.findIndex((item) => item.value === selectValue.value);
    newOpts.splice(index, 1);
    setOpts(newOpts);
    setExtra1();
    setExtra2();
    setExtra3();
    setExtra4();
    setExtra5();
    setSelectValue("");
    setExtras([]);
    onChange(newList);
  };

  const deletePill = (value) => {
    const newList = [...list];
    const index = newList.findIndex((item) => item.value === value);
    const newOpts = [...opts];
    newOpts.push(newList[index]);
    newOpts.sort((a, b) => {
      let fa = a.label.toLowerCase(),
        fb = b.label.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    newList.splice(index, 1);
    setList(newList);
    setOpts(newOpts);
    onChange(newList);
  };

  return (
    <>
      {list && (
        <div className="col-12 mb-2 mt-2">
          <div className="group-box">
            <label className="group-box-label">{label}</label>
            {action != "C" && (
              <>
                <div className="row mt-2 mr-1 ml-1">
                  <div className="col-4">
                    <Input combo preventForward value={selectValue} options={opts} float label={placeholder} onChange={handleSelectChange} overrideKeyDown={handleKeyPress} />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      {extras.length > 0 &&
                        extras.map((item, index) => {
                          if (item.tipo === "combo")
                            return (
                              <>
                                <div key={Math.random()} className="col-4">
                                  <Input combo options={item.options} name={`extra${index + 1}`} onChange={handleSelectChangeExtra} />
                                </div>
                                {index === extras.length - 1 ? (
                                  <div className="col-2 my-auto">
                                    <div className="form-group">
                                      <button type="button" className="btn btn-small btn-secondary" onClick={handleAddWithExtras}>
                                        Agregar
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                        })}
                    </div>
                  </div>
                </div>
                {list.length > 0 && <hr />}
              </>
            )}
            <div className="container-fluid mb-2">
              {list.map((element) => (
                <span key={Math.random()} className="badge  badge-secondary p-3 m-1">
                  <strong>{element.label + (element.extra ? ": " + element.extra : "")}</strong>
                  {action != "C" && <i className="fas fa-times ml-2 clickable" onClick={() => deletePill(element.value)}></i>}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PillList;

import "./noConnection.css";

import React from "react";

export default function NotFound(props) {
  const goHome = () => {
    props.history.replace("/");
  };

  return (
    <React.Fragment>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3>Changos! Hay algún problema de conexión</h3>
            <div className="flex-column justify-content-center align-items-center">
              <svg
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                width="200px"
                height="200px"
                viewBox="0 0 862.898 862.9"
                enableBackground="new 0 0 862.898 862.9"
              >
                <g>
                  <g>
                    <circle cx="385.6" cy="656.1" r="79.8" />
                    <path
                      d="M561.699,401c-15.801-10.3-32.601-19.198-50.199-26.6c-39.899-16.9-82.301-25.5-126-25.5
			c-44.602,0-87.899,8.9-128.601,26.6c-39.3,17-74.3,41.302-104.101,72.2L253.5,545c34.898-36.1,81.8-56,132-56
			c49.001,0,95.101,19.102,129.801,53.802l25.398-25.399L493.001,469.7L561.699,401z"
                    />
                    <path
                      d="M385.6,267.1c107.601,0,208.9,41.7,285.3,117.399l98.5-99.501c-50-49.5-108.1-88.398-172.699-115.6
			C529.802,141.3,458.7,127,385.6,127c-73.601,0-145,14.4-212.301,42.9C108.3,197.4,50,236.7,0,286.8l99,99.002
			C175.5,309.301,277.3,267.1,385.6,267.1z"
                    />
                    <polygon
                      points="616.801,402.5 549.699,469.599 639.199,559.099 549.699,648.599 616.801,715.7 706.301,626.2 795.801,715.7 
			862.899,648.599 773.399,559.099 862.899,469.599 795.801,402.5 706.301,492 		"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <h2>por favor, reintentá en un rato</h2>
          <div className="col text-center">
            <button className="btn btn-secondary" onClick={goHome}>
              Volver
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

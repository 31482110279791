import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import ContentHeader from "../common/contentHeader";
import jwtDecode from "jwt-decode";
import { getConfig } from "../../config/config.js";
import { getUser } from "../../services/userService";
import ChildArray from "./childArray";
import { toastError } from "../utils/toasts";
import { getPermisos } from "../../services/permisosService";

const config = getConfig();
const childRecordsPerPage = config.childRecordsPerPage;

class Form extends Component {
  validateForm = false;
  validateChild = false;
  childPageLength = childRecordsPerPage;

  state = {
    data: {},
    errors: {},
    action: "",
    childIndex: 0,
    child: {},
    childAction: "",
    childPage: 1,
    showModal: false,
    isSaving: false,
    isEditingChild: false,
    menuLink: "/",
    canDo: {
      canAdd: false,
      canView: false,
      canModify: false,
      canDelete: false,
      canList: false,
      canExport: false,
      special1: false,
      special2: false,
      special3: false,
      special4: false,
      special5: false,
      special6: false,
      special7: false,
      special8: false,
      special9: false,
      special10: false,
    },
  };
  schema = {};
  childSchema = {};

  renderContentHeader = (title, breadcrumbs, back) => {
    return <ContentHeader title={title} breadcrumbs={breadcrumbs} />;
  };

  goBack = () => {
    this.props.history.goBack();
  };

  renderInput = (name, label, type = "text", extras) => {
    const { errors, data, action } = this.state;
    var inputProps = { ...extras };
    if (label) inputProps.label = label;
    // if (floatLabel) inputProps.float = "true";
    // if (size) inputProps.size = size;
    if (action === "C") {
      inputProps.disabled = true;
      if (type === "combo" || type === "asyncCombo" || type === "asyncCreatable") inputProps.isDisabled = true;
    }
    type = type.toUpperCase();
    switch (type.toUpperCase()) {
      case "TOGGLE":
        inputProps.toggle = "true";
        break;
      case "CHECK":
        inputProps.check = "checkbox";
        break;
      case "DATE":
        inputProps.date = "true";
        break;
      case "INTEGER":
        inputProps.integer = "true";
        break;
      case "NUMBER":
        inputProps.number = "true";
        break;
      case "FLOAT":
        inputProps.float = "true";
        break;
      case "CUIT":
        inputProps.cuit = "true";
        break;
      case "CURRENCY":
        inputProps.currency = "true";
        break;
      case "TEXTAREA":
        inputProps.textarea = "true";
        break;
      case "PHONE":
        inputProps.phone = "true";
        break;
      case "DNI":
        inputProps.dni = "true";
        break;
      case "COMBO":
        // if (options) {
        inputProps.combo = true;
        //   inputProps.options = options;
        // }
        break;
      case "ASYNCCOMBO":
        inputProps.asyncCombo = true;
        break;
      case "ASYNCCREATABLE":
        inputProps.asyncCreatable = true;
        break;
      default:
        inputProps.type = "text";
    }
    // console.log(name);
    // console.log(data[name]);

    return (
      <React.Fragment>
        {inputProps.asyncCreatable && <Input name={name} value={data[name]} error={errors[name]} onChange={this.handleAsyncChange} {...inputProps} />}
        {inputProps.asyncCombo && <Input name={name} value={data[name]} error={errors[name]} onChange={this.handleAsyncChange} {...inputProps} />}
        {inputProps.combo && <Input name={name} value={data[name]} error={errors[name]} onChange={this.handleSelectChange} {...inputProps} />}
        {!inputProps.combo && !inputProps.asyncCombo && !inputProps.asyncCreatable && (inputProps.toggle || inputProps.check) && (
          <Input name={name} value={data[name]} error={errors[name]} checked={data[name]} onChange={this.handleChange} {...inputProps} />
        )}
        {!inputProps.combo && !inputProps.asyncCombo && !inputProps.asyncCreatable && !(inputProps.toggle || inputProps.check) && <Input name={name} value={data[name]} error={errors[name]} onChange={this.handleChange} {...inputProps} />}
      </React.Fragment>
    );
  };

  renderChildArray = (data, blankItem, editable, format, label, onChange) => {
    return <ChildArray data={data} blankItem={blankItem} editable={editable} format={format} label={label} onChange={onChange} onIsEditing={(isEditing) => this.handleEditingChildArray(isEditing)} />;
  };

  handleEditingChildArray = (isEditingChild) => {
    this.setState({ isEditingChild });
  };

  renderSubmit(label) {
    const { action } = this.state;
    return (
      <React.Fragment>
        {action !== "C" && (
          <button type="button" onClick={this.handleSubmit} disabled={this.state.isEditingChild} className="btn btn-secondary float-right">
            {label}
          </button>
        )}
        {action === "C" && (
          <button type="button" onClick={this.goBack} className="btn btn-secondary float-right">
            Volver
          </button>
        )}
      </React.Fragment>
    );
  }

  renderSubmitToMain(label) {
    const { action } = this.state;
    return (
      <React.Fragment>
        {action !== "C" && (
          <button type="button" onClick={this.handleSubmitToMain} disabled={this.state.isEditingChild} className="btn btn-secondary float-right">
            {label}
          </button>
        )}
        {action === "C" && (
          <button type="button" onClick={this.goBack} className="btn btn-secondary float-right">
            Volver
          </button>
        )}
      </React.Fragment>
    );
  }

  handleChange = (e) => {
    const { currentTarget: input } = e;
    const inputMode = e.target.inputMode;
    const errors = { ...this.state.errors };
    if (this.validateForm) {
      const errorMessage = this.validateProperty(input, inputMode);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
    } else delete errors[input.name];
    const data = { ...this.state.data };
    if (input.type === "checkbox") data[input.name] = input.checked;
    else data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleAsyncChange = (selectedOption, name) => {
    const data = { ...this.state.data };
    data[name.name] = selectedOption;
    this.setState({ data });
  };

  handleSelectChange = (selectedOption, name) => {
    const input = name.name;
    // const value = selectedOption.value;
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    if (this.validateForm) {
      const errorMessage = this.validateSelect(input, selectedOption.value);
      if (errorMessage) errors[input] = errorMessage;
      else delete errors[input];
    } else delete errors[input];
    data[input] = selectedOption;
    // data[input] = value;
    // this.setState({ [input]: selectedOption });
    this.setState({ data, errors });
  };

  validateRules = () => {
    return null;
  };

  validate = () => {
    let errors = {};
    const data = { ...this.state.data };
    const options = { abortEarly: false };
    if (this.validateForm) {
      Object.keys(data).map(function (key, index) {
        if ((key.toLowerCase().includes("dni") || key.toLowerCase().includes("documento")) && !key.toLowerCase().includes("tipo")) {
          const valor = data[key];
          const stripped = valor.replaceAll(".", "");
          if (stripped.length === 8) {
            const nuevoValor = stripped.substring(0, 2) + "." + stripped.substring(2, 5) + "." + stripped.substring(5, 8);
            data[key] = nuevoValor;
          }
          if (stripped.length === 7) {
            const nuevoValor = stripped.substring(0, 1) + "." + stripped.substring(1, 4) + "." + stripped.substring(4, 7);
            data[key] = nuevoValor;
          }
        }
        if (typeof data[key] === "object" && data[key] && data[key].value && typeof data[key].value === "string") {
          data[key] = data[key].value;
        }
      });
      let { error } = Joi.validate(data, this.schema, options);
      if (!error) {
        error = this.validateRules();
        if (!error) {
          Object.keys(data).map(function (key, index) {
            // console.log(key);
            // if (key === "titular") {
            //   console.log(data[key]);
            // }
            if (typeof data[key] === "object" && data[key] && data[key].value) data[key] = data[key].value;
          });

          return null;
        }
        error.map((error) => {
          toastError(error.message, 2500);
          return (errors[error.path] = error.message);
        });
        console.log(errors);
        return errors;
      } else {
        error.details.map((validationError) => {
          return (errors[validationError.path[0]] = validationError.message);
        });
        console.log(errors);
        return errors;
      }
    } else return null;
  };

  validateProperty = (input, inputMode) => {
    let { name, value } = input;
    if (inputMode === "numeric") {
      const index = value.indexOf(" ");
      value = parseFloat(value.substr(value.indexOf(" ") + 1));
    }
    const object = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(object, schema);
    // console.log(error);
    return error ? error.details[0].message : null;
  };

  validateSelect = (name, value) => {
    const object = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(object, schema);
    return error ? error.details[0].message : null;
  };

  prepareData = () => {};

  handleSubmit = (event) => {
    this.prepareData();

    if (event) event.preventDefault();
    const checkErrors = this.validate();
    this.setState({ errors: checkErrors || {} });
    if (checkErrors) {
      // console.log(checkErrors);
      return;
    }
    // console.log("sin errores");
    this.doSubmit();
  };

  handleSubmitToMain = (e) => {
    this.prepareData();

    if (e) e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      console.log(errors);
      return;
    }
    // console.log("sin errores");
    this.doSubmitToMain();
  };

  getDataFromServer = async (recordId) => {
    return;
  };

  getExtraData = async (record) => {
    return;
  };

  defaultValues = () => {};

  hookMounted = () => {
    if (this.props.history.location.state && this.props.history.location.state.action) this.setState({ action: this.props.history.location.state.action });
    else {
      const recordId = this.props.match.params.id;
      if (recordId === "nuevo" || recordId === "nueva") this.setState({ action: "A" });
      else this.setState({ action: "C" });
    }
  };

  componentWillMount = async () => {
    const jwt = localStorage.getItem("token");
    const decoded = jwt ? jwtDecode(jwt) : null;
    const user = await getUser(decoded.user._id);
    if (this.state.user) this.setState({ user });
    const permisos = await getPermisos(user.profile._id);
    if (!permisos) return this.props.history.replace("/notallowed");
    var found = permisos.find((element) => element.menu.link === this.state.menuLink);
    if (!found && !user.profile.isAdmin && !user.isSuperAdmin) return this.props.history.replace("/notallowed");
    else {
      const canDo = {
        canAdd: user.profile.isAdmin || user.isSuperAdmin ? true : found.canAdd,
        canView: user.profile.isAdmin || user.isSuperAdmin ? true : found.canView,
        canModify: user.profile.isAdmin || user.isSuperAdmin ? true : found.canModify,
        canDelete: user.profile.isAdmin || user.isSuperAdmin ? true : found.canDelete,
        canList: user.profile.isAdmin || user.isSuperAdmin ? true : found.canList,
        canExport: user.profile.isAdmin || user.isSuperAdmin ? true : found.canExport,
        special1: user.profile.isAdmin || user.isSuperAdmin ? true : found.special1,
        special2: user.profile.isAdmin || user.isSuperAdmin ? true : found.special2,
        special3: user.profile.isAdmin || user.isSuperAdmin ? true : found.special3,
        special4: user.profile.isAdmin || user.isSuperAdmin ? true : found.special4,
        special5: user.profile.isAdmin || user.isSuperAdmin ? true : found.special5,
        special6: user.profile.isAdmin || user.isSuperAdmin ? true : found.special6,
        special7: user.profile.isAdmin || user.isSuperAdmin ? true : found.special7,
        special8: user.profile.isAdmin || user.isSuperAdmin ? true : found.special8,
        special9: user.profile.isAdmin || user.isSuperAdmin ? true : found.special9,
        special10: user.profile.isAdmin || user.isSuperAdmin ? true : found.special10,
      };
      this.setState({ permisos: canDo });
    }
  };

  componentDidMount = async () => {
    this.hookMounted();
    const { onLoading, onDoneLoading, action } = this.props;
    const recordId = this.props.match.params.id;
    let record = {};
    onLoading();
    if (recordId !== "nuevo" && recordId !== "nueva") record = await this.getDataFromServer(recordId);
    onDoneLoading();
    if (record === "NETWORK ERROR") return this.props.history.replace("/noconnection");
    await this.getExtraData(record);
    if (recordId === "nuevo" || recordId === "nueva") {
      this.defaultValues();
      return;
    } else {
      if (!record) return this.props.history.replace("/notfound");
      this.setState({ data: this.mapToViewModel(record) });
    }
  };
}

export default Form;

import React from "react";
import Joi from "joi-browser";
import Form from "../common/newForm";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer";
import RoundButton from "../common/roundButton";
import jwtDecode from "jwt-decode";
import { withRouter } from "react-router-dom";
import { getUser } from "../../services/userService";
import { getProfile, saveProfile } from "../../services/profilesService";
import { getPermisos } from "../../services/permisosService";
import { getMenues } from "../../services/menuesService";

class ProfilesForm extends Form {
  title = "Perfil de Usuario";

  validateForm = true;

  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Perfiles de Usuario",
      link: "/profiles",
    },
    {
      title: "Detalles",
    },
  ];

  state = {
    menuLink: "/profiles",
    action: "",
    data: { descripcion: "", isAdmin: false, permisos: null },
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    descripcion: Joi.string()
      .label("Descripción")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            // case "string.min":
            //   return { message: "first msg" };
            // case "string.max":
            //   return { message: "second msg" };
            case "any.empty":
              return { message: "Debe ingresar una descripción" };
          }
        });
      }),
    isAdmin: Joi.boolean(),
    permisos: Joi.array(),
  };

  setPermisos = async (recordId) => {
    const jwt = localStorage.getItem("token");
    const selectedModule = localStorage.getItem("selectedModule");
    const menues = await getMenues(selectedModule);
    const permisos = await getPermisos(recordId);
    const newPermisos = [];
    menues.forEach((element) => {
      const newRow = element;
      if (element.submenus.length > 0) {
        const newSubMenus = [];
        element.submenus.forEach((subelement) => {
          var newSubElement = subelement;
          if (subelement.submenus.length > 0) {
            const newSub2Menus = [];
            subelement.submenus.forEach((sub2element) => {
              var newSub2Element = sub2element;
              const found = permisos.find((permiso) => permiso.menu._id === sub2element._id);
              if (found) newSub2Element.permisos = found;
              else {
                const toAdd = {
                  menu: { _id: sub2element._id },
                  canAdd: false,
                  canView: false,
                  canModify: false,
                  canDelete: false,
                  canList: false,
                  canExport: false,
                  special1: false,
                  special2: false,
                  special3: false,
                  special4: false,
                  special5: false,
                  special6: false,
                  special7: false,
                  special8: false,
                  special9: false,
                  special10: false,
                };
                newSub2Element.permisos = toAdd;
              }
              newSub2Menus.push(newSub2Element);
            });
            newSubElement.submenus = newSub2Menus;
            newSubMenus.push(newSubElement);
          } else {
            const found = permisos.find((permiso) => permiso.menu._id === subelement._id);
            if (found) newSubElement.permisos = found;
            else {
              const toAdd = {
                menu: { _id: subelement._id },
                canAdd: false,
                canView: false,
                canModify: false,
                canDelete: false,
                canList: false,
                canExport: false,
                special1: false,
                special2: false,
                special3: false,
                special4: false,
                special5: false,
                special6: false,
                special7: false,
                special8: false,
                special9: false,
                special10: false,
              };
              newSubElement.permisos = toAdd;
            }
            newSubMenus.push(newSubElement);
          }
          newRow.submenus = newSubMenus;
        });
      } else {
        const found = permisos.find((permiso) => permiso.menu._id === element._id);
        if (found) newRow.permisos = found;
        else {
          const toAdd = {
            menu: { _id: element._id },
            canAdd: false,
            canView: false,
            canModify: false,
            canDelete: false,
            canList: false,
            canExport: false,
            special1: false,
            special2: false,
            special3: false,
            special4: false,
            special5: false,
            special6: false,
            special7: false,
            special8: false,
            special9: false,
            special10: false,
          };
          newRow.permisos = toAdd;
        }
        // newPermisos.push(element);
      }
      newPermisos.push(newRow);
    });
    return newPermisos;
  };

  getDataFromServer = async (recordId) => {
    const record = await getProfile(recordId);
    const newPermisos = await this.setPermisos(recordId);
    record.permisos = newPermisos;
    return record;
  };

  defaultValues = async () => {
    const newPermisos = await this.setPermisos("000000000000");
    const record = { descripcion: "", isAdmin: false, permisos: newPermisos };
    this.setState({ data: record });
  };

  mapToViewModel = (profile) => {
    return {
      _id: profile._id,
      descripcion: profile.descripcion,
      isAdmin: profile.isAdmin,
      permisos: profile.permisos,
    };
  };

  checkState = (id, option) => {
    const { permisos } = this.state.data;
    var returnValue = false;
    if (permisos) {
      var found = permisos.find((item) => item._id === id);
      if (found) {
        switch (option) {
          case "A":
            returnValue = found.permisos.canAdd;
            break;
          case "V":
            returnValue = found.permisos.canView;
            break;
          case "M":
            returnValue = found.permisos.canModify;
            break;
          case "D":
            returnValue = found.permisos.canDelete;
            break;
          case "L":
            returnValue = found.permisos.canList;
            break;
          case "E":
            returnValue = found.permisos.canExport;
            break;
          case "1":
            returnValue = found.permisos.special1;
            break;
          case "2":
            returnValue = found.permisos.special2;
            break;
          case "3":
            returnValue = found.permisos.special3;
            break;
          case "4":
            returnValue = found.permisos.special4;
            break;
          case "5":
            returnValue = found.permisos.special5;
            break;
          case "6":
            returnValue = found.permisos.special6;
            break;
          case "7":
            returnValue = found.permisos.special7;
            break;
          case "8":
            returnValue = found.permisos.special8;
            break;
          case "9":
            returnValue = found.permisos.special9;
            break;
          case "10":
            returnValue = found.permisos.special10;
            break;
        }
      } else {
        permisos.forEach((item) => {
          if (item.submenus.length > 0) {
            found = item.submenus.find((subitem) => subitem._id === id);
            if (found) {
              switch (option) {
                case "A":
                  returnValue = found.permisos.canAdd;
                  break;
                case "V":
                  returnValue = found.permisos.canView;
                  break;
                case "M":
                  returnValue = found.permisos.canModify;
                  break;
                case "D":
                  returnValue = found.permisos.canDelete;
                  break;
                case "L":
                  returnValue = found.permisos.canList;
                  break;
                case "E":
                  returnValue = found.permisos.canExport;
                  break;
                case "1":
                  returnValue = found.permisos.special1;
                  break;
                case "2":
                  returnValue = found.permisos.special2;
                  break;
                case "3":
                  returnValue = found.permisos.special3;
                  break;
                case "4":
                  returnValue = found.permisos.special4;
                  break;
                case "5":
                  returnValue = found.permisos.special5;
                  break;
                case "6":
                  returnValue = found.permisos.special6;
                  break;
                case "7":
                  returnValue = found.permisos.special7;
                  break;
                case "8":
                  returnValue = found.permisos.special8;
                  break;
                case "9":
                  returnValue = found.permisos.special9;
                  break;
                case "10":
                  returnValue = found.permisos.special10;
                  break;
              }
            } else {
              item.submenus.forEach((subitem) => {
                if (subitem.submenus.length > 0) {
                  found = subitem.submenus.find((sub2item) => sub2item._id === id);
                  if (found) {
                    switch (option) {
                      case "A":
                        returnValue = found.permisos.canAdd;
                        break;
                      case "V":
                        returnValue = found.permisos.canView;
                        break;
                      case "M":
                        returnValue = found.permisos.canModify;
                        break;
                      case "D":
                        returnValue = found.permisos.canDelete;
                        break;
                      case "L":
                        returnValue = found.permisos.canList;
                        break;
                      case "E":
                        returnValue = found.permisos.canExport;
                        break;
                      case "1":
                        returnValue = found.permisos.special1;
                        break;
                      case "2":
                        returnValue = found.permisos.special2;
                        break;
                      case "3":
                        returnValue = found.permisos.special3;
                        break;
                      case "4":
                        returnValue = found.permisos.special4;
                        break;
                      case "5":
                        returnValue = found.permisos.special5;
                        break;
                      case "6":
                        returnValue = found.permisos.special6;
                        break;
                      case "7":
                        returnValue = found.permisos.special7;
                        break;
                      case "8":
                        returnValue = found.permisos.special8;
                        break;
                      case "9":
                        returnValue = found.permisos.special9;
                        break;
                      case "10":
                        returnValue = found.permisos.special10;
                        break;
                    }
                  }
                }
              });
            }
          }
        });
      }
    }
    return returnValue;
  };

  addRow = (id) => {
    this.changeRow(id, true);
  };

  removeRow = (id) => {
    this.changeRow(id, false);
  };

  changeRow = (id, changeValue) => {
    const { permisos } = this.state.data;
    if (permisos) {
      var found = permisos.findIndex((item) => item._id === id);
      if (found >= 0) {
        permisos[found].permisos.canAdd = changeValue;
        permisos[found].permisos.canView = changeValue;
        permisos[found].permisos.canModify = changeValue;
        permisos[found].permisos.canDelete = changeValue;
        permisos[found].permisos.canList = changeValue;
        permisos[found].permisos.canExport = changeValue;
        permisos[found].permisos.special1 = changeValue;
        permisos[found].permisos.special2 = changeValue;
        permisos[found].permisos.special3 = changeValue;
        permisos[found].permisos.special4 = changeValue;
        permisos[found].permisos.special5 = changeValue;
        permisos[found].permisos.special6 = changeValue;
        permisos[found].permisos.special7 = changeValue;
        permisos[found].permisos.special8 = changeValue;
        permisos[found].permisos.special9 = changeValue;
        permisos[found].permisos.special10 = changeValue;
        this.setState({ data: { ...this.state.data, permisos } });
      } else {
        permisos.forEach((item, index) => {
          if (item.submenus.length >= 0) {
            found = item.submenus.findIndex((subitem) => subitem._id === id);
            if (found >= 0) {
              permisos[index].submenus[found].permisos.canAdd = changeValue;
              permisos[index].submenus[found].permisos.canView = changeValue;
              permisos[index].submenus[found].permisos.canModify = changeValue;
              permisos[index].submenus[found].permisos.canDelete = changeValue;
              permisos[index].submenus[found].permisos.canList = changeValue;
              permisos[index].submenus[found].permisos.canExport = changeValue;
              permisos[index].submenus[found].permisos.special1 = changeValue;
              permisos[index].submenus[found].permisos.special2 = changeValue;
              permisos[index].submenus[found].permisos.special3 = changeValue;
              permisos[index].submenus[found].permisos.special4 = changeValue;
              permisos[index].submenus[found].permisos.special5 = changeValue;
              permisos[index].submenus[found].permisos.special6 = changeValue;
              permisos[index].submenus[found].permisos.special7 = changeValue;
              permisos[index].submenus[found].permisos.special8 = changeValue;
              permisos[index].submenus[found].permisos.special9 = changeValue;
              permisos[index].submenus[found].permisos.special10 = changeValue;
              this.setState({ data: { ...this.state.data, permisos } });
            } else {
              item.submenus.forEach((subitem, index2) => {
                if (subitem.submenus.length >= 0) {
                  found = subitem.submenus.findIndex((sub2item) => sub2item._id === id);
                  if (found >= 0) {
                    permisos[index].submenus[index2].submenus[found].permisos.canAdd = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.canView = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.canModify = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.canDelete = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.canList = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.canExport = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special1 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special2 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special3 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special4 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special5 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special6 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special7 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special8 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special9 = changeValue;
                    permisos[index].submenus[index2].submenus[found].permisos.special10 = changeValue;
                    this.setState({ data: { ...this.state.data, permisos } });
                  }
                }
              });
            }
          }
        });
      }
    }
  };

  handleOnChangeCheck = (id, option) => {
    const { permisos } = this.state.data;
    var found = permisos.find((item) => item._id === id);
    if (found) {
      switch (option) {
        case "A":
          found.permisos.canAdd = !found.permisos.canAdd;
          break;
        case "V":
          found.permisos.canView = !found.permisos.canView;
          break;
        case "M":
          found.permisos.canModify = !found.permisos.canModify;
          break;
        case "D":
          found.permisos.canDelete = !found.permisos.canDelete;
          break;
        case "L":
          found.permisos.canList = !found.permisos.canList;
          break;
        case "E":
          found.permisos.canExport = !found.permisos.canExport;
          break;
        case "1":
          found.permisos.special1 = !found.permisos.special1;
          break;
        case "2":
          found.permisos.special2 = !found.permisos.special2;
          break;
        case "3":
          found.permisos.special3 = !found.permisos.special3;
          break;
        case "4":
          found.permisos.special4 = !found.permisos.special4;
          break;
        case "5":
          found.permisos.special5 = !found.permisos.special5;
          break;
        case "6":
          found.permisos.special6 = !found.permisos.special6;
          break;
        case "7":
          found.permisos.special7 = !found.permisos.special7;
          break;
        case "8":
          found.permisos.special8 = !found.permisos.special8;
          break;
        case "9":
          found.permisos.special9 = !found.permisos.special9;
          break;
        case "10":
          found.permisos.special10 = !found.permisos.special10;
          break;
      }
    } else {
      permisos.forEach((item) => {
        if (item.submenus.length > 0) {
          found = item.submenus.find((subitem) => subitem._id === id);
          if (found) {
            switch (option) {
              case "A":
                found.permisos.canAdd = !found.permisos.canAdd;
                break;
              case "V":
                found.permisos.canView = !found.permisos.canView;
                break;
              case "M":
                found.permisos.canModify = !found.permisos.canModify;
                break;
              case "D":
                found.permisos.canDelete = !found.permisos.canDelete;
                break;
              case "L":
                found.permisos.canList = !found.permisos.canList;
                break;
              case "E":
                found.permisos.canExport = !found.permisos.canExport;
                break;
              case "1":
                found.permisos.special1 = !found.permisos.special1;
                break;
              case "2":
                found.permisos.special2 = !found.permisos.special2;
                break;
              case "3":
                found.permisos.special3 = !found.permisos.special3;
                break;
              case "4":
                found.permisos.special4 = !found.permisos.special4;
                break;
              case "5":
                found.permisos.special5 = !found.permisos.special5;
                break;
              case "6":
                found.permisos.special6 = !found.permisos.special6;
                break;
              case "7":
                found.permisos.special7 = !found.permisos.special7;
                break;
              case "8":
                found.permisos.special8 = !found.permisos.special8;
                break;
              case "9":
                found.permisos.special9 = !found.permisos.special9;
                break;
              case "10":
                found.permisos.special10 = !found.permisos.special10;
                break;
            }
          } else {
            item.submenus.forEach((subitem) => {
              if (subitem.submenus.length > 0) {
                found = subitem.submenus.find((sub2item) => sub2item._id === id);
                if (found) {
                  switch (option) {
                    case "A":
                      found.permisos.canAdd = !found.permisos.canAdd;
                      break;
                    case "V":
                      found.permisos.canView = !found.permisos.canView;
                      break;
                    case "M":
                      found.permisos.canModify = !found.permisos.canModify;
                      break;
                    case "D":
                      found.permisos.canDelete = !found.permisos.canDelete;
                      break;
                    case "L":
                      found.permisos.canList = !found.permisos.canList;
                      break;
                    case "E":
                      found.permisos.canExport = !found.permisos.canExport;
                      break;
                    case "1":
                      found.permisos.special1 = !found.permisos.special1;
                      break;
                    case "2":
                      found.permisos.special2 = !found.permisos.special2;
                      break;
                    case "3":
                      found.permisos.special3 = !found.permisos.special3;
                      break;
                    case "4":
                      found.permisos.special4 = !found.permisos.special4;
                      break;
                    case "5":
                      found.permisos.special5 = !found.permisos.special5;
                      break;
                    case "6":
                      found.permisos.special6 = !found.permisos.special6;
                      break;
                    case "7":
                      found.permisos.special7 = !found.permisos.special7;
                      break;
                    case "8":
                      found.permisos.special8 = !found.permisos.special8;
                      break;
                    case "9":
                      found.permisos.special9 = !found.permisos.special9;
                      break;
                    case "10":
                      found.permisos.special10 = !found.permisos.special10;
                      break;
                  }
                }
              }
            });
          }
        }
      });
    }
  };

  prepareData = () => {
    const { descripcion, isAdmin, permisos } = this.state.data;
    const newPermisos = [];
    permisos.forEach((permiso) => {
      if (
        permiso.link !== "" &&
        !isAdmin &&
        (permiso.permisos.canAdd ||
          permiso.permisos.canView ||
          permiso.permisos.canModify ||
          permiso.permisos.canDelete ||
          permiso.permisos.canList ||
          permiso.permisos.canExport ||
          permiso.permisos.special1 ||
          permiso.permisos.special2 ||
          permiso.permisos.special3 ||
          permiso.permisos.special4 ||
          permiso.permisos.special5 ||
          permiso.permisos.special6 ||
          permiso.permisos.special7 ||
          permiso.permisos.special8 ||
          permiso.permisos.special9 ||
          permiso.permisos.special10)
      ) {
        newPermisos.push(permiso.permisos);
      }
      if (permiso.submenus.length > 0) {
        permiso.submenus.forEach((sub) => {
          if (
            sub.link !== "" &&
            !isAdmin &&
            (sub.permisos.canAdd ||
              sub.permisos.canView ||
              sub.permisos.canModify ||
              sub.permisos.canDelete ||
              sub.permisos.canList ||
              sub.permisos.canExport ||
              sub.permisos.special1 ||
              sub.permisos.special2 ||
              sub.permisos.special3 ||
              sub.permisos.special4 ||
              sub.permisos.special5 ||
              sub.permisos.special6 ||
              sub.permisos.special7 ||
              sub.permisos.special8 ||
              sub.permisos.special9 ||
              sub.permisos.special10)
          ) {
            newPermisos.push(sub.permisos);
          }
          if (sub.submenus.length > 0) {
            sub.submenus.forEach((sub2) => {
              if (
                sub2.link != "" &&
                !isAdmin &&
                (sub2.permisos.canAdd ||
                  sub2.permisos.canModify ||
                  sub2.permisos.canView ||
                  sub2.permisos.canDelete ||
                  sub2.permisos.canList ||
                  sub2.permisos.canExport ||
                  sub2.permisos.special1 ||
                  sub2.permisos.special2 ||
                  sub2.permisos.special3 ||
                  sub2.permisos.special4 ||
                  sub2.permisos.special5 ||
                  sub2.permisos.special6 ||
                  sub2.permisos.special7 ||
                  sub2.permisos.special8 ||
                  sub2.permisos.special9 ||
                  sub2.permisos.special10)
              ) {
                newPermisos.push(sub2.permisos);
              }
            });
          }
        });
      }
    });
  };

  doSubmit = async () => {
    try {
      await saveProfile(this.state.data);
      this.props.history.push("/profiles");
    } catch (error) {
      // console.log(error);
    }
  };

  render() {
    const { action } = this.state;
    const { descripcion, isAdmin, permisos } = this.state.data;
    return (
      <React.Fragment>
        <ReactTabHotkeyReplacer>
          {this.renderContentHeader(this.title, this.breadcrumbs)}
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="row ">
                {/* left column */}
                <div className="col-12">
                  <div className="card card-secondary">
                    <div className="card-header">
                      <h3 className="card-title">
                        <RoundButton onClick={() => this.goBack()} iconClass="fas fa-arrow-left" className="btn-transparent mr-2" tooltip="Volver" />
                        Detalles
                      </h3>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      <div className="card-body">
                        <div className="row align-items-center">
                          {this.renderInput("descripcion", "Descripción", "text", { float: true, columns: 9, autoFocus: true })}
                          {this.renderInput("isAdmin", "Es Administrador", "check", { columns: 3 })}
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="card">
                              <div className="card-header">
                                <h3 className="card-title">Permisos</h3>
                              </div>
                              <div className="card-body p-0">
                                <table className="table table-hover">
                                  <tbody>
                                    {isAdmin && (
                                      <tr className="table-success">
                                        <td className="border-0">Acceso total a todas las opciones del sistema</td>
                                      </tr>
                                    )}
                                    {permisos &&
                                      !isAdmin &&
                                      permisos.map((menu) => {
                                        return menu.submenus.length > 0 ? (
                                          <React.Fragment key={`RF` + menu._id}>
                                            <tr key={menu._id} className="table-warning" data-widget="expandable-table" aria-expanded="true">
                                              <td className="border-0" colSpan={19}>
                                                <i className="expandable-table-caret fas fa-caret-right fa-fw" />
                                                {menu.label}
                                              </td>
                                            </tr>
                                            <tr key={Math.random()} className="expandable-body">
                                              <td colSpan={19}>
                                                <div className="p-0">
                                                  <table className="table table-hover">
                                                    <thead>
                                                      <tr>
                                                        <th className="w-40">Menú</th>
                                                        <th className="w-3">I/C</th>
                                                        <th className="w-3">A</th>
                                                        <th className="w-3">M</th>
                                                        <th className="w-3">B</th>
                                                        <th className="w-3">L</th>
                                                        <th className="w-3">E</th>
                                                        <th className="w-3">Ex.1</th>
                                                        <th className="w-3">Ex.2</th>
                                                        <th className="w-3">Ex.3</th>
                                                        <th className="w-3">Ex.4</th>
                                                        <th className="w-3">Ex.5</th>
                                                        <th className="w-3">Ex.6</th>
                                                        <th className="w-3">Ex.7</th>
                                                        <th className="w-3">Ex.8</th>
                                                        <th className="w-3">Ex.9</th>
                                                        <th className="w-3">Ex.10</th>
                                                        <th className="w-6"></th>
                                                        <th className="w-6"></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {menu.submenus.map((submenu) => {
                                                        return submenu.submenus.length > 0 ? (
                                                          <React.Fragment key={`RF` + submenu._id}>
                                                            <tr key={Math.random()} className="table-warning" data-widget="expandable-table" aria-expanded="true">
                                                              <td className="border-0" colSpan={19}>
                                                                <i className="expandable-table-caret fas fa-caret-right fa-fw" />
                                                                {submenu.label}
                                                              </td>
                                                            </tr>
                                                            <tr key={Math.random()} className="expandable-body">
                                                              <td colSpan={19}>
                                                                <div className="p-0">
                                                                  <table className="table table-hover">
                                                                    <thead>
                                                                      <tr>
                                                                        <th className="w-40">Menú</th>
                                                                        <th className="w-3">I/C</th>
                                                                        <th className="w-3">A</th>
                                                                        <th className="w-3">M</th>
                                                                        <th className="w-3">B</th>
                                                                        <th className="w-3">L</th>
                                                                        <th className="w-3">E</th>
                                                                        <th className="w-3">Ex.1</th>
                                                                        <th className="w-3">Ex.2</th>
                                                                        <th className="w-3">Ex.3</th>
                                                                        <th className="w-3">Ex.4</th>
                                                                        <th className="w-3">Ex.5</th>
                                                                        <th className="w-3">Ex.6</th>
                                                                        <th className="w-3">Ex.7</th>
                                                                        <th className="w-3">Ex.8</th>
                                                                        <th className="w-3">Ex.9</th>
                                                                        <th className="w-3">Ex.10</th>
                                                                        <th className="w-6"></th>
                                                                        <th className="w-6"></th>
                                                                      </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                      {submenu.submenus.map((sub2menu) => (
                                                                        <tr key={Math.random()}>
                                                                          <td className="w-40">{sub2menu.label}</td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`V${sub2menu._id}`}
                                                                              name={`V` + sub2menu._id}
                                                                              value={sub2menu.permisos.canView}
                                                                              defaultChecked={this.checkState(sub2menu._id, "V")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "V")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`A${sub2menu._id}`}
                                                                              name={`A` + sub2menu._id}
                                                                              value={sub2menu.permisos.canAdd}
                                                                              defaultChecked={this.checkState(sub2menu._id, "A")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "A")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`M${sub2menu._id}`}
                                                                              name={`M` + sub2menu._id}
                                                                              value={sub2menu.permisos.canModify}
                                                                              defaultChecked={this.checkState(sub2menu._id, "M")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "M")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`D${sub2menu._id}`}
                                                                              name={`D` + sub2menu._id}
                                                                              value={sub2menu.permisos.canDelete}
                                                                              defaultChecked={this.checkState(sub2menu._id, "D")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "D")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`L${sub2menu._id}`}
                                                                              name={`L` + sub2menu._id}
                                                                              value={sub2menu.permisos.canList}
                                                                              defaultChecked={this.checkState(sub2menu._id, "L")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "L")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`E${sub2menu._id}`}
                                                                              name={`E` + sub2menu._id}
                                                                              value={sub2menu.permisos.canExport}
                                                                              defaultChecked={this.checkState(sub2menu._id, "E")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "E")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`1${sub2menu._id}`}
                                                                              name={`1` + sub2menu._id}
                                                                              value={sub2menu.permisos.special1}
                                                                              defaultChecked={this.checkState(sub2menu._id, "1")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "1")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`2${sub2menu._id}`}
                                                                              name={`2` + sub2menu._id}
                                                                              value={sub2menu.permisos.special2}
                                                                              defaultChecked={this.checkState(sub2menu._id, "2")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "2")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`3${sub2menu._id}`}
                                                                              name={`3` + sub2menu._id}
                                                                              value={sub2menu.permisos.special3}
                                                                              defaultChecked={this.checkState(sub2menu._id, "3")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "3")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`4${sub2menu._id}`}
                                                                              name={`4` + sub2menu._id}
                                                                              value={sub2menu.permisos.special4}
                                                                              defaultChecked={this.checkState(sub2menu._id, "4")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "4")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`5${sub2menu._id}`}
                                                                              name={`5` + sub2menu._id}
                                                                              value={sub2menu.permisos.special5}
                                                                              defaultChecked={this.checkState(sub2menu._id, "5")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "5")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`6${sub2menu._id}`}
                                                                              name={`6` + sub2menu._id}
                                                                              value={sub2menu.permisos.special6}
                                                                              defaultChecked={this.checkState(sub2menu._id, "6")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "6")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`7${sub2menu._id}`}
                                                                              name={`7` + sub2menu._id}
                                                                              value={sub2menu.permisos.special7}
                                                                              defaultChecked={this.checkState(sub2menu._id, "7")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "7")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`8${sub2menu._id}`}
                                                                              name={`8` + sub2menu._id}
                                                                              value={sub2menu.permisos.special8}
                                                                              defaultChecked={this.checkState(sub2menu._id, "8")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "8")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`9${sub2menu._id}`}
                                                                              name={`9` + sub2menu._id}
                                                                              value={sub2menu.permisos.special9}
                                                                              defaultChecked={this.checkState(sub2menu._id, "9")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "9")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-3">
                                                                            <input
                                                                              type="checkbox"
                                                                              id={`10${sub2menu._id}`}
                                                                              name={`10` + sub2menu._id}
                                                                              value={sub2menu.permisos.special10}
                                                                              defaultChecked={this.checkState(sub2menu._id, "10")}
                                                                              onChange={() => this.handleOnChangeCheck(sub2menu._id, "10")}
                                                                              disabled={action === "C" || action === "D"}
                                                                            />
                                                                          </td>
                                                                          <td className="w-6">{action !== "C" && action !== "D" && <i className="fas fa-plus text-olive-disabled clickable" onClick={() => this.addRow(sub2menu._id)}></i>}</td>
                                                                          <td className="w-6">{action !== "C" && action !== "D" && <i className="fas fa-minus text-danger-disabled clickable" onClick={() => this.removeRow(sub2menu._id)}></i>}</td>
                                                                        </tr>
                                                                      ))}
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </React.Fragment>
                                                        ) : (
                                                          <tr key={Math.random()}>
                                                            <td className="w-40">{submenu.label}</td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`V${submenu._id}`}
                                                                name={`V` + submenu._id}
                                                                value={submenu.permisos.canView}
                                                                defaultChecked={this.checkState(submenu._id, "V")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "V")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`A${submenu._id}`}
                                                                name={`A` + submenu.label}
                                                                value={submenu.permisos.canAdd}
                                                                defaultChecked={this.checkState(submenu._id, "A")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "A")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`M${submenu._id}`}
                                                                name={`M` + submenu._id}
                                                                value={submenu.permisos.canModify}
                                                                defaultChecked={this.checkState(submenu._id, "M")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "M")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`D${submenu._id}`}
                                                                name={`D` + submenu._id}
                                                                value={submenu.permisos.canDelete}
                                                                defaultChecked={this.checkState(submenu._id, "D")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "D")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`L${submenu._id}`}
                                                                name={`L` + submenu._id}
                                                                value={submenu.permisos.canList}
                                                                defaultChecked={this.checkState(submenu._id, "L")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "L")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`E${submenu._id}`}
                                                                name={`E` + submenu._id}
                                                                value={submenu.permisos.canExport}
                                                                defaultChecked={this.checkState(submenu._id, "E")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "E")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`1${submenu._id}`}
                                                                name={`1` + submenu._id}
                                                                value={submenu.permisos.special1}
                                                                defaultChecked={this.checkState(submenu._id, "1")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "1")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`2${submenu._id}`}
                                                                name={`2` + submenu._id}
                                                                value={submenu.permisos.special2}
                                                                defaultChecked={this.checkState(submenu._id, "2")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "2")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`3${submenu._id}`}
                                                                name={`3` + submenu._id}
                                                                value={submenu.permisos.special3}
                                                                defaultChecked={this.checkState(submenu._id, "3")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "3")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`4${submenu._id}`}
                                                                name={`4` + submenu._id}
                                                                value={submenu.permisos.special4}
                                                                defaultChecked={this.checkState(submenu._id, "4")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "4")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`5${submenu._id}`}
                                                                name={`5` + submenu._id}
                                                                value={submenu.permisos.special5}
                                                                defaultChecked={this.checkState(submenu._id, "5")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "5")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`6${submenu._id}`}
                                                                name={`6` + submenu._id}
                                                                value={submenu.permisos.special6}
                                                                defaultChecked={this.checkState(submenu._id, "6")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "6")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`7${submenu._id}`}
                                                                name={`7` + submenu._id}
                                                                value={submenu.permisos.special7}
                                                                defaultChecked={this.checkState(submenu._id, "7")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "7")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`8${submenu._id}`}
                                                                name={`8` + submenu._id}
                                                                value={submenu.permisos.special8}
                                                                defaultChecked={this.checkState(submenu._id, "8")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "8")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`9${submenu._id}`}
                                                                name={`9` + submenu._id}
                                                                value={submenu.permisos.special9}
                                                                defaultChecked={this.checkState(submenu._id, "9")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "9")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-3">
                                                              <input
                                                                type="checkbox"
                                                                id={`10${submenu._id}`}
                                                                name={`10` + submenu._id}
                                                                value={submenu.permisos.special10}
                                                                defaultChecked={this.checkState(submenu._id, "10")}
                                                                onChange={() => this.handleOnChangeCheck(submenu._id, "10")}
                                                                disabled={action === "C" || action === "D"}
                                                              />
                                                            </td>
                                                            <td className="w-6">{action !== "C" && action !== "D" && <i className="fas fa-plus text-olive-disabled clickable" onClick={() => this.addRow(submenu._id)}></i>}</td>
                                                            <td className="w-6">{action !== "C" && action !== "D" && <i className="fas fa-minus text-danger-disabled clickable" onClick={() => this.removeRow(submenu._id)}></i>}</td>
                                                          </tr>
                                                        );
                                                      })}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </td>
                                            </tr>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <tr key={Math.random()}>
                                              <td className="w-40">{menu.label}</td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`V${menu._id}`}
                                                  name={`V` + menu._id}
                                                  value={menu.permisos.canView}
                                                  defaultChecked={this.checkState(menu._id, "V")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "V")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`A${menu._id}`}
                                                  name={`A` + menu.label}
                                                  value={menu.permisos.canAdd}
                                                  defaultChecked={this.checkState(menu._id, "A")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "A")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`M${menu._id}`}
                                                  name={`M` + menu._id}
                                                  value={menu.permisos.canModify}
                                                  defaultChecked={this.checkState(menu._id, "M")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "M")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`D${menu._id}`}
                                                  name={`D` + menu._id}
                                                  value={menu.permisos.canDelete}
                                                  defaultChecked={this.checkState(menu._id, "D")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "D")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`L${menu._id}`}
                                                  name={`L` + menu._id}
                                                  value={menu.permisos.canList}
                                                  defaultChecked={this.checkState(menu._id, "L")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "L")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`E${menu._id}`}
                                                  name={`E` + menu._id}
                                                  value={menu.permisos.canExport}
                                                  defaultChecked={this.checkState(menu._id, "E")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "E")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`1${menu._id}`}
                                                  name={`1` + menu._id}
                                                  value={menu.permisos.special1}
                                                  defaultChecked={this.checkState(menu._id, "1")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "1")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`2${menu._id}`}
                                                  name={`2` + menu._id}
                                                  value={menu.permisos.special2}
                                                  defaultChecked={this.checkState(menu._id, "2")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "2")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`3${menu._id}`}
                                                  name={`3` + menu._id}
                                                  value={menu.permisos.special3}
                                                  defaultChecked={this.checkState(menu._id, "3")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "3")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`4${menu._id}`}
                                                  name={`4` + menu._id}
                                                  value={menu.permisos.special4}
                                                  defaultChecked={this.checkState(menu._id, "4")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "4")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`5${menu._id}`}
                                                  name={`5` + menu._id}
                                                  value={menu.permisos.special5}
                                                  defaultChecked={this.checkState(menu._id, "5")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "5")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`6${menu._id}`}
                                                  name={`6` + menu._id}
                                                  value={menu.permisos.special6}
                                                  defaultChecked={this.checkState(menu._id, "6")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "6")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`7${menu._id}`}
                                                  name={`7` + menu._id}
                                                  value={menu.permisos.special7}
                                                  defaultChecked={this.checkState(menu._id, "7")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "7")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`8${menu._id}`}
                                                  name={`8` + menu._id}
                                                  value={menu.permisos.special8}
                                                  defaultChecked={this.checkState(menu._id, "8")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "8")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`9${menu._id}`}
                                                  name={`9` + menu._id}
                                                  value={menu.permisos.special9}
                                                  defaultChecked={this.checkState(menu._id, "9")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "9")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-3">
                                                <input
                                                  type="checkbox"
                                                  id={`10${menu._id}`}
                                                  name={`10` + menu._id}
                                                  value={menu.permisos.special10}
                                                  defaultChecked={this.checkState(menu._id, "10")}
                                                  onChange={() => this.handleOnChangeCheck(menu._id, "10")}
                                                  disabled={action === "C" || action === "D"}
                                                />
                                              </td>
                                              <td className="w-6">{action !== "C" && action !== "D" && <i className="fas fa-plus text-olive-disabled clickable" onClick={() => this.addRow(menu._id)}></i>}</td>
                                              <td className="w-6">{action !== "C" && action !== "D" && <i className="fas fa-minus text-danger-disabled clickable" onClick={() => this.removeRow(menu._id)}></i>}</td>
                                            </tr>
                                          </React.Fragment>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">{this.renderSubmit("Guardar")}</div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
        </ReactTabHotkeyReplacer>
      </React.Fragment>
    );
  }
}

export default withRouter(ProfilesForm);

import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { timeSince } from "../utils/utils.js";
import { getUser } from "../../services/userService";
import { getNewMessages } from "../../services/mensajesService";
import { getMenues } from "../../services/menuesService";
import { saveCotizacion, getCotizacion } from "../../services/cotizacionesService.js";
import { getPermisos } from "../../services/permisosService";
import { getShortcuts, saveShortcut } from "../../services/shortcutsService";
import { toastTimeout, toastInfo } from "../utils/toasts";
import Avatar from "react-avatar";
import NumberFormat from "react-number-format";
import moment from "moment";

export default function Navbar() {
  const [user, setUser] = useState(null);
  const [mensajes, setMensajes] = useState([]);
  const [alertas, setAlertas] = useState([]);
  const [mounted, setMounted] = useState(null);
  const [menues, setMenues] = useState([]);
  const [shortcuts, setShortcuts] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [cotiz, setCotiz] = useState();
  const [myTimer, setMyTimer] = useState();
  const selectedModule = localStorage.getItem("selectedModule");

  useEffect(() => {
    async function fetchData() {
      try {
        const jwt = localStorage.getItem("token");
        const selectedModule = localStorage.getItem("selectedModule");
        const decoded = jwt ? jwtDecode(jwt) : null;
        const fetchUser = await getUser(decoded.user._id);
        setUser(fetchUser);
        const shortcuts = await getShortcuts();
        setShortcuts(shortcuts);
        const menues = await getMenues(selectedModule);
        const permisos = await getPermisos(fetchUser.profile._id);
        setPermisos(permisos);
        // const showCotiz = await getCotizacion();
        // console.log(showCotiz);
        // setShowCotiz(showCotiz);
        const newMenues = [];
        menues.forEach((menu) => {
          if (menu.link === "" && menu.submenus.length > 0) {
            newMenues.push({ label: menu.label, link: "", selected: false, canClick: false });
            menu.submenus.forEach((submenu) => {
              if (submenu.link === "" && menu.submenus.length > 0) {
                newMenues.push({ label: submenu.label, link: "", selected: false, canClick: false });
                submenu.submenus.forEach((sub2menu) => {
                  if (sub2menu.link !== "") {
                    const found = shortcuts.find((shortcut) => shortcut.link === sub2menu.link);
                    newMenues.push({ label: sub2menu.label, link: sub2menu.link, selected: found ? true : false, canClick: true });
                  }
                });
              } else {
                const found = shortcuts.find((shortcut) => shortcut.link === submenu.link);
                newMenues.push({ label: submenu.label, link: submenu.link, selected: found ? true : false, canClick: true });
              }
            });
          } else {
            const found = shortcuts.find((shortcut) => shortcut.link === menu.link);
            newMenues.push({ label: menu.label, link: menu.link, selected: found ? true : false, canClick: true });
          }
        });

        setMenues(newMenues);
      } catch (exception) {
        localStorage.removeItem("token");
        setUser(null);
      }
    }

    if (!mounted) {
      fetchData();
      // refreshCotiz();
      // refreshAlerts();
      // refreshMessages();
    }

    const timer = startStop(true);
    // const timer = setInterval(() => {
    //   refreshCotiz();
    //   refreshMessages();
    //   refreshAlerts();
    // }, 5000);

    // setMyTimer(timer);

    return () => clearInterval(timer);
  }, []);

  function startStop(doStart) {
    if (doStart) {
      const timer = setInterval(() => {
        // refreshCotiz();
        // refreshMessages();
        // refreshAlerts();
      }, 5000);
      setMyTimer(timer);
      return timer;
    } else clearInterval(myTimer);
  }

  async function refreshMessages() {
    try {
      const jwt = localStorage.getItem("token");
      const decoded = jwt ? jwtDecode(jwt) : null;
      const records = await getNewMessages(decoded.user._id);
      setMensajes(records);
    } catch (exception) {
      localStorage.removeItem("token");
      setUser(null);
    }
  }

  async function refreshCotiz() {
    try {
      const jwt = localStorage.getItem("token");
      const decoded = jwt ? jwtDecode(jwt) : null;
      const cotizacion = await getCotizacion();
      setCotiz(cotizacion);
      // if (!viewCot) setViewCot(cotizacion);
    } catch (exception) {}
  }

  function refreshAlerts() {}

  const checkLink = (url) => {
    if (user.profile.isAdmin || user.isSuperAdmin) return true;
    var found = permisos.findIndex((element) => element.menu.link === url);
    if (found >= 0) return true;
    else return false;
  };

  const handleClick = (e, url) => {
    if (!checkLink(url)) {
      e.preventDefault();
      toastInfo("No tenés acceso a esta opción");
    }
  };

  const handleCotiz = async (event) => {
    const fecha = moment();
    await saveCotizacion({ fecha: fecha, valor: cotiz });
    startStop(true);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleCotiz(e);
    }
  };
  const changeCotiz = (values, sourceInfo) => {
    // console.log(values.currentTarget.value);
    // setCotiz(values.currentTarget.value);
    setCotiz(values.value);
    // setViewCot(values.value);
  };

  const onFocus = () => {
    startStop(false);
    console.log("got focus");
  };

  async function toggleShortcut(event, index) {
    event.preventDefault();
    event.stopPropagation();
    const newMenus = [...menues];
    const newShortcuts = [...shortcuts];
    let count = 0;
    newMenus.forEach((menu) => {
      if (menu.selected) count++;
    });
    if (newMenus[index].selected) count--;
    if (count < 5) {
      newMenus[index].selected = !newMenus[index].selected;
      const success = saveShortcut(newMenus[index]);
      if (newMenus[index].selected) newShortcuts.push({ label: newMenus[index].label, link: newMenus[index].link });
      if (!newMenus[index].selected) {
        const found = newShortcuts.findIndex((item) => item.link === newMenus[index].link);
        if (found > -1) newShortcuts.splice(found, 1);
      }
    } else {
      toastTimeout("No se pueden agregar más atajos", "Se pueden agregar hasta 5 atajos solamente", 5000, true, "error", () => {});
    }
    setMenues(newMenus);
    setShortcuts(newShortcuts);
  }

  return (
    <div>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        {selectedModule && (
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                <i className="fas fa-bars" />
              </a>
            </li>

            {shortcuts &&
              shortcuts.map((shortcut) => (
                <li key={Math.random()} className="nav-item d-none d-sm-inline-block">
                  <NavLink to={shortcut.link} className="nav-link" onClick={(e) => handleClick(e, shortcut.link)}>
                    {shortcut.label}
                  </NavLink>
                </li>
              ))}
            {/* <li className="nav-item d-none d-sm-inline-block">
              <a className="nav-link clickable">Favorito 2</a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a className="nav-link clickable">Favorito 3</a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a className="nav-link clickable">Favorito 2</a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a className="nav-link clickable">Favorito 3</a>
            </li> */}

            <li className="nav-item dropdown d-none d-sm-inline-block clickable">
              <a id="dropdownMenuButton" className="nav-link dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-star text-warning"></i>
              </a>
              {/*ESTRELLA*/}
              <ul className="dropdown-menu dropdown-menu-lg " aria-labelledby="dropdownMenuButton">
                <li className="overflow-auto " style={{ height: "200px" }}>
                  {menues &&
                    menues.map((menu, index) => {
                      return (
                        <div key={Math.random()} onClick={(event) => toggleShortcut(event, index)} className="dropdown-item d-inline-flex justify-content-between">
                          {menu.canClick && (
                            <React.Fragment>
                              <span className="d-flex">{menu.label}</span>
                              {menu.selected && (
                                <span className="d-flex align-self-center">
                                  <i className="fa fa-star text-warning"></i>
                                </span>
                              )}
                            </React.Fragment>
                          )}
                          {!menu.canClick && (
                            <React.Fragment>
                              <span className="d-flex">
                                <strong>{menu.label}</strong>
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                      );
                    })}
                  {/* {Cars.map((x) => {
                    return (
                      <div key={x.id} onClick={() => this.toggleItemClicked(x.id)} className="dropdown-item d-inline-flex justify-content-between">
                        <span className="d-flex"> {x.name} </span>
                        <span className="d-flex align-self-center">{this.state.idsOfItemClicked.includes(x.id) && <i className="fa fa-times" aria-hidden="true" />}</span>
                      </div>
                    );
                  })} */}
                </li>
              </ul>
            </li>
          </ul>
        )}

        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}
          {selectedModule && cotiz && (
            <>
              <li className="nav-item ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">1 u$s = </span>
                    {/* <input type="text" class="form-control" /> */}
                    <NumberFormat
                      prefix={"$ "}
                      // value={viewCot}
                      defaultValue={cotiz}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      onFocus={onFocus}
                      onBlur={handleCotiz}
                      onKeyDown={handleKeyDown}
                      onValueChange={changeCotiz}
                    />{" "}
                    {/* <input type="number" value={cotiz} onFocus={onFocus} onBlur={handleCotiz} onChange={changeCotiz} /> */}
                  </div>
                </div>
              </li>

              <li className="nav-item">
                <a className="nav-link" data-widget="navbar-search" href="#" role="button">
                  <i className="fas fa-search" />
                </a>
                <div className="navbar-search-block">
                  <form className="form-inline">
                    <div className="input-group input-group-sm">
                      <input className="form-control form-control-navbar" type="search" placeholder="Buscar" aria-label="Buscar" />
                      <div className="input-group-append">
                        <button className="btn btn-navbar" type="submit">
                          <i className="fas fa-search" />
                        </button>
                        <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>
            </>
          )}

          {/* User Dropdown Menu */}
          <li className="nav-item dropdown user-menu clickable">
            <a className="nav-link dropdown-toggle" data-toggle="dropdown">
              <img src={user && user.avatar ? user.avatar : "/dist/img/user.jpg"} className="user-image img-circle shadow" alt="User Image" />
              <span className="d-none d-md-inline"> {user ? user.nombre : ""}</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              {/* User image */}
              <li className="user-header bg-navy-disabled" style={{ height: "200px" }}>
                <img src={user && user.avatar ? user.avatar : "/dist/img/user.jpg"} className="img-circle shadow" alt="User Image" />
                <p>
                  {user ? user.nombre + " " + user.apellido : ""}
                  <br />
                  {user && user.profile ? user.profile.descripcion : ""}
                  <small>{user ? "Miembro desde " + moment(user.created).locale("es").format("MMMM") + "/" + moment(user.created).format("YYYY") : ""}</small>
                </p>
              </li>
              {/* Menu Body */}
              {/* <li className="user-body">
                <section className="row">
                  <div className="col-4 text-center">
                    <a href="#">Followers</a>
                  </div>
                  <div className="col-4 text-center">
                    <a href="#">Sales</a>
                  </div>
                  <div className="col-4 text-center">
                    <a href="#">Friends</a>
                  </div>
                </section>
              </li> */}
              {/* Menu Footer*/}
              <li className="user-footer d-flex justify-content-between">
                <a className="text-secondary clickable">Mis Datos</a>
                <a className="text-secondary clickable">Bloquear</a>
                <NavLink to="/logout" className="text-secondary clickable ">
                  Cerrar Sesión
                </NavLink>
              </li>
            </ul>
          </li>

          {/* Messages Dropdown Menu */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-comments" />
              {mensajes && mensajes.length > 0 && <span className="badge badge-danger navbar-badge">{mensajes.length}</span>}
            </a>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              {mensajes && mensajes.length > 0 && <span className="dropdown-item dropdown-header">{mensajes.length + (mensajes.length === 1 ? " Mensaje Nuevo" : " Mensajes Nuevos")}</span>}
              {mensajes &&
                mensajes.length > 0 &&
                mensajes.map((mensaje, index) => {
                  return (
                    <React.Fragment key={"msg" + index}>
                      <div className="dropdown-divider" />

                      <a href="#" className="dropdown-item">
                        {/* Message Start */}
                        <div className="media">
                          <Avatar className="table-avatarimg-size-50 mr-3 img-circle" name={mensaje.de.nombre + " " + mensaje.de.apellido} size="50" round="100%" src={mensaje.de.avatar} />

                          <div className="media-body">
                            <h3 className="dropdown-item-title">
                              {mensaje.de.nombre + " " + mensaje.de.apellido}
                              <span className="float-right text-sm text-warning">
                                <i className="fas fa-star" />
                              </span>
                            </h3>
                            <p className="text-sm truncate">{mensaje.texto}</p>
                            <p className="text-sm text-muted">
                              <i className="far fa-clock mr-1" /> {timeSince(mensaje.fecha)}
                            </p>
                          </div>
                        </div>
                        {/* Message End */}
                      </a>
                      <div className="dropdown-divider" />
                    </React.Fragment>
                  );
                })}
              {mensajes && mensajes.length > 0 && <div className="dropdown-divider" />}
              <a href="#" className="dropdown-item dropdown-footer">
                Ver todos los mensajes
              </a>
            </div>
          </li>

          {/* Notifications Dropdown Menu */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell" />
              {alertas.length > 0 && <span className="badge badge-warning navbar-badge">{alertas.length}</span>}
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              {alertas.length > 0 && <span className="dropdown-item dropdown-header">{alertas.length + (alertas.length === 1 ? " Alerta Nueva" : " Alertas Nuevas")}</span>}
              {alertas.map((alerta, index) => {
                return (
                  <React.Fragment key={"alert" + index}>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                      <i className="fas fa-envelope mr-2" /> 4 mensajes nuevos
                      <span className="float-right text-muted text-sm">3 mins</span>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                      <i className="fas fa-users mr-2" /> 8 alertas
                      <span className="float-right text-muted text-sm">12 horas</span>
                    </a>
                  </React.Fragment>
                );
              })}
              {alertas.length > 0 && <div className="dropdown-divider" />}
              {
                <a href="#" className="dropdown-item dropdown-footer">
                  Ver todas las alertas
                </a>
              }
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
              <i className="fas fa-th-large" />
            </a>
          </li>
        </ul>
      </nav>
      {/* /.navbar */}
    </div>
  );
}

import React from "react";
import { withRouter } from "react-router-dom";
import Form from "../common/newForm";
import Joi from "joi-browser";
import { getCondicionIva, saveCondicionIva } from "../../services/condicionesIvaService";
import ContentHeader from "../common/contentHeader";

class CondicionesIvaForm extends Form {
  title = "Condición de Iva";
  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Condiciones de IVA",
      link: "/condicionesiva",
    },
    {
      title: "Detalles",
    },
  ];

  state = {
    mounted: false,
    data: { descripcion: "", generaIva: false, seLeDiscriminaIva: false },
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    descripcion: Joi.string()
      .label("Descripción")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            // case "string.min":
            //   return { message: "first msg" };
            // case "string.max":
            //   return { message: "second msg" };
            case "any.empty":
              return { message: "Debe ingresar una descripción" };
            default:
              return { message: "Debe ingresar una descripción" };
          }
        });
      }),
    generaIva: Joi.boolean(),
    seLeDiscriminaIva: Joi.boolean(),
  };

  render() {
    // const { options } = this.state;
    const { generaIva, seLeDiscriminaIva } = this.state.data;
    console.log(generaIva);
    console.log(seLeDiscriminaIva);
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} />

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 className="card-title">Detalles</h3>
                  </div>
                  {this.state.mounted && (
                    <form onSubmit={this.handleSubmit}>
                      <div className="card-body">
                        <div className="row ">
                          <div className="col-6">{this.renderInput("descripcion", "Descripcion")}</div>
                        </div>
                        <div className="row ">
                          <div className="ml-3 col-2">{this.renderInput("generaIva", "Genera IVA", "toggle")}</div>
                          <div className="col-2">{this.renderInput("seLeDiscriminaIva", "Se Le Discrimina IVA", "toggle")}</div>
                        </div>
                      </div>
                      <div className="card-footer">{this.renderSubmit("Guardar")}</div>
                    </form>
                  )}
                </div>
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
      </React.Fragment>
    );
  }

  componentDidMount = async () => {
    const { onLoading, onDoneLoading } = this.props;
    const recordId = this.props.match.params.id;
    if (recordId === "nuevo" || recordId === "nueva") return;
    onLoading();
    const record = await getCondicionIva(recordId);
    onDoneLoading();
    if (!record) return this.props.history.replace("/notfound");
    const data = this.mapToViewModel(record);

    this.setState({ data, mounted: true });
  };

  mapToViewModel = (condicionIva) => {
    return {
      _id: condicionIva._id,
      descripcion: condicionIva.descripcion,
      generaIva: condicionIva.generaIva,
      seLeDiscriminaIva: condicionIva.seLeDiscriminaIva,
    };
  };

  doSubmit = async () => {
    await saveCondicionIva(this.state.data);
    this.props.history.push("/condicionesiva");
  };
}

export default withRouter(CondicionesIvaForm);

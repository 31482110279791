import React from "react";
import { withRouter } from "react-router-dom";
import Form from "../common/form";
import Joi from "joi-browser";
import { getEstado, saveEstado } from "../../services/estadosService";
import ContentHeader from "../common/contentHeader";

class estadosForm extends Form {
  title = "Estado";
  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Estados",
      link: "/estados",
    },
    {
      title: "Detalles",
    },
  ];

  state = {
    data: { descripcion: "" },
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    descripcion: Joi.string()
      .label("Descripción")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            // case "string.min":
            //   return { message: "first msg" };
            // case "string.max":
            //   return { message: "second msg" };
            case "any.empty":
              return { message: "Debe ingresar una descripción" };
            default:
              return { message: "Debe ingresar una descripción" };
          }
        });
      }),
  };

  render() {
    // const { options } = this.state;
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} />

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 className="card-title">Detalles</h3>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-6">{this.renderInput("descripcion", "Descripcion")}</div>
                      </div>
                    </div>
                    <div className="card-footer">{this.renderButton("Guardar")}</div>
                  </form>
                </div>
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
      </React.Fragment>
    );
  }

  componentDidMount = async () => {
    const { onLoading, onDoneLoading } = this.props;
    const recordId = this.props.match.params.id;
    if (recordId === "nuevo" || recordId === "nueva") return;
    onLoading();
    const record = await getEstado(recordId);
    onDoneLoading();
    if (!record) return this.props.history.replace("/notfound");
    this.setState({ data: this.mapToViewModel(record) });
  };

  mapToViewModel = (estado) => {
    return {
      _id: estado._id,
      descripcion: estado.descripcion,
    };
  };

  doSubmit = async () => {
    await saveEstado(this.state.data);
    this.props.history.push("/estados");
  };
}

export default withRouter(estadosForm);

import "./App.css";

import React, { useState } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/sidebar/sidebar";
import Footer from "./components/footer/footer";
import Controlbar from "./components/controlbar/controlbar";
import Inmuebles from "./components/inmuebles/inmuebles";
import ForgotPassword from "./components/password/forgotPassword";
import ResetPassword from "./components/password/resetPassword";
import InmueblesForm from "./components/inmuebles/inmueblesForm";
import Main from "./components/main/main";
import MainAlquileres from "./components/mainAlquileres/mainAlquileres";
import MainVentas from "./components/mainVentas/mainVentas";
import MainDomo from "./components/mainDomo/mainDomo";
import MainAgios from "./components/mainAgios/mainAgios";
import LoginForm from "./components/loginForm/loginForm";
import Proyectos from "./components/proyectos/proyectos";
import ProyectosForm from "./components/proyectos/proyectosForm";
import Presupuestos from "./components/presupuestos/presupuestos";
import PresupuestosForm from "./components/presupuestos/presupuestosForm";
import Personas from "./components/personas/personas";
import PersonasForm from "./components/personas/personasForm";
import CondicionesIva from "./components/condicionesiva/condicionesIva";
import CondicionesIvaForm from "./components/condicionesiva/condicionesIvaForm";
import Estados from "./components/estados/estados";
import EstadosForm from "./components/estados/estadosForm";
import Tipos from "./components/tipos/tipos";
import TiposForm from "./components/tipos/tiposForm";
import Empresas from "./components/empresas/empresas";
import EmpresasForm from "./components/empresas/empresasForm";
import Profiles from "./components/profiles/profiles";
import ProfilesForm from "./components/profiles/profilesForm";
import CajasDomo from "./components/cajasdomo/cajasdomo";
import Categorias from "./components/categorias/categorias";
import CategoriasForm from "./components/categorias/categoriasForm";
import NotAllowed from "./components/notAllowed/notAllowed";
import NotFound from "./components/notFound/notFound";
import NoConnection from "./components/noConnection/noConnection";
import Logout from "./components/logout/logout";
import SaldosPresupuestos from "./components/saldosPresupuestos/saldosPresupuestos";
import PreLoader from "./components/preloader/preloader";
import Test from "./components/test/test";
// import Dashboard from "./components/dashboard/dashboard";

const App = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const loading = () => {
    setIsLoading(true);
  };

  const doneLoading = () => {
    setIsLoading(false);
  };

  const token = localStorage.getItem("token");

  return token ? (
    <div className="wrapper">
      <PreLoader />

      <Navbar />
      <Sidebar />

      <div className={isLoading ? "content-wrapper page-loading" : "content-wrapper"}>
        <Switch>
          <Redirect from="/login" exact to="/main" />
          <Route
            path="/proyectos/:id"
            render={() => {
              return <ProyectosForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/test"
            render={() => {
              return <Test onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/proyectos"
            render={() => {
              return <Proyectos onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/presupuestos/:id"
            render={() => {
              return <PresupuestosForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/inmuebles/:id"
            render={() => {
              return <InmueblesForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/inmuebles"
            render={() => {
              return <Inmuebles onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/presupuestos"
            render={() => {
              return <Presupuestos onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/personas/:id"
            render={() => {
              return <PersonasForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/personas"
            render={() => {
              return <Personas onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/condicionesiva/:id"
            render={() => {
              return <CondicionesIvaForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/condicionesiva"
            render={() => {
              return <CondicionesIva onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/categorias/:id"
            render={() => {
              return <CategoriasForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/categorias"
            render={() => {
              return <Categorias onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/estados/:id"
            render={() => {
              return <EstadosForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/estados"
            render={() => {
              return <Estados onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/tipos/:id"
            render={() => {
              return <TiposForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/tipos"
            render={() => {
              return <Tipos onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/cajasdomo"
            render={() => {
              return <CajasDomo onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/profiles/:id"
            render={() => {
              return <ProfilesForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/profiles"
            render={() => {
              return <Profiles onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/empresas/:id"
            render={() => {
              return <EmpresasForm onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/empresas"
            render={() => {
              return <Empresas onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/saldospresupuestos"
            render={() => {
              return <SaldosPresupuestos onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          <Route
            path="/logout"
            render={() => {
              return <Logout onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          />
          {/* <Route
            path="/test"
            render={() => {
              return <Test onLoading={loading} onDoneLoading={doneLoading} />;
            }}
          /> */}
          <Route path="/notallowed" component={NotAllowed} />
          <Route path="/notfound" component={NotFound} />
          <Route path="/noconnection" component={NoConnection} />
          <Route path="/main" component={Main} />
          <Route path="/alquileres" component={MainAlquileres} />
          <Route path="/ventas" component={MainVentas} />
          <Route path="/consorcios" component={MainAgios} />
          <Route path="/obras" component={MainDomo} />

          <Redirect from="/" exact to="/main" />

          <Redirect to="/notfound" />
        </Switch>
      </div>

      <Footer />
      <Controlbar />
    </div>
  ) : (
    <div className="wrapper">
      <Switch>
        <Route path="/login" component={(props) => <LoginForm {...props} />} />
        <Route
          path="/forgotpassword"
          render={() => {
            return <ForgotPassword />;
          }}
        />
        <Route
          path="/resetpassword/:id/:token"
          render={() => {
            return <ResetPassword />;
          }}
        />
        <Redirect to="/login" />
      </Switch>
    </div>
  );
};

export default App;

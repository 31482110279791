import Dropzone from "react-dropzone";
import React, { Component } from "react";
import "./fileUploader.css";

class FileUploadContainer extends Component {
  constructor() {
    super();

    this.onImageDrop = this.onImageDrop.bind(this);
  }

  onImageDrop(acceptedFiles) {
    const { onUpload } = this.props;
    const data = new FormData();
    for (let i = 0; i < acceptedFiles.length; i += 1) {
      data.append("file", acceptedFiles[i]);
    }
    if (acceptedFiles.length > 0) onUpload(data);
  }

  render() {
    const { url, background, uploadingImages, ...rest } = this.props;
    return (
      <div>
        <Dropzone
          {...rest}
          className="dropzone"
          onDrop={(acceptedFiles) => {
            this.onImageDrop(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
            const additionalClass = isDragAccept ? "accept" : isDragReject ? "reject" : "";

            return (
              <div
                {...getRootProps({
                  className: `dropzone ${additionalClass}`,
                })}
              >
                <div
                  className="dropzone-bg"
                  style={{
                    backgroundImage: `url(${background})`,
                  }}
                ></div>
                <input {...getInputProps()} />
                {/* <span>{isDragActive ? "📂" : "📁"}</span> */}
                <span>
                  {uploadingImages && <i className="fas fa-2x fa-camera"></i>}
                  {!uploadingImages && <i className="fas fa-2x fa-cloud-upload-alt"></i>}
                </span>
                <p>
                  Arrastre {uploadingImages ? "imágenes" : "archivos"} o haga <strong className="clickable">click aquí</strong>
                </p>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

export default FileUploadContainer;

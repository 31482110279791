import React from "react";
import Form from "../common/newForm";
import { withRouter } from "react-router-dom";
import Joi from "joi-browser";
import { getMovimientosPresupuestos } from "../../services/cajasDomoService.js";
import { getPresupuesto, savePresupuesto } from "../../services/presupuestosService";
import { personasPromiseOptions, getPersona, getPersonasSelect } from "../../services/personasService";
import { proyectosPromiseOptions, getProyecto, getProyectosSelect } from "../../services/proyectosService";
import { estadosPresupuestos, formatCurrencyToFloat } from "../utils/utils";
import { getMonedasSimboloSelect } from "../../services/monedasService.js";
import { getConfig } from "../../config/config.js";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer/dist/ReactTabHotkeyReplacer";
import RoundButton from "../common/roundButton";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

class PresupuestosForm extends Form {
  title = "Presupuesto";

  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Presupuestos",
      link: "/presupuestos",
    },
    {
      title: "Detalles",
    },
  ];

  validateForm = true;

  state = {
    action: "",
    data: {
      proyecto: "",
      responsable: "",
      descripcion: "",
      estado: "",
      monedasindocumentar: "",
      monedadocumentado: "",
      totalsindocumentar: 0,
      totaldocumentado: 0,
    },
    percsindocumentar: 0,
    percdocumentado: 0,
    monedas: [],
    estado: { label: "Pedido", value: "Pedido" },
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    descripcion: Joi.string()
      .label("Descripcion")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar una descripcion" };
          }
        });
      }),
    totaldocumentado: Joi.number()
      .label("Total Documentado")
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "number.base":
              return { message: "Debe ingresar un valor" };
          }
        });
      }),

    totalsindocumentar: Joi.number()
      .label("Total Sin Documentar")
      .error((errors) => {
        return errors.map((error) => {
          // console.log(error.type);
          switch (error.type) {
            case "number.base":
              return { message: "Debe ingresar un valor" };
          }
        });
      }),
    estado: Joi.string()
      .label("Estado")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar un estado" };
          }
        });
      }),
    proyecto: Joi.string()
      .label("Proyecto")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar un proyecto" };
          }
        });
      }),
    monedasindocumentar: Joi.string()
      .label("Moneda Sin Documentar")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar moneda" };
          }
        });
      }),
    monedadocumentado: Joi.string()
      .label("Moneda Documentado")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar moneda" };
          }
        });
      }),
    responsable: Joi.string()
      .label("Responsable")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "object.base":
              return { message: "Debe ingresar un responsable" };
          }
        });
      }),
  };

  componentDidMount = async () => {
    this.hookMounted();
    const { onLoading, onDoneLoading } = this.props;
    const recordId = this.props.match.params.id;
    let record = {};
    onLoading();
    const monedas = await getMonedasSimboloSelect();
    this.setState({ monedas });
    if (recordId !== "nuevo" && recordId !== "nueva") {
      record = await getPresupuesto(recordId);
      const movimientos = await getMovimientosPresupuestos(recordId, false);
      let nSaldoMovimientos = 0;
      movimientos.forEach((movimiento) => {
        if (record.monedasindocumentar._id === movimiento.moneda) nSaldoMovimientos += parseFloat(movimiento.egreso) - parseFloat(movimiento.ingreso);
        else {
          if (record.monedasindocumentar.simbolo === "$") nSaldoMovimientos += parseFloat((movimiento.egreso - movimiento.ingreso) * movimiento.cotizacion);
          else nSaldoMovimientos += parseFloat((movimiento.egreso - movimiento.ingreso) / movimiento.cotizacion);
        }
      });
      const movimientosD = await getMovimientosPresupuestos(recordId, true);
      let nSaldoMovimientosD = 0;
      movimientosD.forEach((movimiento) => {
        if (record.monedadocumentado._id === movimiento.moneda) nSaldoMovimientosD += parseFloat(movimiento.egreso) - parseFloat(movimiento.ingreso);
        else {
          if (record.monedadocumentado.simbolo === "$") nSaldoMovimientos += parseFloat((movimiento.egreso - movimiento.ingreso) * movimiento.cotizacion);
          else nSaldoMovimientosD += parseFloat((movimiento.egreso - movimiento.ingreso) / movimiento.cotizacion);
        }
      });
      this.setState({ percsindocumentar: nSaldoMovimientos, percdocumentado: nSaldoMovimientosD });
    }

    onDoneLoading();
    if (recordId === "nuevo" || recordId === "nueva") {
      const presupuestado = estadosPresupuestos.find((item) => item.label === "Presupuestado");
      const data = { ...this.state.data };
      data.estado = presupuestado;
      this.setState({ data });
      return;
    } else {
      if (!record) return this.props.history.replace("/notfound");
      // const selectedCondicionIva = {
      //   label: record.condicionIva.descripcion,
      //   value: record.condicionIva._id,
      // };
      this.setState({
        data: this.mapToViewModel(record),
        //   condicionesIva,
        //   condicionIvaId: selectedCondicionIva,
      });
    }
  };

  mapToViewModel = (presupuesto) => {
    return {
      _id: presupuesto._id,
      descripcion: presupuesto.descripcion,
      totalsindocumentar: presupuesto.totalsindocumentar,
      totaldocumentado: presupuesto.totaldocumentado,
      // proyecto: presupuesto.proyecto
      //   ? {
      //       value: presupuesto.proyecto._id,
      //       label: presupuesto.proyecto.nombre,
      //     }
      //   : { label: "", value: "" },
      monedasindocumentar: presupuesto.monedasindocumentar
        ? {
            value: presupuesto.monedasindocumentar._id,
            label: presupuesto.monedasindocumentar.simbolo,
          }
        : { label: "", value: "" },
      monedadocumentado: presupuesto.monedadocumentado
        ? {
            value: presupuesto.monedadocumentado._id,
            label: presupuesto.monedadocumentado.simbolo,
          }
        : { label: "", value: "" },
      proyecto: presupuesto.proyecto
        ? {
            value: presupuesto.proyecto._id,
            label: presupuesto.proyecto.nombre,
          }
        : { label: "", value: "" },
      responsable: presupuesto.responsable
        ? {
            value: presupuesto.responsable._id,
            label: presupuesto.responsable.apellido + " " + presupuesto.responsable.nombre,
          }
        : { label: "", value: "" },
      estado: estadosPresupuestos.find((item) => item.label === presupuesto.estado),
    };
  };

  prepareData = () => {
    const { data } = this.state;
    const totaldocumentado = formatCurrencyToFloat(data.totaldocumentado);
    const totalsindocumentar = formatCurrencyToFloat(data.totalsindocumentar);
    data.estado = data.estado.value;
    data.proyecto = data.proyecto.value;
    data.monedadocumentado = data.monedadocumentado.value;
    data.monedasindocumentar = data.monedasindocumentar.value;
    data.responsable = data.responsable.value;
    data.totaldocumentado = totaldocumentado;
    data.totalsindocumentar = totalsindocumentar;
    this.setState({ data });
  };

  doSubmit = async () => {
    try {
      await savePresupuesto(this.state.data);
      this.props.history.push("/presupuestos");
      // console.log("dosubmit");
      // const newId = await savePresupuesto(this.state.data);

      // if (this.state.data._id) this.props.history.push("/presupuestos");
      // else {
      //   const data = { ...this.state.data };
      //   this.setState({
      //     data: { ...data, _id: newId },
      //   });
      // }
    } catch (error) {
      // console.log(error);
    }
  };

  render() {
    const { action } = this.state;
    const { data, errors, percdocumentado, percsindocumentar, monedas } = this.state;
    const config = getConfig();
    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        {/* <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} /> */}
        {this.renderContentHeader(this.title, this.breadcrumbs, this.goBack)}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary mt-2">
                  <div className="card-header">
                    <h3 className="card-title">
                      {" "}
                      <RoundButton onClick={() => this.goBack()} iconClass="fas fa-arrow-left" className="btn-transparent mr-2" tooltip="Volver" />
                      Detalles
                    </h3>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <ReactTabHotkeyReplacer>
                      <div className="card-body">
                        <div className="row">
                          {this.renderInput("descripcion", "Descripción", "text", {
                            float: true,
                            columns: 12,
                            autoFocus: true,
                          })}
                        </div>
                        <div className="row">
                          {this.renderInput("proyecto", "Proyecto", "asyncCombo", {
                            float: true,
                            loadOptions: proyectosPromiseOptions,
                            columns: 6,
                          })}
                          {this.renderInput("responsable", "Responsable", "asyncCombo", {
                            float: true,
                            loadOptions: personasPromiseOptions,
                            columns: 6,
                          })}
                        </div>

                        <div className="row">
                          {this.renderInput("estado", "Estado", "combo", {
                            float: true,
                            options: estadosPresupuestos,
                            columns: 4,
                          })}
                          {this.renderInput("monedasindocumentar", "Moneda", "combo", {
                            float: true,
                            options: monedas,
                            columns: 1,
                          })}
                          {this.renderInput("totalsindocumentar", "Total Sin Documentar", "number", {
                            float: true,
                            columns: 3,
                          })}
                          {this.renderInput("monedadocumentado", "Moneda", "combo", {
                            float: true,
                            options: monedas,
                            columns: 1,
                          })}
                          {this.renderInput("totaldocumentado", "Total Documentado", "number", {
                            float: true,
                            columns: 2,
                          })}
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-6 text-center">
                          {/* {percsindocumentar && percsindocumentar > 0 && ( */}
                          <>
                            <CircularProgressbar
                              className="progress-presupuesto"
                              value={isNaN(percsindocumentar / this.state.data.totalsindocumentar) ? 0 : percsindocumentar / this.state.data.totalsindocumentar}
                              maxValue={1}
                              text={`${Math.round((isNaN(percsindocumentar / this.state.data.totalsindocumentar) ? 0 : percsindocumentar / this.state.data.totalsindocumentar) * 10000) / 100}%`}
                            />
                            <h4 className="mt-2">Progreso Presupuesto</h4>
                          </>
                          {/* )} */}
                        </div>
                        <div className="col-6 text-center">
                          {/* {percdocumentado && percdocumentado > 0 && ( */}
                          <>
                            <CircularProgressbar
                              className="progress-presupuesto"
                              value={isNaN(percdocumentado / this.state.data.totaldocumentado) ? 0 : percdocumentado / this.state.data.totaldocumentado}
                              maxValue={1}
                              text={`${Math.round((isNaN(percdocumentado / this.state.data.totaldocumentado) ? 0 : percdocumentado / this.state.data.totaldocumentado) * 10000) / 100}%`}
                            />
                            <h4 className="mt-2">Progreso Documentado</h4>
                          </>
                          {/* )} */}
                        </div>
                      </div>

                      <div className="card-footer">{this.renderSubmit(this.state.data._id ? "Guardar" : "Continuar")}</div>
                    </ReactTabHotkeyReplacer>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(PresupuestosForm);

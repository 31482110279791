import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/estados";

export async function getEstados() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getEstado(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function saveEstado(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else return await http.post(serviceEndpoint, data);
}

export async function deleteEstado(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

export async function getEstadosSelect() {
  let records = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((record) => {
      records.push({ value: record._id, label: record.descripcion });
    });
    return records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export const estadosPromiseOptions = (inputValue) =>
  http
    .get(serviceEndpoint, { params: { search: inputValue } })
    .then(function (response) {
      let options = response.data.records.map((record) => ({
        value: record._id,
        label: record.descripcion,
      }));
      return options;
    })
    .catch(function (error) {
      console.log(error);
    });

import React from "react";

export default function PreLoader() {
  return (
    <div>
      {/* Preloader */}
      <div style={{ backgroundColor: "#212529" }} className="preloader flex-column justify-content-center align-items-center">
        <img src="/dist/img/logo.png" alt="Logo" />
        <i className="mt-5 text-secondary fas fa-2x fa-sync fa-spin"></i>
      </div>
    </div>
  );
}

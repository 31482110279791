import React, { Component } from "react";
import { withRouter } from "react-router-dom/";

class Logout extends Component {
  componentDidMount() {
    const { onLoading } = this.props;
    localStorage.removeItem("token");
    localStorage.removeItem("selectedModule");
    onLoading();
    this.props.history.push("/login");
  }

  render() {
    return <div></div>;
  }
}

export default withRouter(Logout);

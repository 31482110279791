import React, { useState, useEffect } from "react";
import { getModulos } from "../../services/modulosService";
import { getUser } from "../../services/userService";
import { toastTimeout } from "../utils/toasts";
import jwtDecode from "jwt-decode";

export default function Main(props) {
  const [mounted, setMounted] = useState(false);
  const [modulos, setModulos] = useState([]);
  const [user, setUser] = useState();
  const [willRedirect, setWillRedirect] = useState(true);

  const fetchUserAndModulos = async () => {
    const jwt = localStorage.getItem("token");
    const decoded = jwt ? jwtDecode(jwt) : null;
    const user = await getUser(decoded.user._id);
    setUser(user);
    const modulos = await getModulos();
    if (modulos) {
      setModulos(modulos);
      let count = 0;
      let indexModuloUnico = null;
      modulos.map((modulo, index) => {
        if (user.empresa.modulos.find((item) => item === modulo._id)) {
          count++;
          indexModuloUnico = index;
        }
      });
      if (count === 1 && !user.empresa.muestraModulosInactivos) {
        redirectToModulo(modulos[indexModuloUnico].path);
      } else setWillRedirect(false);
    } else this.props.history.replace("/noconnection");
  };

  if (!mounted) {
    const selectedModule = localStorage.removeItem("selectedModule");

    fetchUserAndModulos();
    // Code for componentWillMount here
    // This code is called only one time before intial render
  }

  useEffect(() => {
    setMounted(true);
    localStorage.removeItem("selectedModule");
  }, []);

  const redirectToModulo = (path) => {
    localStorage.setItem("selectedModule", path);
    window.location = "/" + path;
  };
  const goModulo = (index) => {
    localStorage.setItem("selectedModule", modulos[index].path);
    window.location = "/" + modulos[index].path;
  };

  const moduloNoHabilitado = () => {
    toastTimeout("Módulo No Habilitado", "Su empresa no tiene habilitado este módulo", 5000, true, "error", () => {});
  };

  return !willRedirect ? (
    <div>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Seleccione Módulo</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">
                  <a href="#">Inicio</a>
                </li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}

      <section className="content">
        <div className="container-fluid">
          <h5 className="mb-2 mt-4">Módulos</h5>
          <div className="row">
            {modulos &&
              modulos.map((modulo, index) => {
                if (user.empresa.modulos.find((item) => item === modulo._id)) {
                  return (
                    <div key={"modulo" + index} className="col-lg-3 col-6">
                      <div className={"small-box " + modulo.color}>
                        <div className="inner">
                          <h3>{modulo.nombre}</h3>

                          <p>{modulo.descripcion}</p>
                        </div>
                        <div className="icon">
                          <i className={modulo.icon}></i>
                        </div>
                        <a className="small-box-footer clickable" onClick={() => goModulo(index)}>
                          Ingresar <i className="fas fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>
                  );
                } else {
                  if (user.empresa.muestraModulosInactivos) {
                    return (
                      <div key={"modulo" + index} className="col-lg-3 col-6">
                        <div className={"small-box bg-light"}>
                          <div className="inner">
                            <h3>{modulo.nombre}</h3>

                            <p>{modulo.descripcion}</p>
                          </div>
                          <div className="icon">
                            <i className={modulo.icon}></i>
                          </div>
                          <a className="small-box-footer clickable" onClick={() => moduloNoHabilitado(index)}>
                            Ingresar <i className="fas fa-arrow-circle-right"></i>
                          </a>
                        </div>
                      </div>
                    );
                  }
                }
              })}
          </div>
        </div>
      </section>
    </div>
  ) : (
    ""
  );
}

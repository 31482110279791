import React, { useState, useEffect } from "react";
import { dateFormatter } from "../utils/utils";
import NumberFormat from "react-number-format";
import Input from "./input";
import moment from "moment";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer/dist/ReactTabHotkeyReplacer";
import { isEmpty } from "lodash";

export default function Cuenta(props) {
  let saldo = 0;

  const [editItem, setEditItem] = useState(-1);

  const {
    tipoCuenta,
    cajas,
    categorias,
    rubros,
    proyectos,
    destinos,
    onSave,
    onCancel,
    newRecord,
    onReset,
    onEdit,
    onChangeFecha,
    onChangeDestino,
    onChangeCategoria,
    onChangeRubro,
    onChangeProyecto,
    onRecordChange,
    onToggleDocumentado,
    onDelete,
    isAdmin,
    isSuperAdmin,
    status,
    canEdit,
  } = props;

  const editSave = () => {
    setEditItem(-1);
    onSave();
  };

  const editRecord = async (index) => {
    newRecord._id = cajas[index]._id;
    newRecord.fecha = moment(cajas[index].fecha).format("YYYY-MM-DD");
    newRecord.detalle = cajas[index].detalle;
    newRecord.ingreso = cajas[index].ingreso;
    newRecord.egreso = cajas[index].egreso;
    newRecord.destino = cajas[index].destino;
    if (!isEmpty(cajas[index].presupuesto)) newRecord.categoria = { label: cajas[index].presupuesto.label, value: cajas[index].presupuesto.value, tipo: "P" };
    if (!isEmpty(cajas[index].categoria)) {
      newRecord.categoria = { label: cajas[index].catecoria.label, value: cajas[index].categoria.value, tipo: "C" };
      newRecord.rubro = cajas[index].rubro ? { label: cajas[index].rubro, value: cajas[index].rubro } : {};
    }
    newRecord.proyecto = {};
    newRecord.persona = {};
    setEditItem(index);
    onEdit();
  };

  const editCancel = () => {
    onReset();
    setEditItem(-1);
  };

  const deleteRecord = (index) => {
    onDelete(index);
  };

  const isIngresoAllowed = (values, limita) => {
    if (!limita) return true;
    else {
      const { floatValue } = values;
      return floatValue <= limita;
    }
  };

  return (
    <div>
      <ReactTabHotkeyReplacer>
        <form>
          <table className="table table-sm table-hover table-striped">
            <thead>
              <tr>
                <th width="10%">Fecha</th>
                {tipoCuenta === "S" && <th width="15%">Proyecto</th>}
                {tipoCuenta === "P" && <th width="15%">Socio</th>}
                {tipoCuenta !== "P" && tipoCuenta !== "S" && <th width="15%">Proyecto/Socio</th>}
                <th width="15%">Categoría/Presupuesto</th>
                <th width="10%">Rubro/Detalle</th>
                <th width="10%"></th>
                <th className="text-center" width="10%">
                  Ingreso
                </th>
                <th className="text-center" width="10%">
                  Egreso
                </th>
                <th className="text-center" width="10%">
                  Saldo
                </th>
                <th className="text-center" width="7%"></th>
              </tr>
            </thead>
            <tbody>
              {cajas.map((caja, index) => {
                let labelCategoria = "";
                let labelDestino = "";
                saldo += caja.ingreso - caja.egreso;
                return (
                  <tr key={`caja${index}`}>
                    {index !== editItem && (
                      <React.Fragment>
                        <td>{dateFormatter(caja.fecha)}</td>
                        <td>{caja.destino.label}</td>
                        {/* <td>{(caja.personaId !== "" || caja.proyectoId !== "") && labelDestino}</td> */}

                        {/* <td>{caja.label}</td> */}
                        {caja.destino.tipo === "P" && caja.presupuesto && (
                          <>
                            <td>{caja.presupuesto.label}</td>
                            <td colSpan="2">{caja.detalle}</td>
                          </>
                        )}
                        {caja.destino.tipo === "P" && caja.categoria && caja.categoria.tipo !== "P" && (
                          <>
                            <td>{caja.categoria.label}</td>
                            <td>{caja.rubro}</td>
                            <td>{caja.detalle}</td>
                          </>
                        )}
                        {caja.destino.tipo === "S" && (
                          <>
                            <td>{caja.categoria && caja.categoria.label}</td>
                            <td>{caja.proyecto && caja.proyecto.label}</td>
                            <td>{caja.detalle}</td>
                          </>
                        )}
                        <td className="text-right">{caja.ingreso > 0 && <NumberFormat value={caja.ingreso} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />}</td>
                        <td className="text-right">{caja.egreso > 0 && <NumberFormat value={caja.egreso} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />}</td>
                        <td className={"text-right " + (saldo < 0 ? "text-danger" : "")}>{<NumberFormat value={saldo} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} />}</td>
                        <td>
                          <i className={"fa-balance-scale mr-2 fa text-secondary " + `${caja.copiadodocumentado ? "visible" : "invisible"}`}></i>
                          {(isAdmin || isSuperAdmin) && canEdit && !caja.parentId && <i className="fas fa-pencil-alt mr-2 clickable text-olive-disabled" onClick={() => editRecord(index)}></i>}
                          {(isAdmin || isSuperAdmin) && canEdit && !caja.parentId && <i className="fas fa-trash-alt mr-2 clickable text-danger-disabled" onClick={() => deleteRecord(caja._id)}></i>}
                        </td>
                      </React.Fragment>
                    )}
                    {index === editItem && (
                      <React.Fragment>
                        {(isAdmin || isSuperAdmin) && (
                          <td className="align-middle">
                            <Input date size="sm" name="addFecha" value={newRecord.fecha} onChange={onChangeFecha} />
                          </td>
                        )}
                        {!(isAdmin || isSuperAdmin) && <td>{dateFormatter(newRecord.fecha)}</td>}
                        <td className="align-middle">
                          <Input combo size="sm" value={newRecord.destino} options={destinos} onChange={onChangeDestino} />
                        </td>
                        {newRecord.destino && !newRecord.destino.value && (
                          <>
                            <td className="align-middle">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle">
                              <div className="cajaloader"></div>
                            </td>
                            <td className="align-middle">
                              <div className="cajaloader"></div>
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.value && (
                          <td className="align-middle">
                            <Input combo size="sm" value={newRecord.categoria} options={categorias} onChange={onChangeCategoria} />
                          </td>
                        )}

                        {newRecord.destino && newRecord.destino.tipo && newRecord.destino.tipo === "S" && (
                          <>
                            <td className="align-middle">
                              <Input combo size="sm" value={newRecord.proyecto} options={proyectos} onChange={onChangeProyecto} />
                            </td>
                            <td className="align-middle">
                              <Input name="detalle" size="sm" disabled value={newRecord.detalle} onChange={onRecordChange} />
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo !== "P" && (
                          <>
                            <td className="align-middle">
                              <Input combo size="sm" value={newRecord.rubro} options={rubros} onChange={onChangeRubro} />
                            </td>
                            <td className="align-middle">
                              <Input name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo === "P" && (
                          <>
                            <td colSpan="2" className="align-middle">
                              <Input name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                            </td>
                          </>
                        )}
                        {newRecord.destino && newRecord.destino.value && (
                          <>
                            <td className="align-middle">
                              <Input number size="sm" name="ingreso" value={newRecord.ingreso} onChange={onRecordChange} isAllowed={(values) => isIngresoAllowed(values, newRecord.limitaIngreso)} />
                            </td>
                            <td className="align-middle">
                              <Input number size="sm" name="egreso" value={newRecord.egreso} onChange={onRecordChange} />
                            </td>
                            <td className="align-middle"></td>
                          </>
                        )}

                        <td>
                          <i className={"fa-balance-scale clickable mr-2 " + (newRecord.documentado ? "fas text-primary" : "fa text-secondary") + (newRecord.destino.tipo === "P" ? " visible" : " invisible")} onClick={onToggleDocumentado}></i>
                          <i className="fa fa-check mt-3 mr-2 clickable text-olive-disabled " onClick={editSave}></i>
                          <i className="fa fa-times mr-1 clickable text-danger-disabled" onClick={editCancel}></i>
                        </td>
                        <td></td>
                      </React.Fragment>
                    )}
                  </tr>
                );
              })}
              {status === "A" && editItem < 0 && (
                <tr>
                  {(isAdmin || isSuperAdmin) && (
                    <td className="align-middle">
                      <Input date size="sm" name="addFecha" value={newRecord.fecha} onChange={onChangeFecha} />
                    </td>
                  )}
                  {!(isAdmin || isSuperAdmin) && <td>{dateFormatter(newRecord.fecha)}</td>}
                  <td className="align-middle">
                    <Input combo size="sm" value={newRecord.destino} options={destinos} onChange={onChangeDestino} />
                  </td>
                  {newRecord.destino && !newRecord.destino.value && (
                    <>
                      <td className="align-middle">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle">
                        <div className="cajaloader"></div>
                      </td>
                      <td className="align-middle">
                        <div className="cajaloader"></div>
                      </td>
                    </>
                  )}
                  {newRecord.destino && newRecord.destino.value && (
                    <td className="align-middle">
                      <Input combo size="sm" value={newRecord.categoria} options={categorias} onChange={onChangeCategoria} />
                    </td>
                  )}

                  {newRecord.destino && newRecord.destino.tipo === "S" && (
                    <>
                      <td className="align-middle">
                        <Input combo size="sm" value={newRecord.proyecto} options={proyectos} onChange={onChangeProyecto} />
                      </td>
                      <td className="align-middle">
                        <Input name="detalle" size="sm" disabled value={newRecord.detalle} onChange={onRecordChange} />
                      </td>
                    </>
                  )}
                  {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo !== "P" && (
                    <>
                      <td className="align-middle">
                        <Input combo size="sm" value={newRecord.rubro} options={rubros} onChange={onChangeRubro} />
                      </td>
                      <td className="align-middle">
                        <Input name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                      </td>
                    </>
                  )}
                  {newRecord.destino && newRecord.destino.tipo === "P" && newRecord.categoria.tipo === "P" && (
                    <>
                      <td colSpan="2" className="align-middle">
                        <Input name="detalle" size="sm" value={newRecord.detalle} onChange={onRecordChange} />
                      </td>
                    </>
                  )}
                  {newRecord.destino && newRecord.destino.value && (
                    <>
                      <td className="align-middle">
                        <Input number size="sm" name="ingreso" value={newRecord.ingreso} onChange={onRecordChange} isAllowed={(values) => isIngresoAllowed(values, newRecord.limitaIngreso)} />
                      </td>
                      <td className="align-middle">
                        <Input number size="sm" name="egreso" value={newRecord.egreso} onChange={onRecordChange} />
                      </td>
                      <td className="align-middle"></td>
                    </>
                  )}

                  <td>
                    <i className={"fa-balance-scale clickable mr-2 " + (newRecord.documentado ? "fas text-primary" : "fa text-secondary") + (newRecord.destino.tipo === "P" ? " visible" : " invisible")} onClick={onToggleDocumentado}></i>
                    <i className="fa fa-check mt-3 mr-2 clickable text-olive-disabled " onClick={onSave}></i>
                    <i className="fa fa-times mr-1 clickable text-danger-disabled" onClick={onCancel}></i>
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </form>
      </ReactTabHotkeyReplacer>
    </div>
  );
}

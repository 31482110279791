import React from "react";
import RoundButton from "./roundButton";
import { Link } from "react-router-dom";

const ContentHeader = ({ title, breadcrumbs }) => {
  return (
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="ml-1">{title}</h1>
          </div>
          {/* /.col */}
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              {breadcrumbs.map((item, index) => (
                <li
                  key={"bread" + index}
                  className={"breadcrumb-item " + (item.link ? "" : "active")}
                >
                  {item.link && <Link to={item.link}>{item.title}</Link>}
                  {!item.link && item.title}
                </li>
              ))}
            </ol>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
};

export default ContentHeader;

import http from "./httpService";

import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/users/login";

export function login(username, password) {
  return http.post(serviceEndpoint, {
    email: username,
    password: password,
  });
}

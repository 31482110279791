import React from "react";

export default function Mainagios() {
  return (
    <div>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Administración de Consorcios</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">
                  <a href="#">Inicio</a>
                </li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}

      <section className="content">
        <div className="container-fluid">
          {/* <h5 className="mb-2 mt-4">Módulos</h5>
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-olive disabled">
                <div className="inner">
                  <h3>Khios</h3>

                  <p>Alquileres</p>
                </div>
                <div className="icon">
                  <i className="fas fa-handshake"></i>
                </div>
                <a href="#" className="small-box-footer">
                  Ingresar <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-lightblue disabled">
                <div className="inner">
                  <h3>Khios</h3>

                  <p>Ventas</p>
                </div>
                <div className="icon">
                  <i className="fas fa-home"></i>
                </div>
                <a href="#" className="small-box-footer">
                  Ingresar <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-purple disabled">
                <div className="inner">
                  <h3>Agios</h3>

                  <p>Consorcios</p>
                </div>
                <div className="icon">
                  <i className="fas fa-building"></i>
                </div>
                <a href="#" className="small-box-footer">
                  Ingresar <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger disabled">
                <div className="inner">
                  <h3>Domo</h3>

                  <p>Obras</p>
                </div>
                <div className="icon">
                  <i className="fas fa-hard-hat"></i>
                </div>
                <a href="#" className="small-box-footer">
                  Ingresar <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

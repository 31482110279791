import React from "react";

const CheckBox = ({ name, label, error, focus, value, ...rest }) => {
  if (focus)
    return (
      <div className="form-check">
        <input
          {...rest}
          type="checkbox"
          id={name}
          name={name}
          ref={focus.ref}
          className={"form-check-input" + (error ? "is-invalid" : "")}
        />
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
      </div>
    );
  else {
    return (
      <div className="form-check">
        <input
          {...rest}
          type="checkbox"
          id={name}
          name={name}
          className={"form-check-input " + (error ? "is-invalid" : "")}
        />
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
      </div>
    );
  }
};

export default CheckBox;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { toastConfirm, toastError } from "../utils/toasts";
import jwtDecode from "jwt-decode";
import { paginate } from "../utils/paginate";
import { cleanString } from "../utils/cleanString";
import { getConfig } from "../../config/config.js";

import ContentHeader from "../common/contentHeader";
import Pagination from "../common/pagination";
import Records from "../common/records";
import SearchBox from "../common/searchBox";
import TableHeader from "../common/tableHeader";

import { getUser } from "../../services/userService";
import { getPermisos } from "../../services/permisosService";
import { getAlquileres, direccionCompleta, localidadCompleta } from "../../services/inmueblesService";
import _ from "lodash";

const config = getConfig();
const recordsPerPage = config.recordsPerPage;

class Inmuebles extends Component {
  title = "Inmuebles";
  breadcrumbs = [
    {
      title: "Inicio",
      link: localStorage.getItem("selectedModule"),
    },
    {
      title: "Inmuebles",
    },
  ];
  state = {
    pageSize: recordsPerPage,
    records: [],
    currentPage: 1,
    sortColumn: { path: "codigo", order: "asc" },
    // sortColumn: { path: (item) => item.apellido.toLowerCase(), order: "asc" },

    searchQuery: "",
  };

  columns = [
    {
      path: "",
      sort: (item) => item.fotos.length,
      label: "",
      width: "10%",
      onClick: (record) => this.props.onView(record._id),
      // content: (record) => (
      //   <Link to={`/ntes/${record._id}`}>{record.apellido}</Link>
      // ),
    },
    {
      path: "codigo",
      sort: (item) => item.codigo,
      label: "Código",
      width: "10%",
      onClick: (record) => this.props.onView(record._id),
      // content: (record) => (
      //   <Link to={`/ntes/${record._id}`}>{record.apellido}</Link>
      // ),
    },
    {
      path: "direccion",
      sort: (item) => item.direccion.toLowerCase(),
      label: "Dirección",
      width: "20%",
      onClick: (record) => this.props.onView(record._id),
      // content: (record) => (
      //   <Link to={`/ntes/${record._id}`}>{record.apellido}</Link>
      // ),
    },
    {
      path: "tipos[0].descripcion",
      sort: (item) => item.tipos[0].descripcion.toLowerCase(),
      label: "Tipo de Inmueble",
      width: "20%",
      onClick: (record) => this.props.onView(record._id),
      // content: (record) => (
      //   <Link to={`/ntes/${record._id}`}>{record.apellido}</Link>
      // ),
    },
  ];

  async componentWillMount() {
    const jwt = localStorage.getItem("token");
    const decoded = jwt ? jwtDecode(jwt) : null;
    const user = await getUser(decoded.user._id);
    const permisos = await getPermisos(user.profile._id);
    if (!permisos) return this.props.history.replace("/notallowed");

    var found = permisos.find((element) => element.menu.link === "/inmuebles");
    if (!found && !user.profile.isAdmin && !user.isSuperAdmin) return this.props.history.replace("/notallowed");
    else {
      const canDo = {
        canAdd: user.profile.isAdmin || user.isSuperAdmin ? true : found.canAdd,
        canView: user.profile.isAdmin || user.isSuperAdmin ? true : found.canView,
        canModify: user.profile.isAdmin || user.isSuperAdmin ? true : found.canModify,
        canDelete: user.profile.isAdmin || user.isSuperAdmin ? true : found.canDelete,
        canList: user.profile.isAdmin || user.isSuperAdmin ? true : found.canList,
        canExport: user.profile.isAdmin || user.isSuperAdmin ? true : found.canExport,
        special1: user.profile.isAdmin || user.isSuperAdmin ? true : found.special1,
        special2: user.profile.isAdmin || user.isSuperAdmin ? true : found.special2,
        special3: user.profile.isAdmin || user.isSuperAdmin ? true : found.special3,
        special4: user.profile.isAdmin || user.isSuperAdmin ? true : found.special4,
        special5: user.profile.isAdmin || user.isSuperAdmin ? true : found.special5,
        special6: user.profile.isAdmin || user.isSuperAdmin ? true : found.special6,
        special7: user.profile.isAdmin || user.isSuperAdmin ? true : found.special7,
        special8: user.profile.isAdmin || user.isSuperAdmin ? true : found.special8,
        special9: user.profile.isAdmin || user.isSuperAdmin ? true : found.special9,
        special10: user.profile.isAdmin || user.isSuperAdmin ? true : found.special10,
      };
      this.setState({ permisos: canDo });
    }
  }

  async componentDidMount() {
    const { onLoading, onDoneLoading } = this.props;
    onLoading();
    const records = await getAlquileres();
    onDoneLoading();
    if (records === "NETWORK ERROR") return this.props.history.replace("/noconnection");
    this.setState({ records });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ currentPage: 1, sortColumn });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleView = (id) => {
    // event.preventDefault();
    this.props.history.push({
      pathname: "/inmuebles/" + id,
      state: { action: "C" },
    });
  };

  handleEdit = (event, id) => {
    // console.log("edit");
    // event.preventDefault();
    this.props.history.push({
      pathname: "/inmuebles/" + id,
      state: { action: "E" },
    });
  };

  handleDelete = async (event, id) => {
    event.preventDefault();
    toastConfirm("¿ Eliminar ficha ?", () => this.doDelete(id));
  };

  doDelete = async (id) => {
    // const originalRecords = this.state.records;
    // const records = this.state.records.filter((m) => m._id !== id);
    // this.setState({ records });
    // try {
    //   await deleteProyecto(id);
    // } catch (ex) {
    //   if (ex.response && ex.response.status === 404) toastError("Ese registro ya fue eliminado");
    //   this.setState({ records: originalRecords });
    // }
  };

  getPagedData = () => {
    const { pageSize, currentPage, records: allRecords, sortColumn, selectedFilter, searchQuery } = this.state;
    let filtered = [];
    if (searchQuery.trim().length > 0) {
      filtered = allRecords.filter((m) => cleanString(m.direccion).includes(cleanString(searchQuery)));
    } else {
      //   filtered =
      //     selectedFilter && selectedFilter._id
      //       ? allRecords.filter((m) => m.genre._id === selectedFilter._id)
      //       : allRecords;
      filtered = allRecords;
    }
    const ordered = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const records = paginate(ordered, currentPage, pageSize);
    return { totalCount: filtered.length, records };
  };

  render() {
    const { length: count } = this.state.records;

    const {
      pageSize,
      currentPage,
      sortColumn,
      //   genres,
      searchQuery,
      permisos,
    } = this.state;

    const { totalCount, records } = this.getPagedData();

    if (currentPage > Math.ceil(totalCount / pageSize) && totalCount > 0) this.setState({ currentPage: currentPage - 1 });
    return (
      <React.Fragment>
        <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} />

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* <div className="card-header">
                    <h3 className="card-title">Datos Personales</h3>
                  </div> */}
                  {/* /.card-header */}
                  <div className="card-body h-75">
                    <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          {permisos && permisos.canAdd && (
                            <div className="dt-buttons btn-group flex-wrap">
                              {" "}
                              <Link to="/inmuebles/nuevo">
                                <button className="btn btn-secondary buttons-copy buttons-html5" tabIndex={0} aria-controls="datatable" type="button">
                                  <span>Nuevo</span>
                                </button>{" "}
                              </Link>
                            </div>
                          )}
                        </div>
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                      </div>

                      {/* <ProyectosTable
                        records={records}
                        onDelete={(id) => this.handleDelete(id)}
                        onEdit={(id) => this.handleEdit(id)}
                        onSort={(sortColumn) => this.handleSort(sortColumn)}
                        sortColumn={sortColumn}
                      /> */}

                      {/* <table className="table table-striped projects"> */}

                      <table className="table table-striped table-hover dataTable dtr-inline projects">
                        {/* <thead>
                          <tr>
                            <th style={{ width: "10%" }}></th>
                            <th style={{ width: "10%" }}>Codigo</th>
                            <th style={{ width: "30%" }}>Direccion</th>
                            <th style={{ width: "30%" }}>Propietario</th>
                            <th style={{ width: "10%" }}>Monto</th>
                          </tr>
                        </thead> */}

                        <TableHeader columns={this.columns} sortColumn={sortColumn} onSort={this.handleSort} />

                        <tbody>
                          {records.map((record, index) => {
                            return (
                              <React.Fragment key={Math.random()}>
                                <tr key={record._id} data-widget="expandable-table" aria-expanded="false">
                                  <td>
                                    {record.fotos.length > 0 && <img src={record.fotos[0].archivo ? config.filesUrl + "/inmuebles/" + record._id + "/fotos/" + record.fotos[0].archivo : ""} style={{ height: "60px" }}></img>}
                                    {record.fotos.length === 0 && <img src="/dist/img/sinimagen.jpg" style={{ height: "60px" }}></img>}
                                  </td>
                                  <td onClick={() => this.handleView(record._id)}>{record.codigo.toString().padStart(8, "0")}</td>
                                  <td onClick={() => this.handleView(record._id)}>
                                    <a>{direccionCompleta(record)}</a>
                                    <br />
                                    <small>{localidadCompleta(record)}</small>
                                  </td>
                                  <td onClick={() => this.handleView(record._id)}>{record.tipos[0] ? record.tipos[0].descripcion : ""}</td>
                                  {/* <td onClick={(e) => this.handleView(e, record._id)}>Apellido, Nombre</td> */}
                                  <td onClick={() => this.handleView(record._id)}>$ 79.000</td>
                                  <td className="project-actions text-right">
                                    <i className="fas fa-pencil-alt mr-2 clickable text-olive-disabled" onClick={(e) => this.handleEdit(e, record._id)}></i>
                                    <i className="fas fa-trash-alt mr-2 clickable text-danger-disabled" onClick={(e) => this.handleDelete(e, record._id)}></i>
                                  </td>
                                </tr>
                                <tr className="expandable-body d-none">
                                  <td colSpan="5">
                                    <p>
                                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                                      it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
                                      Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>

                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <Records itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
                        </div>

                        <div className="col-sm-12 col-md-7">
                          <Pagination onPageChange={(page) => this.handlePageChange(page)} itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Inmuebles);

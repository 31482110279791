import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/servicios";

export async function getServicios() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getServicio(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function saveServicio(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else return await http.post(serviceEndpoint, data);
}

export async function deleteServicio(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

export async function getServiciosSelect() {
  let records = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((record) => {
      records.push({ value: record.descripcion, label: record.descripcion, extras: record.extras });
    });
    return records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export const serviciosPromiseOptions = (inputValue) =>
  http
    .get(serviceEndpoint, { params: { search: inputValue } })
    .then(function (response) {
      let options = response.data.records.map((record) => ({
        value: record.descripcion,
        label: record.descripcion,
        extras: record.extras,
      }));
      return options;
    })
    .catch(function (error) {
      console.log(error);
    });

import React, { Component } from "react";
import Table from "../common/table";

class ProfilesTable extends Component {
  columns = [
    {
      path: "descripcion",
      label: "Prefil de Usuario",
      width: "110%",
      onClick: (record) => this.props.onView(record._id),
      // content: (record) => (
      //   <Link to={`/clientes/${record._id}`}>{record.apellido}</Link>
      // ),
    },
  ];

  componentWillMount = () => {
    const { permisos } = this.props;
    if (permisos.canModify)
      this.columns.push({
        key: "edit",
        content: (record) => <i className={(record.descripcion !== "Administrador" ? "visible" : "invisible") + " fa fa-edit clickable"} aria-hidden="true" onClick={() => this.props.onEdit(record._id)}></i>,
        isicon: true,
        dataClass: "text-olive-disabled",
      });

    if (permisos.canDelete)
      this.columns.push({
        key: "delete",
        content: (record) => <i className={(record.descripcion !== "Administrador" ? "visible" : "invisible") + " fa fa-trash-alt clickable"} aria-hidden="true" onClick={() => this.props.onDelete(record._id)}></i>,
        isicon: true,
        dataClass: "text-danger-disabled",
      });
    // this.setState({ columns });
  };

  render() {
    const { records, sortColumn, onSort } = this.props;
    return <Table data={records} columns={this.columns} sortColumn={sortColumn} onSort={onSort} />;
  }
}

export default ProfilesTable;

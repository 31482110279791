import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const ReportTableHeader = (data) => (
  <View style={data.headerStyles}>
    {data.columns.map((column, index) => {
      let columnStyle = {};
      if (column.styles) columnStyle = { ...column.styles, width: column.width };
      let styles = StyleSheet.create({
        column: columnStyle,
      });
      return (
        <Text key={`rth${index}`} style={styles.column}>
          {column.header}
        </Text>
      );
    })}
  </View>
);

export default ReportTableHeader;

import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/monedas";

export async function getMonedas() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getMonedasSelect() {
  let records = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((record) => {
      records.push({ value: record._id, label: record.descripcion });
    });
    return records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getMonedasSimboloSelect() {
  let records = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((record) => {
      records.push({ value: record._id, label: record.simbolo });
    });
    return records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/inmuebles";

export async function getAlquileres() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getInmueble(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function saveInmueble(data) {
  if (data._id) {
    try {
      const response = await http.put(serviceEndpoint + "/" + data._id, data);
      return response;
    } catch (error) {
      if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
      else return null;
    }
  } else {
    try {
      const response = await http.post(serviceEndpoint, data);
      return response.data.record._id;
    } catch (error) {
      if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
      else return null;
    }
  }
}

export function direccionCompleta(record) {
  return record.direccion.trim() + " Nº" + record.nro.trim() + (record.piso !== "" ? " piso " + record.piso.trim() : "") + (record.dto !== "" ? " Dto " + record.dto.trim() : "");
}

export function localidadCompleta(record) {
  if (record.localidad) return record.localidad.nombre + ", " + record.localidad.provincia.nombre + ", " + record.localidad.provincia.pais.nombre;
  else return "";
}

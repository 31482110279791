import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Input from "../common/input";
import { Link } from "react-router-dom";
import { resetPassword } from "../../services/userService";
import PasswordStrengthMeter from "../common/passwordStrengthMeter";
import zxcvbn from "zxcvbn";
import { toastBigError } from "../utils/toasts";

class ResetPassword extends Component {
  state = {
    data: {
      password: "",
      confirm: "",
    },
    sent: false,
  };

  goHome = () => {
    this.props.history.push({
      pathname: "/login",
    });
  };

  handleSubmit = async () => {
    const id = this.props.match.params.id;
    const token = this.props.match.params.token;
    try {
      const response = await resetPassword(id, token, this.state.data);
      this.setState({ sent: true });
    } catch (error) {
      // console.log("error");
      toastBigError("Enlace inválido o vencido", "Genere una nueva solicitud para reestablecer su contraseña");
    }
  };

  handleChange = (e, control) => {
    const { currentTarget: input } = e;
    const inputMode = e.target.inputMode;
    let { data } = this.state;
    if (control === "password") data.password = input.value;
    else data.confirm = input.value;
    this.setState({ data });
  };

  render() {
    const { password, confirm } = this.state.data;
    const { sent } = this.state;
    const testValue = zxcvbn(password);
    return (
      <div>
        <div style={{ backgroundColor: "#e9ecef" }} className="fullscreen flex-column justify-content-center align-items-center">
          <div className="login-box">
            <div className="login-logo">
              <b>Khios</b>Web
            </div>
            {/* /.login-logo */}
            <div className="card">
              <div className="card-body login-card-body">
                {!sent && (
                  <>
                    <p className="login-box-msg">Reestablecer contraseña. Por favor ingrese su nueva contraseña.</p>
                    <form onSubmit={this.handleSubmit}>
                      {/* <div className="input-group mb-3"> */}
                      <Input float password name="password" label="Contraseña" value={password} onChange={(e) => this.handleChange(e, "password")} />
                      <PasswordStrengthMeter className="mb-3" password={password} />
                      {/* <input type="password" className="form-control" placeholder="Password" />
                    <div className="input-group-append">
                    <div className="input-group-text">
                    <span className="fas fa-lock" />
                    </div>
                  </div> */}
                      {/* </div> */}
                      {/* <div className="input-group mb-3"> */}
                      <Input className="mt-3 mb-5" float password name="confirm" label="Confirmar Contraseña" value={confirm} onChange={(e) => this.handleChange(e, "confirm")} />
                      {/* <input type="password" className="form-control" placeholder="Confirm Password" />
                    <div className="input-group-append">
                    <div className="input-group-text">
                    <span className="fas fa-lock" />
                    </div>
                  </div> */}
                      {/* </div> */}
                      <div className="row">
                        <div className="col-12">
                          <button type="button" className="btn btn-primary btn-block" disabled={this.state.data.password !== this.state.data.confirm || this.state.data.password === "" || testValue.score < 2} onClick={this.handleSubmit}>
                            Restablecer Contraseña
                          </button>
                        </div>
                        {/* /.col */}
                      </div>
                    </form>
                  </>
                )}
                {sent && (
                  <>
                    <p className="login-box-msg mb-4">Contraseña restablecida correctamente. Ya puede ingresar al sistema con sus nuevas credenciales.</p>
                    <div className="row">
                      <div className="col-12">
                        <button type="button" className="btn btn-primary btn-block" onClick={this.goHome}>
                          Aceptar
                        </button>
                      </div>
                      {/* /.col */}
                    </div>
                  </>
                )}
                {!sent && (
                  <p className="mt-3 mb-1">
                    <Link to="/login">Ingreso al sistema</Link>
                  </p>
                )}
              </div>
              {/* /.login-card-body */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);

import React from "react";
import Form from "../common/newForm";
import Joi from "joi-browser";
import Input from "../common/input";
import Avatar from "react-avatar";
import AvatarEdit from "react-avatar-edit";
import ContentHeader from "../common/contentHeader";
import RoundButton from "../common/roundButton";
import FileUploadContainer from "../common/fileUploader";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer/dist/ReactTabHotkeyReplacer";
import { withRouter } from "react-router-dom";
import { getEmpresa, saveEmpresa, uploadLogo } from "../../services/empresasService";
import { getConfig } from "../../config/config.js";
import { timeSince } from "../utils/utils.js";
import { getProfilesSelect } from "../../services/profilesService";
import { saveUser, deleteUser } from "../../services/userService";
import { toastConfirm, toastError } from "../utils/toasts";
import _ from "lodash";

const config = getConfig();
const filesUrl = config.filesUrl + "/empresas/";
const childRecordsPerPage = config.childRecordsPerPage;

// General Focus Hook
// const utilizeFocus = () => {
//   const ref = React.createRef();
//   const setFocus = () => {
//     ref.current && ref.current.focus();
//   };
//   return { setFocus, ref };
// };

class EmpresasForm extends Form {
  title = "Empresa";
  childPageLength = childRecordsPerPage;

  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Empresas",
      link: "/empresas",
    },
    {
      title: "Detalles",
    },
  ];

  // constructor(props) {
  //   super(props);
  //   this.razonSocial = utilizeFocus();
  // }

  state = {
    user: true,
    menuLink: "/empresas",
    action: "",
    data: {
      razonSocial: "",
      domicilio: "",
      demo: false,
      demoValid: "",
      logoPantalla: "",
      logoPapel: "",
      usuarios: [],
    },
    childIndex: 0,
    child: {
      nombre: "",
      apellido: "",
      email: "",
      password: "",
      confirm: "",
      profileId: "",
      avatar: "",
    },
    childAction: "",
    childPage: 1,
    profiles: [],
    profileId: "",
    errors: {},
  };

  schema = {
    _id: Joi.string(),
    razonSocial: Joi.string()
      .label("Razón Social")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar una Razón Social" };
            default:
              return { message: "Debe ingresar una Razón Social" };
          }
        });
      }),
    domicilio: Joi.string()
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar un domicilio" };
            default:
              return { message: "Debe ingresar un domicilio" };
          }
        });
      }),
    demo: Joi.boolean(),
    demoValid: Joi.string().optional().allow(null, ""),
    logoPantalla: Joi.string().optional().allow(null, ""),
    logoPapel: Joi.string().optional().allow(null, ""),
    url: Joi.string(),
    usuarios: Joi.array().items(),
  };

  render() {
    const { child, childPage, profiles, profileId, user, action } = this.state;
    const { usuarios } = this.state.data;
    const totalPages = Math.ceil(usuarios.length / this.childPageLength);
    const minPage = Math.max(1, childPage - 2);
    const maxPage = Math.min(totalPages, minPage + 4);
    const childRecords = usuarios.slice((childPage - 1) * this.childPageLength, childPage * this.childPageLength);
    const pages = _.range(minPage, maxPage + 1);

    return (
      <React.Fragment>
        {/* Content Header (Page header) */}
        <ContentHeader title={this.title} breadcrumbs={user.isSuperAdmin ? this.breadcrumbs : []} />

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 className="card-title">
                      <RoundButton onClick={() => this.goBack()} iconClass="fas fa-arrow-left" className="btn-transparent mr-2" tooltip="Volver" />
                      Mi Empresa
                    </h3>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      <ReactTabHotkeyReplacer>
                        <div className="row">
                          <div className="col-12">
                            {this.renderInput("razonSocial", "Razón Social", "text", {
                              float: true,
                            })}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data._id && (
                            <React.Fragment>
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-group files">
                                      <label>Logo Para Pantalla</label>
                                      <FileUploadContainer
                                        onUpload={(files) => this.uploadLogo(files, "pantalla")}
                                        accept="image/jpeg, image/png"
                                        minSize={1024}
                                        maxSize={3072000}
                                        background={this.state.data.logoPantalla ? filesUrl + this.state.data.logoPantalla : ""}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="form-group files">
                                      <label>Logo Para Papel</label>
                                      <FileUploadContainer
                                        onUpload={(files) => this.uploadLogo(files, "papel")}
                                        accept="image/jpeg, image/png"
                                        minSize={1024}
                                        maxSize={3072000}
                                        background={this.state.data.logoPapel ? filesUrl + this.state.data.logoPapel : ""}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                {/* USERS LIST */}
                                <div className="card">
                                  <div className="card-header">
                                    <h3 className="card-title">Usuarios</h3>
                                    <div className="card-tools">
                                      <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus" />
                                      </button>
                                    </div>
                                  </div>
                                  {/* /.card-header */}
                                  <div className="card-body p-0">
                                    <ul className="users-list clearfix">
                                      {childRecords.map((usuario, index) => {
                                        return (
                                          <li className="clickable" key={`usuario${index}`}>
                                            <div>
                                              <a data-toggle="modal" data-target="#modal-default" onClick={() => this.handleChildEdit(index)}>
                                                <Avatar className="table-avatar" name={usuario.nombre + " " + usuario.apellido} size="64" round="100%" src={usuario.avatar} />
                                              </a>
                                              {/* <img src={`../../dist/img/user.jpg`} alt="Usuario" style={{ width: "64px" }} data-toggle="modal" data-target="#modal-default" onClick={() => this.handleChildEdit(index)} /> */}
                                              <i
                                                className="fas fa-times text-danger-disabled top-right"
                                                onClick={() =>
                                                  toastConfirm("¿ Eliminar Usuario ?", () => {
                                                    this.handleChildDelete(index);
                                                  })
                                                }
                                              ></i>
                                            </div>

                                            <a className="users-list-name">{usuario.nombre + " " + usuario.apellido}</a>
                                            <span className="users-list-date">{timeSince(usuario.lastLogin)}</span>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                    {/* /.users-list */}
                                  </div>
                                  {/* /.card-body */}
                                  <div className="card-footer ">
                                    <div>
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        data-toggle="modal"
                                        data-target="#modal-default"
                                        onClick={() => {
                                          this.handleOpenModal("A");
                                        }}
                                      >
                                        <i className="fa fa-plus pr-2" aria-hidden="true"></i>
                                        Nuevo
                                      </button>

                                      {pages.length > 1 && (
                                        <ul className="pagination text-secondary pagination-sm float-right">
                                          <li className="page-item">
                                            <a className="page-link" onClick={() => this.handleChildPageChange(childPage - 1)}>
                                              «
                                            </a>
                                          </li>
                                          {pages.map((page) => {
                                            return (
                                              <li className={"page-item " + (page === childPage ? "active" : "")}>
                                                <a className="page-link " onClick={() => this.handleChildPageChange(page)}>
                                                  {page}
                                                </a>
                                              </li>
                                            );
                                          })}

                                          <li className="page-item">
                                            <a className="page-link" onClick={() => this.handleChildPageChange(childPage + 1)}>
                                              »
                                            </a>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  </div>
                                  {/* /.card-footer */}
                                </div>
                                {/*/.card */}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                        {user && user.isSuperAdmin && (
                          <div className="row">
                            <div className="col-6">
                              <div className="row align-items-center">
                                <div className="col-1 mr-5">{this.renderInput("demo", "Demo", "check")}</div>
                                <div className="col-5">{this.renderInput("valid", "Valido Hasta", "date", { float: true })}</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </ReactTabHotkeyReplacer>
                    </div>

                    <div className="card-footer">
                      {user.isSuperAdmin && this.renderSubmit("Guardar")}
                      {!user.isSuperAdmin && this.renderSubmitToMain("Guardar")}
                    </div>
                  </form>
                </div>
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.container-fluid */}
          <div id="modal-default" className="modal fade">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Usuario</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-2 text-center">
                      <div className="avatarContainer">
                        <Avatar className="avatar" name={child.nombre + " " + child.apellido} size="150" round="100%" src={child.avatar} />
                        {action != "C" && (
                          <div className="camera" data-toggle="modal" data-target="#modal-avatar">
                            <i className="fas fa-camera fa-4x "></i>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-2 text-center">
                      <img src={`../../dist/img/user.jpg`} style={{ borderRadius: "50%" }} alt="Usuario" />
                    </div> */}
                    <div className="col-10">
                      <ReactTabHotkeyReplacer>
                        <div className="row">
                          <div className="col-6">
                            <Input float name="nombre" label="Nombre" value={child.nombre} onChange={this.handleChildChange} />
                          </div>
                          <div className="col-6">
                            <Input float name="apellido" label="Apellido" value={child.apellido} onChange={this.handleChildChange} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <Input float name="email" label="e-mail" value={child.email} onChange={this.handleChildChange} />
                          </div>
                          <div className="col-6">
                            <Input name="profileId" float combo label="Perfil de Usuario" options={profiles} value={profileId} onChange={this.handleChildSelectChange} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <Input type="password" float name="password" label="Contraseña" value={child.password} onChange={this.handleChildChange} error={!(child.password === child.confirm)} />
                          </div>
                          <div className="col-6">
                            <Input type="password" float name="confirm" label="Confirmar Contraseña" value={child.confirm} onChange={this.handleChildChange} error={!(child.password === child.confirm)} />
                          </div>
                        </div>
                      </ReactTabHotkeyReplacer>
                    </div>
                  </div>
                </div>
                <div className="modal-footer justify-right">
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Cerrar
                  </button>
                  <button type="button" disabled={!(child.password === child.confirm)} className="btn btn-primary" data-dismiss="modal" onClick={this.handleSaveChild}>
                    Guardar Cambios
                  </button>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}

          <div id="modal-avatar" className="modal fade">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-body justify-content-center">
                  <AvatarEdit
                    label="Seleccione Imagen"
                    width="100%"
                    height={400}
                    onCrop={this.handleAvatarCrop}
                    onClose={this.handleAvatarClose}
                    onBeforeFileLoad={this.onBeforeFileLoad}
                    // src={this.state.data.avatar}
                  />
                </div>
                <div className="modal-footer justify-content-right">
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.handleSaveChild}
                  >
                    Guardar
                  </button>
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
        </section>
      </React.Fragment>
    );
  }

  handleAvatarCrop = (preview) => {
    const { child } = this.state;
    // data.avatar = preview;
    child.avatar = preview;
    this.setState({ child });
  };

  handleAvatarClose = () => {
    const { child } = this.state;
    child.avatar = null;
    this.setState({ child });
  };

  uploadLogo = async (uploadData, destino) => {
    const data = { ...this.state.data };
    const filename = await uploadLogo(this.state.data._id, uploadData, destino, destino === "pantalla" ? data.logoPantalla : data.logoPapel);
    if (filename) {
      switch (destino) {
        case "pantalla":
          data.logoPantalla = filename;
          break;
        case "papel":
          data.logoPapel = filename;
          break;
        default:
          break;
      }
      this.setState({ data });
    }
  };

  componentDidMount = async () => {
    const { onLoading, onDoneLoading } = this.props;
    const recordId = this.props.match.params.id;
    let record = {};
    onLoading();
    const profiles = await getProfilesSelect();
    // this.razonSocial.setFocus();
    if (recordId !== "nuevo" && recordId !== "nueva") record = await getEmpresa(recordId);
    console.log(record);
    onDoneLoading();
    if (record === "NETWORK ERROR") return this.props.history.replace("/noconnection");
    this.setState({
      profiles,
    });

    if (recordId === "nuevo" || recordId === "nueva") {
      return;
    } else {
      if (!record) return this.props.history.replace("/notfound");
      // const selectedCondicionIva = {
      //   label: record.condicionIva.descripcion,
      //   value: record.condicionIva._id,
      // };
      this.setState({
        data: this.mapToViewModel(record),
        // profiles,
        // condicionIvaId: selectedCondicionIva,
      });
    }
  };

  mapToViewModel = (empresa) => {
    return {
      _id: empresa._id,
      razonSocial: empresa.razonSocial,
      demo: empresa.demo ? empresa.demo : false,
      valid: empresa.valid,
      logoPantalla: empresa.logoPantalla,
      logoPapel: empresa.logoPapel,
      usuarios: empresa.usuarios,
    };
  };

  doSubmit = async () => {
    try {
      const newId = await saveEmpresa(this.state.data);
      if (this.state.data._id) this.props.history.push("/empresas");
      else {
        const data = { ...this.state.data };
        this.setState({
          data: { ...data, _id: newId },
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  doSubmitToMain = async () => {
    try {
      const newId = await saveEmpresa(this.state.data);
      const selectedModule = localStorage.getItem("selectedModule");
      if (this.state.data._id) this.props.history.push("/" + selectedModule);
      else {
        const data = { ...this.state.data };
        this.setState({
          data: { ...data, _id: newId },
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  handleChildEdit = (index) => {
    const { profiles } = this.state;
    const { usuarios } = this.state.data;
    const selectedProfile = profiles.find((item) => item.value === usuarios[index].profile);
    const child = { ...usuarios[index] };
    delete child.password;
    this.setState({
      profileId: selectedProfile,
      childAction: "M",
      child,
      childIndex: index,
    });
  };

  handleChildDelete = async (index) => {
    const originalUsers = [...this.state.data.usuarios];
    let usuarios = [...this.state.data.usuarios];
    const id = usuarios[index]._id;

    usuarios.splice(index, 1);
    let data = { ...this.state.data };
    data.usuarios = usuarios;
    this.setState({ data });
    try {
      await deleteUser(id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) toastError("Ese registro ya fue eliminado");
      let data = { ...this.state.data };
      data.usuarios = originalUsers;
      this.setState({ data });
    }
  };

  handleChildPageChange = (newPage) => {
    let childPage = newPage;
    if (newPage < 1) childPage = 1;
    if (newPage > this.totalPages) childPage = this.totoalPages;
    this.setState({ childPage });
  };

  handleOpenModal = (status) => {
    this.state.childAction = status;
  };

  handleSaveChild = async () => {
    const { child, childAction } = this.state;

    console.log(child);

    let usuarios = [...this.state.data.usuarios];
    let data = { ...this.state.data };

    await saveUser(child, data._id);

    if (childAction === "A") {
      usuarios.push(child);
    }
    if (childAction === "M") {
      usuarios[this.state.childIndex].nombre = child.nombre;
      usuarios[this.state.childIndex].apellido = child.apellido;
      usuarios[this.state.childIndex].avatar = child.avatar;
      usuarios[this.state.childIndex].profile = child.profile;
    }
    data.usuarios = usuarios;
    // this.setState({ data, childAction: "", child: { nombre: "" } });
    this.setState({ data, childAction: "" });
  };

  handleChildChange = ({ currentTarget: input }) => {
    let child = { ...this.state.child };
    child[input.name] = input.value;
    this.setState({ child });
  };

  handleChildSelectChange = (selectedOption, name) => {
    const input = name.name;
    const value = selectedOption.value;
    // const errors = { ...this.state.errors };
    // const errorMessage = this.validateSelect(input, value);
    // if (errorMessage) errors[input] = errorMessage;
    // else delete errors[input];
    const child = { ...this.state.child };
    child[input] = value;
    this.setState({ [input]: selectedOption });
    this.setState({ child });
  };
}

export default withRouter(EmpresasForm);

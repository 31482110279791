import React from "react";

const Records = ({ itemsCount, pageSize, currentPage }) => {
  return (
    <div
      className="dataTables_info"
      id="datatable_info"
      role="status"
      aria-live="polite"
    >
      {itemsCount === 0 && "No hay registros para mostrar"}
      {itemsCount > 0 &&
        itemsCount > pageSize &&
        `${(currentPage - 1) * pageSize + 1} -
      ${Math.min((currentPage - 1) * pageSize + pageSize, itemsCount)} de ${" "}
      ${itemsCount} registros`}
      {itemsCount > 1 && itemsCount <= pageSize && `${itemsCount} registros`}
      {itemsCount === 1 && `${itemsCount} registro`}
    </div>
  );
};

export default Records;

import http from "./httpService";

import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/shortcuts";
const modulo = localStorage.getItem("selectedModule");

export async function getShortcuts() {
  try {
    const response = await http.get(serviceEndpoint + "/" + modulo);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return null;
    else return [];
  }
}

export async function saveShortcut(data) {
  return await http.post(serviceEndpoint + "/" + modulo, data);
}

import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";

export function toastError(title, timeout = 1500) {
  var Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    iconColor: "white",
    background: "#f27474",
    timerProgressBar: true,
    showConfirmButton: false,
    timer: timeout,
  });
  const MySwal = withReactContent(Toast);
  MySwal.fire({
    icon: "error",
    title: "<span style='color: #fff'>" + title + "</span>",
  });
}

export function toastInfo(title, timeout = 2000) {
  var Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    iconColor: "white",
    background: "#87adbd",
    timerProgressBar: true,
    showConfirmButton: false,
    timer: timeout,
  });
  const MySwal = withReactContent(Toast);
  MySwal.fire({
    icon: "info",
    title: "<span style='color: #fff'>" + title + "</span>",
  });
}

export function toastConfirm(title, callbackTrue = null, callbackFalse = null) {
  var Alert = Swal.mixin({
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: `Si`,
    confirmButtonColor: "#84bca3", //"#888888",
    denyButtonColor: "#e77e89", //"#AAAAAA",
    denyButtonText: `No`,
    customClass: {
      confirmButton: "order-1",
      denyButton: "order-2",
    },
  });
  const MySwal = withReactContent(Alert);
  MySwal.fire({
    title: title,
  }).then((result) => {
    if (result.isConfirmed) {
      if (callbackTrue) callbackTrue();
    } else {
      if (callbackFalse) callbackFalse();
    }
  });
}

export function toastBigError(title, text) {
  var Alert = Swal.mixin({
    confirmButtonText: `Aceptar`,
    confirmButtonColor: "#84bca3", //"#888888",
    icon: "error",
  });
  const MySwal = withReactContent(Alert);
  MySwal.fire({
    title: title,
    text: text,
  });
}

export function toastTimeout(title, html, timer, timerProgressBar, icon, callBack) {
  let timerInterval;
  Swal.fire({
    title: title,
    html: html,
    timer: timer,
    timerProgressBar: timerProgressBar,
    icon: icon,
    didOpen: () => {
      Swal.showLoading();
      // timerInterval = setInterval(() => {
      //   const content = Swal.getContent()
      //   if (content) {
      //     const b = content.querySelector('b')
      //     if (b) {
      //       b.textContent = Swal.getTimerLeft()
      //     }
      //   }
      // }, 100)
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      // console.log('I was closed by the timer')
      callBack();
    }
  });
}

// .colored-toast.swal2-icon-success {
//   background-color: #a5dc86 !important;
// }

// .colored-toast.swal2-icon-error {
//   background-color: #f27474 !important;
// }

// .colored-toast.swal2-icon-warning {
//   background-color: #f8bb86 !important;
// }

// .colored-toast.swal2-icon-info {
//   background-color: #3fc3ee !important;
// }

// .colored-toast.swal2-icon-question {
//   background-color: #87adbd !important;
// }

// .colored-toast .swal2-title {
//   color: white;
// }

// .colored-toast .swal2-close {
//   color: white;
// }

// .colored-toast .swal2-content {
//   color: white;
// }

import React from "react";
import Select from "react-select";

const selectValidStyle = {
  control: (styles) => ({
    ...styles,
    border: `1px solid`,
    color: `black`,
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    color: isSelected ? `white` : `black`,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: `black`,
  }),
};

const selectInvalidStyle = {
  control: (styles) => ({
    ...styles,
    border: `1px solid`,
    color: `red`,
  }),
};

// const customStyles = {
//   control: (base, state) => ({
//     ...base,
//     background: "#023950",
//     // Overwrittes the different states of border
//     borderColor: state.isFocused ? "yellow" : "green",
//     // Removes weird border around container
//     boxShadow: state.isFocused ? null : null,
//     "&:hover": {
//       // Overwrittes the different states of border
//       borderColor: state.isFocused ? "red" : "blue",
//     },
//   }),
// };

const Combo = ({
  name,
  label,
  options,
  className = "form-select",
  error,
  ...rest
}) => {
  return (
    <div className="form-group">
      {/* {label && <label htmlFor={name}>{label}</label>} */}
      <Select
        {...rest}
        id={name}
        name={name}
        options={options}
        styles={error ? selectInvalidStyle : selectValidStyle}
        className={`${className}` + (error ? " is-invalid" : "")}
        placeholder={label}
      />
      {error && (
        <div>
          <small className="text-red w-100">{error}</small>
        </div>
      )}
    </div>
  );
};
export default Combo;

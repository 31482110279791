import React, { Component } from "react";
import Table from "../common/table";

class PersonasTable extends Component {
  columns = [
    {
      path: "apellido",
      sort: (item) => item.apellido.toLowerCase(),
      label: "Apellido/R.Social",
      width: "20%",
      onClick: (record) => this.props.onView(record._id),
      // content: (record) => (
      //   <Link to={`/ntes/${record._id}`}>{record.apellido}</Link>
      // ),
    },
    {
      path: "nombre",
      sort: (item) => item.nombre.toLowerCase(),
      label: "Nombre",
      width: "20%",
      onClick: (record) => this.props.onView(record._id),
    },
    {
      path: "cuit",
      label: "CUIT",
      width: "70%",
      onClick: (record) => this.props.onView(record._id),
    },
    // { path: "dailyRentalRate", label: "Rate" },
    // {
    //   key: "like",
    //   content: (movie) => (
    //     <Like onClick={() => this.props.onLike(movie)} liked={movie.liked} />
    //   ),
    // },
  ];

  componentWillMount = () => {
    const { permisos } = this.props;
    if (permisos.canModify)
      this.columns.push({
        key: "edit",
        content: (record) => <i className="fa fa-edit clickable" aria-hidden="true" onClick={() => this.props.onEdit(record._id)}></i>,
        isicon: true,
        dataClass: "text-olive-disabled",
      });

    if (permisos.canDelete)
      this.columns.push({
        key: "delete",
        content: (record) => <i className="fa fa-trash-alt clickable" aria-hidden="true" onClick={() => this.props.onDelete(record._id)}></i>,
        isicon: true,
        dataClass: "text-danger-disabled",
      });
    // this.setState({ columns });
  };

  render() {
    const { records, sortColumn, onSort } = this.props;

    return <Table data={records} columns={this.columns} sortColumn={sortColumn} onSort={onSort} />;
  }
}

export default PersonasTable;

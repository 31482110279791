import React from "react";

const TextArea = ({
  name,
  label,
  error,
  focus,
  className = "form-control form-control-sm",
  ...rest
}) => {
  if (focus)
    return (
      <div className="form-label-group">
        <textarea
          {...rest}
          id={name}
          name={name}
          ref={focus.ref}
          rows="8"
          placeholder={label}
          className={`${className}` + (error ? " is-invalid" : "")}
        />
        {/* {label && <label htmlFor={name}>{label}</label>} */}
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
      </div>
    );
  else
    return (
      <div className="form-label-group">
        <textarea
          {...rest}
          id={name}
          name={name}
          rows="8"
          placeholder={label}
          className={`${className}` + (error ? " is-invalid" : "")}
        />
        {/* {label && <label htmlFor={name}>{label}</label>} */}
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
      </div>
    );
};

export default TextArea;

import React, { Component } from "react";

class Toggle extends React.Component {
  constructor(props) {
    // console.log("toggle");
    // console.log(props);
    super(props);
    this.state = {
      isChecked: props.checked || false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    if (!this.props.disabled) this.setState({ isChecked: !this.state.isChecked });
    this.props.onChange(e);
  }
  render() {
    return (
      <div className="row align-items-center">
        <label className="switch">
          <input id={this.props.id} name={this.props.name} type="checkbox" checked={this.state.isChecked} value={this.state.isChecked} onChange={(e) => this.handleChange(e)} />
          <div className="slider"></div>
        </label>
        <label className="pl-2 slider-label">{this.props.label}</label>
      </div>
    );
  }
}

export default Toggle;

import React from "react";

export default function Test() {
  console.log("paso");

  //   let pepas = [];
  const [pepas, setPepas] = React.useState([]);
  let totos = [];
  console.log("pepas");
  console.log(pepas);
  console.log("totos");
  console.log(totos);

  React.useEffect(() => {
    console.log("useEffect");
    totos.push("1");
    totos.push("2");
    totos.push("3");
    setPepas(["1", "2", "3"]);
    console.log("pepasLEF");
    console.log(pepas);
    console.log("totosLEF");
    console.log(totos);
  }, []);

  React.useEffect(() => {
    console.log("cambió Pepas");
  }, [pepas]);

  const changePepas = () => {
    console.log("changePepas");
    setPepas(["4", "5", "6"]);
  };

  return (
    <div>
      <div>PEPAS</div>
      <ul>
        {pepas.map((pepa) => (
          <li>{pepa}</li>
        ))}
      </ul>
      <div>TOTOS</div>
      <ul>
        {totos.map((toto) => (
          <li>{toto}</li>
        ))}
      </ul>
      <button onClick={() => changePepas()}>Change Pepas</button>
    </div>
  );
}

import React, { Component } from "react";

class TableHeader extends Component {
  raiseSort = (path) => {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path) sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column) => {
    const { sortColumn } = this.props;
    if (column.path !== sortColumn.path) return "sorting";
    if (sortColumn.order === "asc") return "sorting sorting_asc";
    else return "sorting sorting_desc";
  };

  setStyle = (column) => {
    if (!column.width) return {};
    else return { width: column.width };
  };

  render() {
    const { columns } = this.props;
    let clases = "";
    return (
      <thead>
        <tr role="row">
          {columns.map(
            (column, index, array) => (
              (clases = column.label ? this.renderSortIcon(column) : ""),
              (clases += index < array.length - 1 && column.isicon && array[index + 1].isicon ? " border-right-0" : ""),
              (clases += column.isicon ? " border-left-0" : ""),
              (
                <th
                  // className={column.label ? this.renderSortIcon(column) : ""}
                  // className={
                  //   index < array.length - 1 && array[index + 1].isicon
                  //     ? "text-red"
                  //     : ""
                  // }
                  // className={column.isicon ? "border-left-0!important" : ""}
                  // key={column.path || column.key}
                  key={Math.random()}
                  className={clases}
                  onClick={() => this.raiseSort(column.sort ? column.sort : column.path)}
                  tabIndex={index}
                  aria-controls="datatable"
                  rowSpan={1}
                  colSpan={1}
                  aria-sort="descending"
                  aria-label=""
                  style={this.setStyle(column)}
                >
                  {column.label}
                </th>
              )
            )
          )}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;

import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { getUser } from "../../services/userService";
import { getMenues } from "../../services/menuesService";
import { getPermisos } from "../../services/permisosService";
import { getConfig } from "../../config/config.js";
import { toastInfo } from "../utils/toasts";
import { imageExists } from "../utils/utils";
import { useHistory } from "react-router";

export default function Sidebar(props) {
  const [mounted, setMounted] = useState(false);
  const [user, setUser] = useState(null);
  const [logo, setLogo] = useState(null);
  const [menues, setMenues] = useState([]);
  const [permisos, setPermisos] = useState([]);
  // const [selectedModule, setSelectedModule] = useState(null);

  const config = getConfig();
  const history = useHistory();

  const fetchData = async () => {
    try {
      const jwt = localStorage.getItem("token");
      const selectedModule = localStorage.getItem("selectedModule");
      if (!selectedModule) {
        history.push({
          pathname: "/main",
          // state: {
          //   response: messageFromServer
          // }
        });
      }
      const decoded = jwt ? jwtDecode(jwt) : null;
      const user = await getUser(decoded.user._id);
      setUser(user);
      if (imageExists(config.filesUrl + "/empresas/" + user.empresa.logoPantalla)) setLogo(config.filesUrl + "/empresas/" + user.empresa.logoPantalla);
      const menues = await getMenues(selectedModule);
      setMenues(menues);
      const permisos = await getPermisos(user.profile._id);
      setPermisos(permisos);
    } catch (exception) {
      localStorage.removeItem("token");
      setUser(null);
    }
  };

  const checkLink = (url) => {
    if (user.profile.isAdmin || user.isSuperAdmin) return true;
    var found = permisos.findIndex((element) => element.menu.link === url);
    if (found >= 0) return true;
    else return false;
  };

  if (!mounted) {
    fetchData();
    setMounted(true);
  }

  const parseUrl = (url) => {
    var newUrl = url;
    newUrl = newUrl.replace(":empresaId", user.empresa._id);
    return newUrl;
  };

  const handleClick = (e, url) => {
    var newUrl = parseUrl(url);
    if (!checkLink(newUrl)) {
      e.preventDefault();
      toastInfo("No tenés acceso a esta opción");
    }
  };

  useEffect(() => {}, []);

  return (
    mounted && (
      <div>
        {/* Main Sidebar Container */}
        <aside className="main-sidebar sidebar-dark-primary elevation-4 align-items-center">
          {/* Brand Logo */}
          {/* <NavLink to="/" className="brand-link">
            <img src="/dist/img/logo.png" alt="Logo" className="brand-image " style={{ opacity: ".8" }} />
            <span className="ml-3 brand-text font-weight-bold appname">KHIOS</span>
          </NavLink> */}
          <a
            className="brand-link clickable"
            onClick={() => {
              localStorage.removeItem("selectedModule");
              window.location = "/";
            }}
          >
            <img src="/dist/img/logo.png" alt="Logo" className="brand-image " style={{ opacity: ".8" }} />
            <span className="ml-3 brand-text font-weight-bolder appname">KHIOS WEB</span>
          </a>
          {/* Sidebar */}
          {menues && (
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="container">
                {user && logo && <img src={logo} className="brand pt-2 mt-1 mb-2" alt="Logo" />}
                {!logo && <img src="/dist/img/bw.jpg" className=" img-fluid mt-1 mb-2" style={{ width: "100%" }} />}
              </div>

              {/* SidebarSearch Form */}
              {/* <div className="form-inline">
                <div className="input-group" data-widget="sidebar-search">
                  <input className="form-control form-control-sidebar" type="search" placeholder="Buscar" aria-label="Buscar" />{" "}
                  <div className="input-group-append">
                    <button className="btn btn-sidebar">
                      <i className="fas fa-search fa-fw" />
                    </button>
                  </div>
                </div>
              </div> */}

              {/* Sidebar Menu */}
              <nav className="mt-2 ">
                <ul className="nav nav-pills nav-sidebar nav-child-indent nav-compact flex-column nav-collapse-hide-child" data-widget="treeview" role="menu" data-accordion="false">
                  {menues.map((menu, index) => (
                    <li key={`menu${index}`} className="nav-item ">
                      {menu.link && menu.submenus.length === 0 && (
                        <li className="nav-item">
                          <NavLink to={parseUrl(menu.link)} className="nav-link">
                            {menu.icon && <i className={`nav-icon ${menu.icon}`} />}
                            <p>{menu.label}</p>
                          </NavLink>
                        </li>
                      )}
                      {!menu.link && menu.submenus && (
                        <React.Fragment>
                          <a className="nav-link">
                            <i className={`nav-icon ${menu.icon}`} />
                            <p>
                              {menu.label}
                              <i className="right fas fa-angle-right" />
                            </p>
                          </a>
                          <ul className="nav nav-treeview">
                            {menu.submenus.map((submenu, index) => (
                              <React.Fragment key={`submenu${index}`}>
                                {submenu.link && submenu.submenus.length === 0 && (
                                  <li key={`submenu${index}`} className="nav-item">
                                    <NavLink to={parseUrl(submenu.link)} className="nav-link" onClick={(e) => handleClick(e, submenu.link)}>
                                      {submenu.icon && <i className={`nav-icon ${submenu.icon}`} />}
                                      <p>{submenu.label}</p>
                                    </NavLink>
                                  </li>
                                )}
                                {!submenu.link && submenu.submenus.length !== 0 && (
                                  <React.Fragment>
                                    <a className="nav-link">
                                      <i className={`nav-icon ${submenu.icon}`} />
                                      <p>
                                        {submenu.label}
                                        <i className="right fas fa-angle-right" />
                                      </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                      {submenu.submenus.map((sub2menu, index) => (
                                        <li key={`sub2menu${index}`} className="nav-item">
                                          <NavLink to={parseUrl(sub2menu.link)} className="nav-link">
                                            {sub2menu.icon && <i className={`nav-icon ${sub2menu.icon}`} />}
                                            <p>{sub2menu.label}</p>
                                          </NavLink>
                                        </li>
                                      ))}
                                    </ul>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            ))}
                          </ul>
                        </React.Fragment>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>

              {/* /.sidebar-menu */}
            </div>
          )}
          {/* /.sidebar */}
        </aside>
      </div>
    )
  );
}

import http from "./httpService";
import moment from "moment";
import "moment/locale/es";

import { getConfig } from "../config/config.js";
import { getCategoria, getCategoriasSelect } from "./categoriasService";
import { getProyectos } from "./proyectosService";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/cajasdomo";

export async function getNextAporte(proyecto, persona, moneda, categoria) {
  if (proyecto === "" || persona === "" || moneda === "" || categoria === "") return "";
  const response = await http.get(serviceEndpoint + "/aportes", {
    params: { proyecto: proyecto, persona: persona, moneda: moneda, categoria: categoria },
  });
  return { label: "Aporte #" + parseInt(response.data.proximo), maximo: parseFloat(response.data.maximo), proximo: response.data.proximo };
  // return "Aporte #" + (parseInt(response.data.cantidad) + 1);
}

export async function getCajaSetup() {
  const response = await http.get(serviceEndpoint + "/setup");
  return response.data;
}

async function processRecords(records, transporteSaldo, fechaQuery, total) {
  const categorias = await getCategoriasSelect();
  const proyectos = await getProyectos();
  let presupuestos = [];
  proyectos.forEach((proyecto) => {
    proyecto.presupuestos.forEach((item) => {
      if (item.estado === "Aceptado") {
        const insert = {
          value: item._id,
          label: item.descripcion,
        };
        presupuestos.push(insert);
      }
    });
  });
  const insertTransporte = {
    fecha: moment(fechaQuery).format("YYYY-MM-DD"),
    detalle: "Transporte de saldo",
    ingreso: parseFloat(transporteSaldo) > 0 ? Math.abs(transporteSaldo) : 0,
    egreso: parseFloat(transporteSaldo) < 0 ? Math.abs(transporteSaldo) : 0,
    totalCount: total,
    pendiente: false,
    saldo: transporteSaldo,
  };
  let saldo = transporteSaldo;
  if (transporteSaldo != 0) records.unshift(insertTransporte);
  records.forEach((element, index) => {
    records[index].label = "";
    records[index].totalCount = total;
    saldo += element.ingreso - element.egreso;
    records[index].saldo = saldo;
    if (element.categoria && element.categoria !== "") {
      const foundCategoria = categorias.find((m) => {
        return m.value.toString() === element.categoria.toString();
      });
      if (foundCategoria) {
        records[index].label = foundCategoria.label;
      }
    }
    if (element.presupuesto && element.presupuesto !== "") {
      const foundPresupuesto = presupuestos.find((m) => m.value.toString() === element.presupuesto.toString());
      if (foundPresupuesto) {
        records[index].label = foundPresupuesto.label;
      }
    }
  });
  return records;
}

export async function getMovimientosPresupuestos(idPresupuesto, isDoc) {
  try {
    const response = await http.get(serviceEndpoint, {
      params: {
        presupuesto: idPresupuesto,
        documentado: isDoc,
        pendiente: false,
      },
    });
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getCajas(tipo, idCaja, idMoneda, startingDate, page) {
  if (idMoneda) {
    try {
      switch (tipo) {
        case "U": {
          const response = await http.get(serviceEndpoint, {
            params: { noParent: true, moneda: idMoneda, pendiente: false, desde: startingDate, recordsPerPage: config.cajaRecordsPerPage, page: page - 1 },
          });
          return processRecords(response.data.records, response.data.transporteSaldo, startingDate, response.data.totalCount);
          break;
        }
        case "X": {
          const response = await http.get(serviceEndpoint, {
            params: { moneda: idMoneda, pendiente: true, desde: startingDate, recordsPerPage: config.cajaRecordsPerPage, page: page - 1 },
          });
          return processRecords(response.data.records, response.data.transporteSaldo, startingDate, response.data.totalCount);
          break;
        }
        case "P": {
          const response = await http.get(serviceEndpoint, {
            params: {
              moneda: idMoneda,
              proyecto: idCaja,
              noParent: true,
              pendiente: false,
              desde: startingDate,
              recordsPerPage: config.cajaRecordsPerPage,
              page: page - 1,
            },
          });
          return processRecords(response.data.records, response.data.transporteSaldo, startingDate, response.data.totalCount);
          break;
        }
        case "S": {
          const response = await http.get(serviceEndpoint, {
            params: { moneda: idMoneda, persona: idCaja, pendiente: false, desde: startingDate, recordsPerPage: config.cajaRecordsPerPage, page: page - 1 },
          });
          return processRecords(response.data.records, response.data.transporteSaldo, startingDate, response.data.totalCount);
          break;
        }
      }
    } catch (error) {
      if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
      else return [];
    }
  } else return [];
}

export async function saveCajaRecord(record) {
  if (record._id) return await http.put(serviceEndpoint + "/" + record._id, record);
  else return await http.post(serviceEndpoint, record);
}

export async function deleteCajaRecord(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/categorias";

export async function getCategorias() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getCategoria(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function saveCategoria(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else return await http.post(serviceEndpoint, data);
}

export async function deleteCategoria(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

export async function getCategoriasSelect() {
  let categorias = [];
  const response = await http.get(serviceEndpoint);
  response.data.records.map((categoria) => {
    categorias.push({ value: categoria._id, label: categoria.descripcion, afectaSocios: categoria.afectaSocios });
  });
  return categorias;
}

export async function getGroupedRubros() {
  let categorias = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((categoria) => {
      const etiqueta = categoria.descripcion;
      let opciones = [];
      categoria.rubros.map((rubro) => {
        opciones.push({ value: rubro._id, label: rubro.descripcion });
      });
      categorias.push({ label: categoria.descripcion, options: opciones });
    });
    return categorias;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}
export async function getRubros(recordId) {
  let rubros = [];
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    let opciones = [];
    response.data.record.rubros.map((rubro) => {
      opciones.push({ value: rubro.descripcion, label: rubro.descripcion });
    });
    return opciones;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export const categoriasPromiseOptions = (inputValue) =>
  http
    .get(serviceEndpoint, { params: { search: inputValue } })
    .then(function (response) {
      let options = response.data.records.map((categoria) => ({
        value: categoria._id,
        label: categoria.descripcion,
      }));
      return options;
    })
    .catch(function (error) {
      // console.log(error);
    });

import React, { Component } from "react";
import _ from "lodash";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    else {
      if (column.onClick)
        return (
          <span className="clickable" onClick={() => column.onClick(item)}>
            {_.get(item, column.path)}
          </span>
        );
      else return _.get(item, column.path);
    }
  };

  createKey = (item, column) => {
    return item._id + (column.path || column.key);
  };

  render() {
    const { data, columns } = this.props;
    let clases = "";
    return (
      <tbody>
        {data.map((item) => (
          <tr role="row" key={item._id}>
            {columns.map(
              (column, index, array) => (
                (clases = index < array.length - 1 && column.isicon && array[index + 1].isicon ? " border-right-0" : ""),
                (clases += column.isicon ? " border-left-0" : ""),
                (clases += column.dataClass ? " " + column.dataClass : ""),
                (
                  <td key={this.createKey(item, column)} className={clases}>
                    {this.renderCell(item, column)}
                  </td>
                )
              )
            )}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;

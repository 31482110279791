import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/cotizaciones";

export async function getCotizacion() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.cotizacion;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function saveCotizacion(data) {
  return await http.post(serviceEndpoint, data);
}

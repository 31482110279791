import React from "react";
import styled from "styled-components";
import { components } from "react-select";

export const Control = (props) => {
  return (
    <>
      <Label
        size={props.selectProps.size}
        isFocused={props.isFocused}
        isFloating={props.isFocused || props.hasValue}
      >
        {props.selectProps.label}
      </Label>
      <components.Control {...props} />
    </>
  );
};

// props.size ? (props.size === "sm" ? "0.775rem" : "1.1rem") : "0.9rem"};

const Label = styled.label`
  position: absolute;
  font-family: Roboto;
  transform: translateY(-51%);
  background-color: white;
  color: rgb(95, 99, 104);
  margin: 0;
  font-weight: 400 !important;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  z-index: 1;
  font-size: ${(props) =>
    props.size
      ? props.size === "sm"
        ? props.isFloating
          ? "0.8528rem"
          : "1.066rem"
        : props.isFloating
        ? "1.21rem"
        : "1.5125rem"
      : props.isFloating
      ? "0.99rem"
      : "1.2375rem"};

  color: ${(props) => (props.isFocused ? "#1a73e8" : "#5f6368")};
  padding: ${(props) => (props.isFloating ? "0 4px" : "0")};
  left: ${(props) => (props.isFloating ? "4px" : "1rem")};
  top: ${(props) => (props.isFloating ? `0` : `50%`)};
`;

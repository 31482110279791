import React, { useEffect, useState } from "react";
import FilerobotImageEditor, { TABS, TOOLS } from "react-filerobot-image-editor";

export default function ImageEditor({ id, imgFile, url, onClose, onSave }) {
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
    onClose();
  };

  const onBeforeSaveImage = () => {
    return false;
  };

  const onSaveImage = async (editedImageObject, designState) => {
    await onSave(id, imgFile, editedImageObject);
    setIsImgEditorShown(false);
    closeImgEditor();
  };

  const getFile = async () => {
    if (url && url !== "") {
      setIsImgEditorShown(true);
    }
  };

  useEffect(() => {
    getFile();
  }, [url]);

  return (
    <React.Fragment>
      {isImgEditorShown && (
        <FilerobotImageEditor
          source={url}
          onSave={(editedImageObject, designState) => onSaveImage(editedImageObject, designState)}
          onClose={closeImgEditor}
          annotationsCommon={{
            fill: "#000000",
          }}
          useBackendTranslations={true}
          defaultSavedImageType="jpg"
          avoidChangesNotSavedAlertOnLeave={false}
          language="es"
          Text={{ text: "Ingrese Texto...." }}
          Rotate={{ angle: 90, componentType: "buttons" }}
          showCanvasOnly={false}
          onBeforeSave={onBeforeSaveImage}
          Crop={{
            autoresize: true,
            // presetsItems: [
            //   {
            //     titleKey: "classicTv",
            //     descriptionKey: "4:3",
            //     ratio: 4 / 3,
            //     // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
            //   },
            //   {
            //     titleKey: "cinemascope",
            //     descriptionKey: "21:9",
            //     ratio: 21 / 9,
            //     // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
            //   },
            // ],
            // presetsFolders: [
            //   {
            //     titleKey: "socialMedia", // will be translated into Social Media as backend contains this translation key
            //     // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
            //     groups: [
            //       {
            //         titleKey: "facebook",
            //         items: [
            //           {
            //             titleKey: "profile",
            //             width: 180,
            //             height: 180,
            //             descriptionKey: "fbProfileSize",
            //           },
            //           {
            //             titleKey: "coverPhoto",
            //             width: 820,
            //             height: 312,
            //             descriptionKey: "fbCoverPhotoSize",
            //           },
            //         ],
            //       },
            //     ],
            //   },
            // ],
          }}
          // tabsIds={[TABS.ADJUST]} // or {['Adjust', 'Annotate', 'Watermark']}
          // defaultTabId={TABS.ADJUST} // or 'Annotate'
          // defaultToolId={TOOLS.TEXT} // or 'Text'
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK, TABS.RESIZE, TABS.FILTERS, TABS.FINETUNE]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ADJUST} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
        />
      )}
    </React.Fragment>
  );
}

import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/mensajes";

export async function getNewMessages(id) {
  try {
    const response = await http.get(serviceEndpoint + "/nuevos/" + id);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return null;
    else return [];
  }
}

import React, { Component, Fragment } from "react";
import { Page, Document, Image, StyleSheet } from "@react-pdf/renderer";
import ReportTitle from "./ReportTitle";
// import logo from "../../../../../../⁄src/logo.png";
import ReportTableHeader from "./ReportTableHeader";
import ReportTableRow from "./ReportTableRow";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1,
    flexDirection: "column",
  },
  header: {
    flexDirection: "row",
    // borderBottomColor: "#bff0fd",
    backgroundColor: "#bbbbbb",
    marginTop: 21,
    borderBottomWidth: 1,
    alignItems: "flex-start",
    height: 14,
    textAlign: "flex-start",
    fontStyle: "bold",
    // flexGrow: 1,
  },
  row: {
    flexDirection: "row",
    // borderBottomColor: "#bff0fd",
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
  },
  logo: {
    width: "15%",
  },
});

const Report = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="landscape">
      {/* <Image style={styles.logo} src={logo} /> */}
      <ReportTitle title={data.title} />
      <ReportTableHeader columns={data.columns} headerStyles={styles.header} />
      <ReportTableRow columns={data.columns} rowStyles={styles.row} rowData={data.items} itemStyles={data.itemStyles} />
    </Page>
  </Document>
);

export default Report;

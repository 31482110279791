import React, { useRef } from "react";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Control } from "../common/comboLabel";
import { ddns } from "../utils/utils";
import Toggle from "../common/toggle";

// import { withTheme } from "styled-components";

const Input = ({
  name,
  label,
  error,
  float,
  size,
  date,
  cuit,
  check,
  toggle,
  number,
  integer,
  thousandSeparator,
  currency,
  moneda,
  textarea,
  placeholder,
  phone,
  dni,
  mask,
  maskPlaceHolder,
  combo,
  asyncCreatable,
  asyncCombo,
  options,
  loadOptions,
  className,
  newRow,
  columns,
  password,
  preventForward,
  overrideKeyDown,
  formatCreateLabel,
  ...rest
}) => {
  var labelClass;
  var inputClass;
  var group = check ? "form-check" : float ? "form-label-group" : "form-group";
  var type = "text";
  var rem = 10;
  var placeHText = "";
  const factor = 1.375;
  const factorSize = size ? (size === "xs" ? 1.46 : size === "sm" ? 1.8125 : 2.875) : 2.25;
  const dropdownPadding = size ? (size === "xs" ? 0 : size === "sm" ? "0" : "2px 5px") : "1px 5px";
  const dropdownFactor = size ? (size === "xs" ? 0.48 : size === "sm" ? 0.6 : 0.8) : 0.9;
  const dropdownTop = size ? (size === "xs" ? "-4px" : size === "sm" ? "-4px" : "0") : "0";
  const fontSize = size ? (size === "xs" ? 0.7 * rem : size === "sm" ? 0.875 * rem : 1.2 * rem) : 1 * rem;
  const inputRef = useRef();
  let paddingTop = size ? (size === "xs" ? 1.2 : size === "sm" ? 1.5 : 2) : 3;
  let topPosition = size ? (size === "xs" ? 1.6 : size === "sm" ? 2 : 2) : 1;
  paddingTop = paddingTop * factor;
  topPosition = topPosition * factor;
  const containerPadding = size ? (size === "xs" ? "1px" : size === "sm" ? "2px 3px" : "2px 3px") : "1px 3px";

  if (password) type = "password";
  if (cuit) {
    mask = "99-99999999-9";
  }
  if (!maskPlaceHolder) maskPlaceHolder = "";
  if (!className) className = "";
  // const DropdownIndicator = (props) => {
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <svg
  //         height="13"
  //         width="13"
  //         viewBox="0 0 13 13"
  //         aria-hidden="true"
  //         focusable="false"
  //         class="css-tj5bde-Svg"
  //       >
  //         <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
  //       </svg>
  //     </components.DropdownIndicator>
  //   );
  // };

  const selectValidStyle = {
    control: (styles, state) => ({
      ...styles,
      border: `1px solid`,
      boxShadow: "none",
      backgroundColor: "#fff",
      // loadingMessage: "aaa",
      color: state.isFocused ? "#1a73e8" : "#5f6368",
      height: (factorSize * rem + 2) * factor,
      minHeight: (factorSize * rem + 2) * factor,
      fontSize: fontSize * factor,
    }),
    valueContainer: (styles, state) => ({
      ...styles,
      height: (factorSize * rem + 2) * factor,
      minHeight: (factorSize * rem + 2) * factor,
      padding: containerPadding,
      color: "#5f6368",
    }),
    singleValue: (styles, state) => ({
      ...styles,
      // height: factorSize * rem + 2,
      // minHeight: factorSize * rem + 2,
      paddingTop: paddingTop + "px",
      paddingLeft: "3px",
      // color: `black`,
    }),
    input: (styles, state) => ({
      ...styles,
      // height: factorSize * rem + 2,
      // minHeight: factorSize * rem + 2,
      // position: "absolute",
      top: topPosition + "px",
      paddingTop: paddingTop + "px",
      paddingLeft: "3px",
      color: `black`,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      height: (factorSize * rem + 2) * factor,
      minHeight: (factorSize * rem + 2) * factor,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      color: isSelected ? `white` : `black`,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "999",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      height: (factorSize * rem + 2) * dropdownFactor,
      width: (factorSize * rem + 2) * dropdownFactor,
      top: dropdownTop,
      padding: dropdownPadding,
    }),

    // clearIndicator: (styles) => ({ ...styles }),

    // group: (styles) => ({ ...styles }),
    // groupHeading: (styles) => ({ ...styles }),
    // indicatorSeparator: (styles) => ({ ...styles }),
    // loadingIndicator: (styles) => ({ ...styles }),
    // loadingMessage: (styles) => ({ ...styles }),
    // menuList: (styles) => ({ ...styles }),
    // menuPortal: (styles) => ({ ...styles }),
    // noOptionsMessage: (styles) => ({ ...styles }),
    // placeholder: (styles) => ({ ...styles }),

    // container: (styles) => ({ ...styles }),
    // multiValue: (styles) => ({ ...styles }),
    // multiValueLabel: (styles) => ({ ...styles }),
    // multiValueRemove: (styles) => ({ ...styles }),
  };

  const handleFocus = (event) => event.target.select();

  const onKeyDown = (e) => {
    if (overrideKeyDown) overrideKeyDown(e);
    if (e.keyCode === 13) {
      if (!preventForward) e.keyCode = 9;
      const form = e.target.form;
      if (form) {
        let index = Array.prototype.indexOf.call(form, e.target);
        if (form.elements[index + 1].type === "hidden") index++;
        if (preventForward) {
          form.elements[index].focus();
          e.keyCode = null;
        } else {
          form.elements[index + 1].focus();
        }
      }
    }
  };

  const selectInvalidStyle = {
    control: (styles) => ({
      ...styles,
      border: `1px solid`,
      boxShadow: "none",
      color: `red`,
    }),
  };

  const handleMaskDni = () => {
    if (inputRef.current && inputRef.current.value) {
      const stripped = inputRef.current.value.replaceAll(".", "");
      if (stripped.length === 7) return "9.999.9999";
      return "99.999.999";
    }
  };

  const handleMaskPhone = () => {
    if (inputRef.current && inputRef.current.value) {
      let search = inputRef.current.value.substring(0, 4);
      let found = ddns.find((element) => element.ddn === search);
      if (!found) {
        search = inputRef.current.value.substring(0, 3);
        found = ddns.find((element) => element.ddn === search);
      }
      if (!found) {
        search = inputRef.current.value.substring(0, 2);
        found = ddns.find((element) => element.ddn === search);
      }
      if (found) {
        switch (search.length) {
          case 4:
            return "9999 99 9999";
          case 3:
            return "999 999 9999";
          case 2:
            return "99 9999 9999";
          default:
            return "9999999999";
        }
      } else {
        const stripped = inputRef.current.value.replaceAll(" ", "");
        if (stripped.length > 8) return "99999999999999999999";
        switch (stripped.length) {
          case 8:
            return "9999 99999";
          case 7:
            return "999 99999";
          case 6:
            return "99 99999";
          default:
            return "";
        }
      }
    }
    return "";
  };

  if (phone && !mask) mask = "999 999 9999";

  if (currency) number = true;
  if (date) type = "date";
  switch (size) {
    case "xs":
      if (float) {
        labelClass = "floating-form-label-xs ";
        inputClass = "form-control form-control-xs " + className + " ";
      } else {
        labelClass = "form-label-xs ";
        inputClass = "form-control form-control-xs " + className + " ";
      }

      break;
    case "sm":
      if (float) {
        labelClass = "floating-form-label-sm ";
        inputClass = "form-control form-control-sm " + className + " ";
      } else {
        labelClass = "form-label-sm ";
        inputClass = "form-control form-control-sm " + className + " ";
      }

      break;
    case "lg":
      if (float) {
        labelClass = "floating-form-label-lg ";
        inputClass = "form-control form-control-lg " + className + " ";
      } else {
        labelClass = "form-label-lg ";
        inputClass = "form-control form-control-lg " + className + " ";
      }
      break;
    default:
      if (float) {
        labelClass = "floating-form-label ";
        inputClass = "form-control form-control " + className + " ";
      } else {
        labelClass = "form-label ";
        inputClass = "form-control " + className + " ";
      }
  }

  if (check) {
    labelClass = "form-check-label ml-2 ";
    inputClass = "form-check-input";
  } else if (textarea) labelClass += " floating-form-label-textarea ";
  else labelClass += "floating-form-label-input" + (size === "sm" ? "-sm" : size === "lg" ? "-lg" : "");

  if (float) placeHText = type === "combo" || type === "asyncCombo" || type === "asyncCreatable" ? "" : label;
  else placeHText = placeholder ? placeholder : "";

  const getContenido = () => {
    return (
      <div className={group}>
        {label && !float && !check && !toggle && (
          <label htmlFor={name} className={labelClass}>
            {label}
          </label>
        )}
        {toggle && <Toggle {...rest} id={name} name={name} label={label}></Toggle>}
        {number && (
          <NumberFormat
            {...rest}
            id={name}
            name={name}
            placeholder={placeHText}
            className={`${inputClass}` + (error ? " is-invalid" : "")}
            prefix={currency ? (moneda ? moneda + " " : "$ ") : ""}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            onFocus={handleFocus}
          />
        )}
        {integer && (
          <NumberFormat
            {...rest}
            id={name}
            name={name}
            placeholder={placeHText}
            className={`${inputClass}` + (error ? " is-invalid" : "")}
            prefix={currency ? (moneda ? moneda + " " : "$ ") : ""}
            thousandSeparator={thousandSeparator ? thousandSeparator : ""}
            decimalSeparator={","}
            decimalScale={0}
            fixedDecimalScale={true}
            onFocus={handleFocus}
          />
        )}
        {textarea && <textarea {...rest} id={name} name={name} placeholder={placeHText} className={`${inputClass}` + (error ? " is-invalid" : "")} />}{" "}
        {mask && !phone && !dni && <InputMask {...rest} id={name} name={name} placeholder={placeHText} className={`${inputClass}` + (error ? " is-invalid" : "")} mask={mask} maskPlaceholder={maskPlaceHolder} />}
        {phone && (
          <InputMask
            {...rest}
            id={name}
            name={name}
            ref={inputRef}
            placeholder={placeHText}
            className={`${inputClass}` + (error ? " is-invalid" : "")}
            mask={handleMaskPhone()}
            maskPlaceholder={maskPlaceHolder}
            formatchars={{ 9: "[0-9]", "?": "[1-9]" }}
          />
        )}
        {check && <input {...rest} id={name} type="checkbox" name={name} className={`${inputClass}` + (error ? " is-invalid" : "")} />}
        {dni && <InputMask {...rest} id={name} name={name} ref={inputRef} placeholder={placeHText} className={`${inputClass}` + (error ? " is-invalid" : "")} mask={handleMaskDni()} maskPlaceholder={maskPlaceHolder} />}
        {combo && float && (
          <Select
            {...rest}
            id={name}
            name={name}
            placeholder=""
            options={options}
            label={label}
            onKeyDown={onKeyDown}
            size={size}
            components={{ Control }}
            styles={error ? selectInvalidStyle : selectValidStyle}
            className={`${className}` + (error ? " is-invalid" : "")}
          />
        )}
        {combo && !float && (
          <Select
            {...rest}
            id={name}
            name={name}
            placeholder={placeHText}
            options={options}
            label={label}
            onKeyDown={onKeyDown}
            size={size}
            styles={error ? selectInvalidStyle : selectValidStyle}
            className={`${className}` + (error ? " is-invalid" : "")}
          />
        )}
        {asyncCombo && float && (
          <AsyncSelect
            {...rest}
            id={name}
            name={name}
            placeholder=""
            loadOptions={loadOptions}
            label={label}
            onKeyDown={onKeyDown}
            size={size}
            components={{ Control }}
            noOptionsMessage={() => "Sin resultados"}
            loadingMessage={() => "Buscando..."}
            styles={error ? selectInvalidStyle : selectValidStyle}
            className={`${className}` + (error ? " is-invalid" : "")}
          />
        )}
        {asyncCombo && !float && (
          <AsyncSelect
            {...rest}
            id={name}
            name={name}
            placeholder={placeHText}
            loadOptions={loadOptions}
            label={label}
            onKeyDown={onKeyDown}
            size={size}
            noOptionsMessage={() => "Sin resultados"}
            loadingMessage={() => "Buscando..."}
            styles={error ? selectInvalidStyle : selectValidStyle}
            className={`${className}` + (error ? " is-invalid" : "")}
          />
        )}
        {asyncCreatable && float && (
          <AsyncCreatableSelect
            {...rest}
            id={name}
            name={name}
            placeholder=""
            loadOptions={loadOptions}
            label={label}
            onKeyDown={onKeyDown}
            size={size}
            components={{ Control }}
            noOptionsMessage={() => "Sin resultados"}
            loadingMessage={() => "Buscando..."}
            formatCreateLabel={(input) => (formatCreateLabel ? formatCreateLabel : "Agregar ") + input + "..."}
            styles={error ? selectInvalidStyle : selectValidStyle}
            className={`${className}` + (error ? " is-invalid" : "")}
          />
        )}
        {asyncCreatable && !float && (
          <AsyncCreatableSelect
            {...rest}
            id={name}
            name={name}
            placeholder={placeHText}
            loadOptions={loadOptions}
            label={label}
            onKeyDown={onKeyDown}
            size={size}
            noOptionsMessage={() => "Sin resultados"}
            loadingMessage={() => "Buscando..."}
            formatCreateLabel={(input) => (formatCreateLabel ? formatCreateLabel : "Agregar ") + input + "..."}
            styles={error ? selectInvalidStyle : selectValidStyle}
            className={`${className}` + (error ? " is-invalid" : "")}
          />
        )}
        {!number && !integer && !check && !toggle && !textarea && !dni && !combo && !asyncCombo && !asyncCreatable && !phone && !mask && (
          <input {...rest} id={name} type={type} name={name} placeholder={placeHText} className={`${inputClass}` + (error ? " is-invalid" : "")} />
        )}
        {((label && float && !combo && !asyncCombo && !asyncCreatable) || check) && (
          <label htmlFor={name} className={`${labelClass}` + (error ? " invalidLabel" : "")}>
            {label}
          </label>
        )}{" "}
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
      </div>
    );
  };

  const getRow = (data) => {
    return (
      <React.Fragment>
        {newRow && <div className="row">{data}</div>}
        {!newRow && data}
      </React.Fragment>
    );
  };
  const getColumns = (data) => {
    return (
      <React.Fragment>
        {columns && <div className={`col-${columns}`}>{data}</div>}
        {!columns && data}
      </React.Fragment>
    );
  };

  return <React.Fragment>{getRow(getColumns(getContenido()))}</React.Fragment>;
};

export default Input;

import "./notFound.css";

import React from "react";

export default function NotFound(props) {
  const goBack = () => {
    props.history.goBack();
  };

  return (
    <React.Fragment>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3>Oia! No se encontró esa pagina</h3>
            <h1>
              <span>4</span>
              <span>0</span>
              <span>4</span>
            </h1>
          </div>
          <h2>lo sentimos, pero esa página no existe</h2>
          <div className="col text-center">
            <button className="mb-5 btn btn-secondary" onClick={goBack}>
              Volver
            </button>
          </div>{" "}
        </div>
      </div>
    </React.Fragment>
  );
}

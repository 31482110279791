import React from "react";

const FloatingLabel = ({
  name,
  label,
  error,
  focus,
  size,
  className,
  ...rest
}) => {
  var labelClass;
  var inputClass;
  switch (size) {
    case "sm":
      labelClass = "floating-form-label-sm";
      inputClass = "form-control form-control-sm " + className;

      break;
    case "lg":
      labelClass = "floating-form-label-lg";
      inputClass = "form-control form-control-lg " + className;
      break;
    default:
      labelClass = "floating-form-label";
      inputClass = "form-control " + className;
  }
  if (focus)
    return (
      <div className="form-label-group">
        <input
          {...rest}
          id={name}
          name={name}
          ref={focus.ref}
          placeholder={label}
          className={`${inputClass}` + (error ? " is-invalid" : "")}
        />
        {label && (
          <label htmlFor={name} className={labelClass}>
            {label}
          </label>
        )}
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
      </div>
    );
  else
    return (
      <div className="form-label-group">
        <input
          {...rest}
          id={name}
          name={name}
          placeholder={label}
          className={`${inputClass}` + (error ? " is-invalid" : "")}
        />
        {label && (
          <label htmlFor={name} className={labelClass}>
            {label}
          </label>
        )}
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
      </div>
    );
};

export default FloatingLabel;

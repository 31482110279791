import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const ReportTableRow = (data) => {
  let keyNo = 1;
  const rows = data.rowData.map((row, indexRow) => {
    const columns = data.columns.map((column, index) => {
      let itemStyle = { ...data.itemStyles[index], width: column.width };
      return (
        <Text key={`rpthr${indexRow}${index}`} style={itemStyle}>
          {row[index]}
        </Text>
      );
    });
    return (
      <View key={`rptrow${indexRow}`} style={data.rowStyles}>
        {columns}
      </View>
    );
  });

  //     (

  //     <View style={data.rowStyles} key={(keyNo++).toString()}>

  //         })

  //       <Text style={styles.description}>{item.desc}</Text>
  //       <Text style={styles.qty}>{item.qty}</Text>
  //       <Text style={styles.rate}>{item.rate}</Text>
  //       <Text style={styles.amount}>{(item.qty * item.rate).toFixed(2)}</Text>
  //     </View>
  //   ));
  return <Fragment>{rows}</Fragment>;
};

export default ReportTableRow;

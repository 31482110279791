import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;

  let fromPage = Math.max(1, currentPage - 3);
  let toPage = Math.min(fromPage + 6, pagesCount);
  if (toPage < fromPage + 6) fromPage = Math.max(1, toPage - 6);
  const pages = _.range(fromPage, toPage + 1);

  if (pages.length === 0) return null;

  return (
    <div className="dataTables_paginate paging_simple_numbers float-right" id="datatable_paginate">
      <ul className="pagination text-secondary">
        <li className={`paginate_button page-item previous ${currentPage === 1 ? "disabled" : "clickable"}`} id="datatable_previous">
          <a onClick={() => onPageChange(currentPage - 1)} aria-controls="datatable" data-dt-idx={0} tabIndex={0} className="page-link">
            Anterior
          </a>
        </li>

        {pages.map((page) => (
          <li key={page} className={"paginate_button page-item " + (page === currentPage ? "active" : "clickable")}>
            <a onClick={() => onPageChange(page)} className="page-link">
              {page}
            </a>
          </li>
        ))}

        <li className={`paginate_button page-item next ${currentPage === toPage ? "disabled" : "clickable"}`} id="datatable_previous">
          <a onClick={() => onPageChange(currentPage + 1)} aria-controls="datatable" data-dt-idx={0} tabIndex={0} className="page-link">
            Siguiente
          </a>
        </li>
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;

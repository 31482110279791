import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import _ from "lodash";
// Alerts, Modals & Toasts
import { toastConfirm, toastError } from "../utils/toasts";
import ContentHeader from "../common/contentHeader";
import CategoriasTable from "./categoriasTable";
import Records from "../common/records";
import SearchBox from "../common/searchBox";
import { paginate } from "../utils/paginate";
import Pagination from "../common/pagination";
import { cleanString } from "../utils/cleanString";
import {
  deleteCategoria,
  getCategorias,
} from "../../services/categoriasService";
import { getConfig } from "../../config/config.js";
const config = getConfig();
const recordsPerPage = config.recordsPerPage;

class Categorias extends Component {
  title = "Categorias y Rubros";
  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Categorias",
    },
  ];
  state = {
    pageSize: recordsPerPage,
    records: [],
    currentPage: 1,
    sortColumn: {
      path: (item) => item.descripcion.toLowerCase(),
      order: "asc",
    },
    searchQuery: "",
  };

  async componentDidMount() {
    const { onLoading, onDoneLoading } = this.props;
    onLoading();
    const records = await getCategorias();
    onDoneLoading();
    if (records === "NETWORK ERROR")
      return this.props.history.replace("/noconnection");
    this.setState({ records });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ currentPage: 1, sortColumn });
  };

  handleDelete = async (id) => {
    toastConfirm("¿ Eliminar registro ?", () => this.doDelete(id));
  };

  doDelete = async (id) => {
    const originalRecords = this.state.records;
    const records = this.state.records.filter((m) => m._id !== id);
    this.setState({ records });
    try {
      await deleteCategoria(id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toastError("Ese registro ya fue eliminado");
      this.setState({ records: originalRecords });
    }
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
  };

  handleEdit = (id) => {
    this.props.history.push({
      pathname: "/categorias/" + id,
      state: { action: "E" },
    });
  };

  handleView = (id) => {
    this.props.history.push({
      pathname: "/categorias/" + id,
      state: { action: "C" },
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      records: allRecords,
      sortColumn,
      searchQuery,
    } = this.state;
    let filtered = [];
    if (searchQuery.trim().length > 0) {
      filtered = allRecords.filter((m) =>
        cleanString(m.descripcion).includes(cleanString(searchQuery))
      );
    } else {
      filtered = allRecords;
    }
    const ordered = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const records = paginate(ordered, currentPage, pageSize);
    return { totalCount: filtered.length, records };
  };

  render() {
    // const { length: count } = this.state.records;
    const {
      action,
      pageSize,
      currentPage,
      sortColumn,
      //   genres,
      searchQuery,
    } = this.state;

    // if (count === 0) return <h5 className="m-2 ">No hay datos para mostrar</h5>;
    const { totalCount, records } = this.getPagedData();
    if (currentPage > Math.ceil(totalCount / pageSize) && totalCount > 0)
      this.setState({ currentPage: currentPage - 1 });
    return (
      <React.Fragment>
        <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} />

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* <div className="card-header">
                    <h3 className="card-title">Datos Personales</h3>
                  </div> */}
                  {/* /.card-header */}
                  <div className="card-body h-75">
                    <div
                      id="datatable_wrapper"
                      className="dataTables_wrapper dt-bootstrap4"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="dt-buttons btn-group flex-wrap">
                            {" "}
                            <Link to="/categorias/nueva">
                              <button
                                className="btn btn-secondary buttons-copy buttons-html5"
                                tabIndex={0}
                                aria-controls="datatable"
                                type="button"
                              >
                                <span>Nuevo</span>
                              </button>{" "}
                            </Link>
                          </div>
                        </div>
                        <SearchBox
                          value={searchQuery}
                          onChange={this.handleSearch}
                        />
                      </div>

                      <CategoriasTable
                        action={action}
                        records={records}
                        onDelete={(id) => this.handleDelete(id)}
                        onEdit={(id) => this.handleEdit(id)}
                        onView={(id) => this.handleView(id)}
                        onSort={(sortColumn) => this.handleSort(sortColumn)}
                        sortColumn={sortColumn}
                      />

                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <Records
                            itemsCount={totalCount}
                            pageSize={pageSize}
                            currentPage={currentPage}
                          />
                        </div>

                        <div className="col-sm-12 col-md-7">
                          <Pagination
                            onPageChange={(page) => this.handlePageChange(page)}
                            itemsCount={totalCount}
                            pageSize={pageSize}
                            currentPage={currentPage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Categorias);

import React, { Component } from "react";
import Table from "../common/table";

class EmpresasTable extends Component {
  columns = [
    {
      path: "razonSocial",
      label: "Razón Social",
      width: "110%",
      // content: (record) => (
      //   <Link to={`/clientes/${record._id}`}>{record.apellido}</Link>
      // ),
    },
    // { path: "nombre", label: "Nombre", width: "20%" },
    // { path: "cuit", label: "CUIT", width: "70%" },
    // { path: "dailyRentalRate", label: "Rate" },
    // {
    //   key: "like",
    //   content: (movie) => (
    //     <Like onClick={() => this.props.onLike(movie)} liked={movie.liked} />
    //   ),
    // },
    {
      key: "edit",
      content: (record) => (
        <i
          className="fa fa-edit clickable"
          aria-hidden="true"
          onClick={() => this.props.onEdit(record._id)}
        ></i>
      ),
      isicon: true,
      dataClass: "text-olive-disabled",
    },
    {
      key: "delete",
      content: (record) => (
        <i
          className="fas fa-trash-alt clickable"
          aria-hidden="true"
          onClick={() => this.props.onDelete(record._id)}
        ></i>
      ),
      isicon: true,
      dataClass: "text-danger-disabled",
    },
  ];

  render() {
    const { records, sortColumn, onSort } = this.props;
    return (
      <Table
        data={records}
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default EmpresasTable;

import React, { useState, useEffect } from "react";
import RoundButton from "./roundButton";
import Input from "./input";

const ChildArray = ({ data, format, label, onChange, editable, blankItem, onIsEditing }) => {
  const [array, setArray] = useState([]);
  const [currentItem, setCurrentItem] = useState(blankItem);
  const [status, setStatus] = useState("");
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    setArray(data);
  }, []);

  const editItem = (index) => {
    const items = { ...array };
    const editing = items[index];
    format.forEach((item) => {
      if (item.type === "asyncCombo") {
        editing[item.column].value = editing[item.column]._id;
        editing[item.column].label = editing[item.column].apellido + " " + editing[item.column].nombre;
        editing.getChild = item.getChild;
        editing.name = item.column;
      }
    });
    setCurrentItem(editing);
    setStatus("E");
    onIsEditing(true);
    setEditIndex(index);
  };

  const deleteItem = (index) => {
    const currentArray = [...array];
    currentArray.splice(index, 1);
    setArray(currentArray);
    onChange(currentArray);
  };

  const saveChanges = async () => {
    let current = { ...currentItem };
    const currentArray = [...array];
    Object.keys(current).map(function (key, index) {
      if ((key.toLowerCase().includes("dni") || key.toLowerCase().includes("documento")) && !key.toLowerCase().includes("tipo")) {
        const valor = current[key];
        const stripped = valor.replaceAll(".", "");
        if (stripped.length === 8) {
          const nuevoValor = stripped.substring(0, 2) + "." + stripped.substring(2, 5) + "." + stripped.substring(5, 8);
          current[key] = nuevoValor;
        }
        if (stripped.length === 7) {
          const nuevoValor = stripped.substring(0, 1) + "." + stripped.substring(1, 4) + "." + stripped.substring(4, 7);
          current[key] = nuevoValor;
        }
      }
      if (typeof current[key] === "object" && current[key].value) current[key] = current[key].value;
    });
    if (current.getChild) {
      const item = await current.getChild(current[current.name]);
      current[current.name] = item;
    }
    if (editIndex < 0) currentArray.push(current);
    else currentArray[editIndex] = current;
    setArray(currentArray);
    onChange(currentArray);
    onIsEditing(false);
    cancelChanges();
  };

  const cancelChanges = () => {
    setCurrentItem(blankItem);
    setStatus("");
    setEditIndex(-1);
    onIsEditing(false);
  };

  const onItemChange = (event) => {
    const current = { ...currentItem };
    current[event.target.name] = event.currentTarget.value;
    setCurrentItem(current);
  };

  const onItemComboChange = (selectedOption, name) => {
    const current = { ...currentItem };
    const input = name.name;
    current[input] = selectedOption.value;
    setCurrentItem(current);
  };

  const onItemAsyncChange = async (selectedOption, name, getChild) => {
    const current = { ...currentItem };
    const input = name.name;
    current[input] = selectedOption;
    current.getChild = getChild;
    current.name = name.name;
    setCurrentItem(current);
  };

  return (
    <React.Fragment>
      <div className="col-12 mb-2 mt-2">
        <div className="group-box">
          <label className="group-box-label">{label}</label>
          <div className="col-12">
            <table className="table table-sm">
              <thead>
                <tr>
                  {format.map((element, index) => {
                    return (
                      <th key={`${label}h${index}`} style={{ width: `${element.width}` }}>
                        {element.label}
                      </th>
                    );
                  })}
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <React.Fragment key={`${label}r${index}`}>
                      {status === "E" && editIndex === index && (
                        <tr className="align-items-center">
                          {format.map((element, index) => {
                            if (element.type === "combo") {
                              return (
                                <td key={`${label}edt${index}`}>
                                  <Input combo name={element.column} value={{ label: currentItem[element.column], value: currentItem[element.column] }} options={element.options} isSearchable={false} onChange={onItemComboChange} />
                                </td>
                              );
                            }
                            if (element.type === "text") {
                              return (
                                <td key={`${label}edt${index}`}>
                                  <Input name={element.column} value={currentItem[element.column]} onChange={onItemChange} />
                                </td>
                              );
                            }
                            if (element.type === "number")
                              return (
                                <td key={`${label}edt${index}`}>
                                  <Input number name={element.column} value={currentItem[element.column]} onChange={onItemChange} />
                                </td>
                              );
                            if (element.type === "integer")
                              return (
                                <td key={`${label}edt${index}`}>
                                  <Input integer name={element.column} value={currentItem[element.column]} onChange={onItemChange} />
                                </td>
                              );
                            if (element.type === "asyncCombo") {
                              return (
                                <td key={`${label}edt${index}`}>
                                  <Input
                                    asyncCombo
                                    loadOptions={element.promiseOptions}
                                    name={element.column}
                                    value={currentItem[element.column]}
                                    onChange={(param1, param2) => {
                                      onItemAsyncChange(param1, param2, element.getChild);
                                    }}
                                  />
                                </td>
                              );
                            }

                            if (element.type === "phone")
                              return (
                                <td key={`${label}edt${index}`}>
                                  <Input phone name={element.column} value={currentItem[element.column]} onChange={onItemChange} />
                                </td>
                              );
                            if (element.type === "dni")
                              return (
                                <td key={`${label}edt${index}`}>
                                  <Input
                                    style={{
                                      float: "left",
                                      display: "inline-block",
                                    }}
                                    dni
                                    name={element.column}
                                    value={currentItem[element.column]}
                                    onChange={onItemChange}
                                  />
                                </td>
                              );
                          })}
                          <td style={{ width: "8%" }}>
                            <i className="fa fa-check mt-3 mr-2 clickable text-olive-disabled " onClick={saveChanges}></i>
                            <i className="fa fa-times mt-3 mr-2 clickable text-danger-disabled" onClick={cancelChanges}></i>
                          </td>
                        </tr>
                      )}
                      {(status != "E" || editIndex != index) && (
                        <tr>
                          {format.map((element, index) => {
                            if (!element.displayValue) return <td key={`${label}d${index}`}> {item[element.column] + " " + (element.suffix ? element.suffix : "")}</td>;
                            else {
                              return <td key={`${label}d${index}`}> {eval(element.displayValue) + " " + (element.suffix ? element.suffix : "")}</td>;
                            }
                          })}
                          {editable && (
                            <td style={{ width: "8%" }}>
                              <i className="fas fa-pencil-alt text-olive-disabled mr-2 clickable" onClick={() => editItem(index)}></i>
                              <i className="fas fa-trash-alt text-danger-disabled mr-2 clickable" onClick={() => deleteItem(index)}></i>
                            </td>
                          )}
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
                {status === "A" && editIndex < 0 && (
                  <tr className="align-items-center">
                    {format.map((element, index) => {
                      if (element.type === "combo") {
                        return (
                          <td key={`${label}adt${index}`}>
                            <Input combo name={element.column} value={{ label: currentItem[element.column], value: currentItem[element.column] }} options={element.options} isSearchable={false} onChange={onItemComboChange} />
                          </td>
                        );
                      }
                      if (element.type === "asyncCombo") {
                        return (
                          <td key={`${label}adt${index}`}>
                            <Input
                              asyncCombo
                              loadOptions={element.promiseOptions}
                              name={element.column}
                              value={currentItem[element.column]}
                              onChange={(param1, param2) => {
                                onItemAsyncChange(param1, param2, element.getChild);
                              }}
                            />
                          </td>
                        );
                      }
                      if (element.type === "text")
                        return (
                          <td key={`${label}adt${index}`}>
                            <Input name={element.column} value={currentItem[element.column]} onChange={onItemChange} />
                          </td>
                        );
                      if (element.type === "number")
                        return (
                          <td key={`${label}adt${index}`}>
                            <Input number name={element.column} value={currentItem[element.column]} onChange={onItemChange} />
                          </td>
                        );
                      if (element.type === "integer")
                        return (
                          <td key={`${label}adt${index}`}>
                            <Input integer name={element.column} value={currentItem[element.column]} onChange={onItemChange} />
                          </td>
                        );
                      if (element.type === "phone")
                        return (
                          <td key={`${label}adt${index}`}>
                            <Input phone name={element.column} value={currentItem[element.column]} onChange={onItemChange} />
                          </td>
                        );
                      if (element.type === "dni")
                        return (
                          <td key={`${label}adt${index}`}>
                            <Input
                              style={{
                                float: "left",
                                display: "inline-block",
                              }}
                              dni
                              name={element.column}
                              value={currentItem[element.column]}
                              onChange={onItemChange}
                            />
                          </td>
                        );
                      return <td key={`${label}adt${index}`}> New item</td>;
                    })}

                    <td style={{ width: "8%" }}>
                      <i className="fa fa-check mt-3 mr-2 clickable text-olive-disabled " onClick={saveChanges}></i>
                      <i className="fa fa-times mt-3 mr-2 clickable text-danger-disabled" onClick={cancelChanges}></i>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {status === "" && editable && (
              <div className="form-group">
                <RoundButton
                  onClick={() => {
                    setCurrentItem(blankItem);
                    setStatus("A");
                    setEditIndex(-1);
                    onIsEditing(true);
                  }}
                  iconClass="fa fa-plus"
                  className="btn-transparent"
                  tooltip="Agregar"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChildArray;

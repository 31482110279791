import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import _ from "lodash";
// Alerts, Modals & Toasts
import { toastConfirm, toastError } from "../utils/toasts";

import ContentHeader from "../common/contentHeader";

import Records from "../common/records";
import Avatar from "react-avatar";

import SearchBox from "../common/searchBox";
import { paginate } from "../utils/paginate";
import Pagination from "../common/pagination";
import { cleanString } from "../utils/cleanString";

import { deleteProyecto, getProyectos } from "../../services/proyectosService";
import { getConfig } from "../../config/config.js";

import jwtDecode from "jwt-decode";
import { getUser } from "../../services/userService";
import { getPermisos } from "../../services/permisosService";

const config = getConfig();
const recordsPerPage = config.recordsPerPage;

class Proyectos extends Component {
  title = "Proyectos";
  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Proyectos",
    },
  ];
  state = {
    pageSize: recordsPerPage,
    records: [],
    currentPage: 1,
    sortColumn: { path: "nombre", order: "asc" },
    searchQuery: "",
  };

  async UNSAFE_componentWillMount() {
    const jwt = localStorage.getItem("token");
    const decoded = jwt ? jwtDecode(jwt) : null;
    const user = await getUser(decoded.user._id);
    const permisos = await getPermisos(user.profile._id);
    if (!permisos) return this.props.history.replace("/notallowed");

    var found = permisos.find((element) => element.menu.link === "/proyectos");
    if (!found && !user.profile.isAdmin && !user.isSuperAdmin) return this.props.history.replace("/notallowed");
    else {
      const canDo = {
        canAdd: user.profile.isAdmin || user.isSuperAdmin ? true : found.canAdd,
        canView: user.profile.isAdmin || user.isSuperAdmin ? true : found.canView,
        canModify: user.profile.isAdmin || user.isSuperAdmin ? true : found.canModify,
        canDelete: user.profile.isAdmin || user.isSuperAdmin ? true : found.canDelete,
        canList: user.profile.isAdmin || user.isSuperAdmin ? true : found.canList,
        canExport: user.profile.isAdmin || user.isSuperAdmin ? true : found.canExport,
        special1: user.profile.isAdmin || user.isSuperAdmin ? true : found.special1,
        special2: user.profile.isAdmin || user.isSuperAdmin ? true : found.special2,
        special3: user.profile.isAdmin || user.isSuperAdmin ? true : found.special3,
        special4: user.profile.isAdmin || user.isSuperAdmin ? true : found.special4,
        special5: user.profile.isAdmin || user.isSuperAdmin ? true : found.special5,
        special6: user.profile.isAdmin || user.isSuperAdmin ? true : found.special6,
        special7: user.profile.isAdmin || user.isSuperAdmin ? true : found.special7,
        special8: user.profile.isAdmin || user.isSuperAdmin ? true : found.special8,
        special9: user.profile.isAdmin || user.isSuperAdmin ? true : found.special9,
        special10: user.profile.isAdmin || user.isSuperAdmin ? true : found.special10,
      };
      this.setState({ permisos: canDo });
    }
  }

  async componentDidMount() {
    const { onLoading, onDoneLoading } = this.props;
    onLoading();
    const records = await getProyectos();
    onDoneLoading();
    if (records === "NETWORK ERROR") return this.props.history.replace("/noconnection");
    this.setState({ records });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    this.setState({ currentPage: 1, sortColumn });
  };

  handleDelete = async (id) => {
    toastConfirm("¿ Eliminar registro ?", () => this.doDelete(id));
  };

  doDelete = async (id) => {
    const originalRecords = this.state.records;
    const records = this.state.records.filter((m) => m._id !== id);
    this.setState({ records });
    try {
      await deleteProyecto(id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) toastError("Ese registro ya fue eliminado");
      this.setState({ records: originalRecords });
    }
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleView = (id) => {
    this.props.history.push({
      pathname: "/proyectos/" + id,
      state: { action: "C" },
    });
  };

  handleEdit = (id) => {
    this.props.history.push({ pathname: "/proyectos/" + id, state: { action: "E" } });
  };

  getPagedData = () => {
    const { pageSize, currentPage, records: allRecords, sortColumn, selectedFilter, searchQuery } = this.state;
    let filtered = [];
    if (searchQuery.trim().length > 0) {
      filtered = allRecords.filter((m) => cleanString(m.nombre).includes(cleanString(searchQuery)) || cleanString(m.ubicacion).includes(cleanString(searchQuery)));
    } else {
      //   filtered =
      //     selectedFilter && selectedFilter._id
      //       ? allRecords.filter((m) => m.genre._id === selectedFilter._id)
      //       : allRecords;
      filtered = allRecords;
    }
    const ordered = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const records = paginate(ordered, currentPage, pageSize);
    return { totalCount: filtered.length, records };
  };

  render() {
    const { length: count } = this.state.records;

    const {
      pageSize,
      currentPage,
      sortColumn,
      //   genres,
      searchQuery,
      permisos,
    } = this.state;

    // if (count === 0) return <h5 className="m-2 ">No hay datos para mostrar</h5>;
    const { totalCount, records } = this.getPagedData();

    if (currentPage > Math.ceil(totalCount / pageSize) && totalCount > 0) this.setState({ currentPage: currentPage - 1 });
    return (
      <React.Fragment>
        <ContentHeader title={this.title} breadcrumbs={this.breadcrumbs} />

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* <div className="card-header">
                    <h3 className="card-title">Datos Personales</h3>
                  </div> */}
                  {/* /.card-header */}
                  <div className="card-body h-75">
                    <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          {permisos && permisos.canAdd && (
                            <div className="dt-buttons btn-group flex-wrap">
                              {" "}
                              <Link to="/proyectos/nuevo">
                                <button className="btn btn-secondary buttons-copy buttons-html5" tabIndex={0} aria-controls="datatable" type="button">
                                  <span>Nuevo</span>
                                </button>{" "}
                              </Link>
                            </div>
                          )}
                        </div>
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                      </div>

                      {/* <ProyectosTable
                        records={records}
                        onDelete={(id) => this.handleDelete(id)}
                        onEdit={(id) => this.handleEdit(id)}
                        onSort={(sortColumn) => this.handleSort(sortColumn)}
                        sortColumn={sortColumn}
                      /> */}

                      {/* <table className="table table-striped projects"> */}

                      <table className="table table-striped table-hover dataTable dtr-inline projects">
                        <thead>
                          <tr>
                            <th style={{ width: "40%" }}>Nombre</th>
                            <th style={{ width: "40%" }}>Socios</th>
                            <th style={{ width: "20%" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.map((record, index) => {
                            return (
                              <tr key={record._id}>
                                <td>
                                  <a style={{ cursor: "pointer" }} onClick={() => this.handleView(record._id)}>
                                    {" "}
                                    {record.nombre}
                                  </a>
                                  <br />
                                  <small>{record.ubicacion}</small>
                                </td>
                                <td style={{ cursor: "pointer" }} onClick={() => this.handleView(record._id)}>
                                  <ul className="list-inline">
                                    {record.socios.map((socio, index) => {
                                      return (
                                        <li key={socio._id} className="list-inline-item">
                                          {socio.persona && <Avatar className="table-avatar" name={socio.persona.nombre + " " + socio.persona.apellido} size="40" round="100%" src={socio.persona.avatar} />}

                                          {/* <img alt="Avatar" className="table-avatar" src={socio.persona && socio.persona.avatar ? socio.persona.avatar : `/dist/img/user.jpg`} /> */}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </td>

                                <td className="project-actions text-right">
                                  <i className="fas fa-pencil-alt mr-2 clickable text-olive-disabled" onClick={() => this.handleEdit(record._id)}></i>
                                  <i className="fas fa-trash-alt mr-2 clickable text-danger-disabled" onClick={() => this.handleDelete(record._id)}></i>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          <Records itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
                        </div>

                        <div className="col-sm-12 col-md-7">
                          <Pagination onPageChange={(page) => this.handlePageChange(page)} itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </React.Fragment>
    );
  }
}

export default withRouter(Proyectos);

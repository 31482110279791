import React from "react";
import InputMask from "react-input-mask";

const MaskedInput = ({
  name,
  label,
  error,
  focus,
  mask,
  maskPlaceHolder,
  className = "form-control form-control-sm",
  ...rest
}) => {
  if (focus)
    return (
      <div className="form-label-group">
        <InputMask
          {...rest}
          id={name}
          name={name}
          ref={focus.ref}
          placeholder={label}
          className={`${className}` + (error ? " is-invalid" : "")}
          mask={mask}
          maskPlaceholder={maskPlaceHolder}
        />
        {label && <label htmlFor={name}>{label}</label>}
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
      </div>
    );
  else
    return (
      <div className="form-label-group">
        <InputMask
          {...rest}
          id={name}
          name={name}
          placeholder={label}
          className={`${className}` + (error ? " is-invalid" : "")}
          mask={mask}
          maskPlaceholder={maskPlaceHolder}
        />
        {label && <label htmlFor={name}>{label}</label>}
        {error && (
          <div>
            <small className="text-red w-100">{error}</small>
          </div>
        )}
      </div>
    );
};

export default MaskedInput;

import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/condicionesiva";

export async function getCondicionesIva() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR"))
      return error.message.toUpperCase();
    else return [];
  }
}

export async function getCondicionIva(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR"))
      return error.message.toUpperCase();
    else return null;
  }
}

export async function saveCondicionIva(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else return await http.post(serviceEndpoint, data);
}

export async function deleteCondicionIva(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

export async function getCondicionesIvaSelect() {
  let condiciones = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((condicion) => {
      condiciones.push({ value: condicion._id, label: condicion.descripcion });
    });
    return condiciones;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR"))
      return error.message.toUpperCase();
    else return [];
  }
}

import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { login } from "../../services/authService";
import { Link } from "react-router-dom";

import "./loginForm.css";

class LoginForm extends Form {
  state = {
    badLogin: false,
    data: { username: "", password: "" },
    errors: {},
    loginError: false,
  };
  schema = {
    username: Joi.string().email().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };
  render() {
    const { data, errors, loginError } = this.state;
    return (
      <div id="login">
        <div id="login-intro">
          <div className="logo scale-up-center">
            <img src="/dist/img/logo.svg" />
          </div>
          <div className="title slide-in-blurred-left">Khios WEB v2.00</div>
          <div className="description slide-in-blurred-left">Sistema integral de gestión inmobiliaria. Ingrese sus credenciales de acceso para ingresar al sistema.</div>
        </div>
        <div id="login-form-wrapper" className="slide-left">
          <div id="login-form">
            <div className="logo">
              <img src="/dist/img/logo.svg" />
            </div>
            <div className="title">ACCESO AL SISTEMA</div>
            <form className="controls" onSubmit={this.handleSubmit}>
              <div className="form-label-group">
                <input
                  type="email"
                  id="username"
                  name="username"
                  placeholder="e-mail"
                  value={data.username}
                  error={errors["username"]}
                  className={errors["username"] ? "login-input form-control invalid" : "login-input form-control"}
                  onChange={this.handleChange}
                  autoFocus
                />
                <label className="login-label" htmlFor="username">
                  Correo electrónico
                </label>
              </div>
              <div className="form-label-group">
                <input
                  type="password"
                  id="password"
                  name="password"
                  className={errors["password  "] ? "login-input form-control invalid" : "login-input form-control"}
                  placeholder="Contraseña"
                  value={data.password}
                  error={errors["password"]}
                  onChange={this.handleChange}
                />
                <label className="login-label" htmlFor="password">
                  Contraseña
                </label>
              </div>
              <div className="remember-forgot-password">
                <div className="remember-me-container">
                  <input type="checkbox" id="rememberMe" name="rememberMe" className="remember-me" />
                  Recordarme
                </div>
                <Link to="/forgotpassword" className="forgot-password ">
                  Olvidó su contraseña?
                </Link>
              </div>

              {loginError && <div className="invalid-login">Usuario o contraseña incorrecta</div>}
              {!loginError && <div className="invalid-login">{"    "}</div>}

              <button id="btnLogin" disabled={this.validate()} className="btn btn-lg btn-secondary btn-block btn-login text-uppercase font-weight-bold mb-2">
                INGRESAR
              </button>
            </form>

            <div className="register">
              <span className="text">No tiene una cuenta?</span>
              <a className="clickable">Crear una cuenta</a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  doSubmit = async () => {
    const { username, password } = this.state.data;
    this.setState({ loginError: false });
    try {
      const response = await login(username, password);
      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        localStorage.removeItem("selectedModule");
        window.location = "/";
        //      this.props.history.push("/");
      } else this.setState({ loginError: true });
    } catch (exception) {
      // this.setState({ loginError: true });
    }
  };
}

export default LoginForm;

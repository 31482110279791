import React, { useLayoutEffect, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import moment from "moment";
import ContentHeader from "../common/contentHeader";
import Input from "../common/input.jsx";
import Cuenta from "../common/cuenta";
import Report from "../common/Report/Report";
import Records from "../common/records.jsx";
import Pagination from "../common/pagination.jsx";
import { PDFDownloadLink, usePDF } from "@react-pdf/renderer";
import { getUser } from "../../services/userService";
import { getRubros } from "../../services/categoriasService";
import { paginate } from "../utils/paginate";
import { getProyecto } from "../../services/proyectosService";
import { getPermisos } from "../../services/permisosService";
import { getCajaSetup, deleteCajaRecord, getCajas, saveCajaRecord, getNextAporte } from "../../services/cajasDomoService";
import { dateFormatter, formattedToFloat, numberWithCommas } from "../utils/utils";
import { toastConfirm, toastInfo } from "../utils/toasts";
import { getConfig } from "../../config/config.js";
import { isEmpty } from "lodash";

import "./cajasdomo.css";

export default function CajasDomo(props) {
  const title = "Cajas";

  const menuLink = "/cajasdomo";

  const breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Proyectos",
      link: "/proyectos",
    },
    {
      title: "Cajas",
    },
  ];

  let initialReportData = {
    title: "Título del Reporte",
    columns: [
      { header: "Fecha", width: "10%", styles: {} },
      { header: "Proyecto/Socio", width: "20%", styles: {} },
      { header: "Rubro", width: "15%", styles: {} },
      { header: "Detalle", width: "15%", styles: {} },
      { header: "Ingreso", width: "10%", styles: { flex: 1, textAlign: "center" } },
      { header: "Egreso", width: "10%", styles: { flex: 1, textAlign: "center" } },
      { header: "Saldo", width: "10%", styles: { flex: 1, textAlign: "center" } },
    ],
    items: [],
    itemStyles: [{}, {}, {}, {}, { flex: 1, textAlign: "right" }, { flex: 1, textAlign: "right" }, { flex: 1, textAlign: "right" }],
  };

  const customStyles = {
    groupHeading: (provided, state) => ({
      ...provided,
      fontWeight: "bold",
      color: "black",
      padding: 10,
    }),
  };

  let componentRef = "";
  const config = getConfig();

  const [desde, setDesde] = useState(null);
  const [permisos, setPermisos] = useState();
  const [permisosOriginal, setPermisosOriginal] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [status, setStatus] = useState("");
  const [newRecord, setNewRecord] = useState({});
  const [originalRecord, setOriginalRecord] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [cajas, setCajas] = useState([]);
  const [fullCajas, setFullCajas] = useState([]);
  const [cuentas, setCuentas] = useState([]);
  const [monedas, setMonedas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [rubros, setRubros] = useState([]);
  const [categoriasOriginal, setCategoriasOriginal] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [presupuestos, setPresupuestos] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [reportData, setReportData] = useState(initialReportData);
  const [selectedMoneda, setSelectedMoneda] = useState({});
  const [selectedCuenta, setSelectedCuenta] = useState({
    value: "",
    label: "Unificada",
    tipo: "U",
    documentado: false,
  });

  const pageSize = config.cajaRecordsPerPage;
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const pdfDocument = <Report data={reportData} />;
  const [pdfInstance, updatePdfInstance] = usePDF({ document: pdfDocument });

  useLayoutEffect(() => {
    const { onLoading, onDoneLoading } = props;
    onLoading();
    async function fetchData() {
      const jwt = localStorage.getItem("token");
      const decoded = jwt ? jwtDecode(jwt) : null;
      const user = await getUser(decoded.user._id);
      if (!user) this.props.history.replace("/noconnection");
      const permisos = await getPermisos(decoded.user._id);
      if (!permisos) return this.props.history.replace("/notallowed");
      var found = permisos.find((element) => element.menu.link === menuLink);
      setIsAdmin(user.profile.isAdmin);
      setIsSuperAdmin(user.isSuperAdmin);
      if (!found && !user.profile.isAdmin && !user.isSuperAdmin) return props.history.replace("/notallowed");
      else {
        const canDo = {
          canAdd: user.profile.isAdmin || user.isSuperAdmin ? true : found.canAdd,
          canModify: user.profile.isAdmin || user.isSuperAdmin ? true : found.canModify,
          canDelete: user.profile.isAdmin || user.isSuperAdmin ? true : found.canDelete,
          canList: user.profile.isAdmin || user.isSuperAdmin ? true : found.canList,
          canExport: user.profile.isAdmin || user.isSuperAdmin ? true : found.canExport,
          special1: user.profile.isAdmin || user.isSuperAdmin ? true : found.special1,
          special2: user.profile.isAdmin || user.isSuperAdmin ? true : found.special2,
          special3: user.profile.isAdmin || user.isSuperAdmin ? true : found.special3,
          special4: user.profile.isAdmin || user.isSuperAdmin ? true : found.special4,
          special5: user.profile.isAdmin || user.isSuperAdmin ? true : found.special5,
          special6: user.profile.isAdmin || user.isSuperAdmin ? true : found.special6,
          special7: user.profile.isAdmin || user.isSuperAdmin ? true : found.special7,
          special8: user.profile.isAdmin || user.isSuperAdmin ? true : found.special8,
          special9: user.profile.isAdmin || user.isSuperAdmin ? true : found.special9,
          special10: user.profile.isAdmin || user.isSuperAdmin ? true : found.special10,
        };
        setPermisos(canDo);
        setPermisosOriginal(canDo);
        const date = moment().startOf("month").format("YYYY-MM-DD");
        setDesde(date);
        try {
          const setup = await getCajaSetup();
          //   setCategorias(setup.categorias);
          setCategoriasOriginal(setup.categorias);
          setSelectedMoneda(setup.monedaReferencia);
          setMonedas(setup.monedas);

          setCuentas(setup.cuentas);
          setProyectos(setup.proyectos);
          setPersonas(setup.personas);
          let destinosAux = [];
          destinosAux.push({ label: "Proyectos", options: setup.proyectos });
          destinosAux.push({ label: "Socios", options: setup.personas });
          setDestinos(destinosAux);
          await fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, setup.monedaReferencia.value, date, currentPage);
        } catch (ex) {
          console.log("network error");
        }
      }
    }
    fetchData();
    onDoneLoading();
  }, []);

  useEffect(() => {
    resetRecord();
    if (permisos) {
      const permisosAux = { ...permisos };
      permisosAux.canModify = (selectedCuenta.tipo === "U" || selectedCuenta.tipo === "X" || selectedCuenta.documentado) && permisosOriginal.canModify;
      permisosAux.canAdd = (selectedCuenta.tipo === "U" || selectedCuenta.tipo === "X" || selectedCuenta.documentado) && permisosOriginal.canAdd;
      setPermisos(permisosAux);
    }
    let saldo = 0;
    let rd = { ...initialReportData };
    cajas.map((caja, index) => {
      let labelCategoria = "";
      let labelDestino = "";
      let found = "";
      saldo += caja.ingreso - caja.egreso;
      found = categorias.find((object) => object.value === caja.categoriaId);
      if (found) labelCategoria = found.label;
      found = "";
      let row = [dateFormatter(caja.fecha), labelDestino, caja.label, caja.detalle, numberWithCommas(caja.ingreso), numberWithCommas(caja.egreso), numberWithCommas(saldo)];
      // reportData.items.push(row);
      rd.items.push(row);
    });
    setReportData(rd);
  }, [cajas, status]);

  // useEffect(() => {
  //   updatePdfInstance();
  // }, [reportData]);

  const onDataLoading = () => {
    setDataLoading(true);
  };

  const onDoneDataLoading = () => {
    setDataLoading(false);
  };

  const resetRecord = () => {
    const newRecord = {
      _id: "",
      fecha: moment().format("YYYY-MM-DD"),
      destino: {},
      proyecto: {},
      persona: {},
      presupuesto: {},
      categoria: {},
      rubro: {},
      detalle: "",
      ingreso: 0,
      egreso: 0,
      documentado: false,
    };
    setNewRecord(newRecord);
    setCategorias(categoriasOriginal);
  };

  const fetchCuenta = async (tipo, idCaja, idMoneda, startingDate, pagina) => {
    onDataLoading();
    const cajas = await getCajas(tipo, idCaja, idMoneda, startingDate, pagina);
    const totalCount = cajas.length > 0 ? cajas[0].totalCount : 0;
    setCurrentPage(Math.ceil(totalCount / pageSize));
    setTotalCount(totalCount);
    setFullCajas(cajas);
    const records = paginate(cajas, currentPage, pageSize);
    setCajas(records);
    onDoneDataLoading();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const records = paginate(fullCajas, page, pageSize);
    setCajas(records);
  };

  const handleChangeCuenta = (selectedOption, name) => {
    setStatus("");
    setSelectedCuenta(selectedOption);
    fetchCuenta(selectedOption.tipo, selectedOption.value, selectedMoneda.value, desde, currentPage);
  };

  const handleChangeMoneda = (selectedOption, name) => {
    const { onLoading, onDoneLoading } = props;
    setStatus("");
    setSelectedMoneda(selectedOption);
    fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedOption.value, desde, currentPage);
  };

  const handleChangeFecha = (event) => {
    setNewRecord({ ...newRecord, fecha: event.target.value });
  };

  const handleChangeDesde = (event) => {
    setDesde(event.target.value);
  };

  const handleDateKeyPress = (event) => {
    if (event.charCode === 13) fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedMoneda.value, desde, currentPage);
  };

  const handleNewDesde = () => {
    fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedMoneda.value, desde, currentPage);
  };

  const handleEdit = async (found) => {
    setOriginalRecord(newRecord);
    if (newRecord.destino.tipo === "P") {
      const proyecto = await getProyecto(newRecord.destino.value);
      let presupuestos = [];
      if (proyecto.presupuestos) {
        let pre = proyecto.presupuestos.filter((m) => m.estado === "Aceptado");
        pre.forEach((presu) => {
          const insert = {
            value: presu._id,
            label: presu.descripcion,
            tipo: "P",
          };
          presupuestos.push(insert);
        });
      }
      let cats = [];
      cats.push({ label: "Presupuestos", options: presupuestos });
      cats.push({ label: "Categorías", options: categoriasOriginal.filter((item) => !item.afectaSocios) });
      setCategorias(cats);
    } else {
      setCategorias(categoriasOriginal.filter((item) => item.afectaSocios));
    }
  };

  const handleSave = async () => {
    const { onLoading } = props;
    setStatus("");
    const saveRecord = {
      _id: newRecord._id,
      fecha: newRecord.fecha,
      destino: newRecord.destino,
      proyecto: newRecord.destino.tipo === "S" && newRecord.proyecto ? newRecord.proyecto.value : newRecord.destino.tipo === "P" ? newRecord.destino.value : "",
      persona: newRecord.destino.tipo === "S" ? newRecord.destino.value : "",
      presupuesto: newRecord.categoria.tipo === "P" ? newRecord.categoria.value : "",
      categoria: newRecord.categoria.tipo === "C" ? newRecord.categoria.value : "",
      rubro: newRecord.rubro ? newRecord.rubro.label : "",
      moneda: selectedMoneda.value,
      detalle: newRecord.detalle,
      documentado: newRecord.documentado,
      ingreso: formattedToFloat(String(newRecord.ingreso)),
      egreso: formattedToFloat(String(newRecord.egreso)),
      pendiente: selectedCuenta.tipo === "X" ? true : false,
    };
    newRecord.proyecto =
      newRecord.destino.tipo === "S" && newRecord.proyecto ? { label: newRecord.proyecto.label, value: newRecord.proyecto.value } : newRecord.destino.tipo === "P" ? { label: newRecord.destino.label, value: newRecord.destino.value } : {};
    newRecord.persona = newRecord.destino.tipo === "S" ? { label: newRecord.destino.label, value: newRecord.destino.value } : {};
    newRecord.presupuesto = newRecord.categoria.tipo === "P" ? { label: newRecord.categoria.label, value: newRecord.categoria.value } : {};
    newRecord.categoria = newRecord.categoria.tipo === "C" ? { label: newRecord.categoria.label, value: newRecord.categoria.value, tipo: "C" } : {};
    newRecord.ingreso = formattedToFloat(String(newRecord.ingreso));
    newRecord.egreso = formattedToFloat(String(newRecord.egreso));
    if (!isEmpty(newRecord.rubro)) newRecord.rubro = newRecord.rubro.label;
    if (isEmpty(newRecord.persona)) delete newRecord.persona;
    if (isEmpty(newRecord.presupuesto)) delete newRecord.presupuesto;
    if (isEmpty(newRecord.categoria)) delete newRecord.categoria;
    let origCajas = [...cajas];
    let regCajas = [...cajas];
    if (!saveRecord._id) regCajas.push(newRecord);
    else {
      let index = regCajas.findIndex((item) => item._id === saveRecord._id);
      regCajas[index] = newRecord;
      index = fullCajas.findIndex((item) => item._id === saveRecord._id);
      fullCajas[index] = newRecord;
    }
    setCajas(regCajas);
    setFullCajas(fullCajas);
    try {
      let result = await saveCajaRecord(saveRecord);
      resetRecord();
      if (result.data.success && saveRecord.fecha !== originalRecord.fecha) fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedMoneda.value, desde, currentPage);
    } catch (error) {
      setCajas(origCajas);
    }
  };

  const handleCancel = () => {
    console.log("handlecancel");
    resetRecord();
    setStatus("");
  };

  const handleDelete = async (id) => {
    toastConfirm("¿ Eliminar registro ?", () => doDelete(id));
  };

  const doDelete = async (id) => {
    console.log("doDelete");
    let origCajas = [...cajas];
    let regCajas = [...cajas];
    const index = regCajas.findIndex((item) => item._id === id);
    regCajas.splice(index, 1);
    setCajas(regCajas);
    try {
      await deleteCajaRecord(id);
      if (regCajas.length === 0) fetchCuenta(selectedCuenta.tipo, selectedCuenta.value, selectedMoneda.value, desde, currentPage);
    } catch (error) {
      setCajas(origCajas);
    }
  };

  const handleChangeDestino = async (selectedOption) => {
    newRecord.destino = selectedOption;
    if (selectedOption.tipo === "P") {
      const proyecto = await getProyecto(selectedOption.value);
      let presupuestos = [];
      if (proyecto.presupuestos) {
        let pre = proyecto.presupuestos.filter((m) => m.estado === "Aceptado");
        pre.forEach((presu) => {
          const insert = {
            value: presu._id,
            label: presu.descripcion,
            tipo: "P",
          };
          presupuestos.push(insert);
        });
      }
      let cats = [];
      cats.push({ label: "Presupuestos", options: presupuestos });
      cats.push({ label: "Categorías", options: categoriasOriginal.filter((item) => !item.afectaSocios) });
      setCategorias(cats);
    } else {
      setCategorias(categoriasOriginal.filter((item) => item.afectaSocios));
    }
    newRecord.detalle = "";
    newRecord.proyecto = {};
    newRecord.categoria = {};
    delete newRecord.limitaIngreso;
    setNewRecord({ ...newRecord });
  };

  const handleChangeProyecto = async (selectedOption) => {
    newRecord.proyecto = selectedOption;
    if (newRecord.categoria.label.toUpperCase().includes("APORTE")) {
      let detalle = await getNextAporte(selectedOption.value, newRecord.destino.value, selectedMoneda.value, newRecord.categoria.value);
      if (detalle.proximo === 0) {
        toastInfo("No hay aportes pendientes en " + selectedMoneda.simbolo + " para ese proyecto");
      } else {
        newRecord.detalle = detalle.label;
        newRecord.ingreso = detalle.maximo;
        newRecord.limitaIngreso = detalle.maximo;
      }
    }
    setNewRecord({ ...newRecord });
  };

  const handleChangeCategoria = async (selectedOption) => {
    newRecord.detalle = "";
    newRecord.proyecto = {};
    newRecord.categoria = selectedOption;
    delete newRecord.limitaIngreso;
    if (selectedOption.tipo === "C") {
      const rubros = await getRubros(selectedOption.value);
      setRubros(rubros);
    }
    setNewRecord({ ...newRecord });
  };

  const handleChangeRubro = (selectedOption) => {
    newRecord.rubro = selectedOption;
    setNewRecord({ ...newRecord });
  };

  const handleRecordChange = (event) => {
    const record = { ...newRecord };
    record[event.currentTarget.name] = event.currentTarget.value;
    if (event.currentTarget.name === "ingreso") record.egreso = 0;
    if (event.currentTarget.name === "egreso") record.ingreso = 0;
    setNewRecord(record);
  };

  const handleToggleDocumentado = (event) => {
    console.log("handletoggledocumentado");
    setNewRecord({ ...newRecord, documentado: !newRecord.documentado });
  };

  return (
    <div>
      <ContentHeader title={title} breadcrumbs={breadcrumbs} />
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {dataLoading && (
            <div className="preloadercaja justify-content-center align-items-center">
              <i className="mt-5 text-secondary fas fa-2x fa-sync fa-spin"></i>
            </div>
          )}
          {!dataLoading && (
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div
                  className="card card-secondary"
                  style={{
                    backgroundColor: "#" + selectedMoneda.backcolor,
                  }}
                >
                  <div className="card-header">
                    <div className="row align-bottom">
                      <div className="col-7">
                        <Input combo="true" options={cuentas} value={selectedCuenta} isDisabled={status} onChange={handleChangeCuenta} />
                        {/* <Combo options={cuentas} value={selectedCuenta} className="form-select" isDisabled={status} onChange={this.handleChangeCuenta} /> */}
                      </div>
                      <div className="col-2">
                        <Input date name="desde" value={desde} onBlur={handleNewDesde} onChange={handleChangeDesde} onKeyPress={handleDateKeyPress} />
                        {/* <Combo options={monedas} value={selectedMoneda} className="form-select" isDisabled={status} onChange={this.handleChangeMoneda} /> */}
                      </div>
                      <div className="col-2">
                        <Input combo="true" options={monedas} value={selectedMoneda} isDisabled={status} onChange={handleChangeMoneda} />
                        {/* <Combo options={monedas} value={selectedMoneda} className="form-select" isDisabled={status} onChange={this.handleChangeMoneda} /> */}
                      </div>
                      <div className="col-1">
                        <button
                          type="button"
                          className="btn mt-1 "
                          style={{
                            backgroundColor: "#b10f10",
                            color: "white",
                          }}
                        >
                          <PDFDownloadLink document={pdfDocument} fileName="cuenta.pdf" style={{ color: "white" }}>
                            {({ blob, url, loading, error }) => updatePdfInstance && (loading ? "Generando..." : "PDF")}
                          </PDFDownloadLink>
                          <i className="fa fa-file-pdf pr-2 ml-2" aria-hidden="true"></i>
                        </button>
                        {/* <ReactToPrint
                            content={() => this.componentRef}
                            trigger={() => (
                              <button
                                type="button"
                                className="btn mt-1 "
                                style={{
                                  backgroundColor: "#b10f10",
                                  color: "white",
                                }}
                              >
                                <i className="fa fa-file-pdf pr-2" aria-hidden="true"></i>
                                Exportar
                              </button>
                            )}
                          /> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body"
                    ref={(response) => {
                      componentRef = response;
                    }}
                  >
                    <Cuenta
                      unificada={selectedCuenta.value ? false : true}
                      tipoCuenta={selectedCuenta.tipo}
                      canEdit={permisos.canModify}
                      cajas={cajas}
                      categorias={categorias}
                      rubros={rubros}
                      destinos={destinos}
                      proyectos={proyectos}
                      presupuestos={presupuestos}
                      personas={personas}
                      status={status}
                      isAdmin={isAdmin}
                      isSuperAdmin={isSuperAdmin}
                      onReset={resetRecord}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      newRecord={newRecord}
                      onDelete={handleDelete}
                      onChangeFecha={handleChangeFecha}
                      onChangeDestino={handleChangeDestino}
                      onChangeProyecto={handleChangeProyecto}
                      onChangeCategoria={handleChangeCategoria}
                      onChangeRubro={handleChangeRubro}
                      onRecordChange={handleRecordChange}
                      onToggleDocumentado={handleToggleDocumentado}
                    />
                    <div className="row ml-1">
                      {permisos.canAdd && (
                        <button type="button" className="btn btn-secondary" onClick={() => setStatus("A")}>
                          <i className="fa fa-plus pr-2" aria-hidden="true"></i>
                          Nuevo
                        </button>
                      )}
                    </div>
                    <div className="row mt-1 align-items-center">
                      <div className="col-sm-12 col-md-5">
                        <Records itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
                      </div>

                      <div className="col-sm-12 col-md-7" style={{ textAlign: "right" }}>
                        <Pagination onPageChange={(page) => handlePageChange(page)} itemsCount={totalCount} pageSize={pageSize} currentPage={currentPage} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.row */}
            </div>
          )}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}

import moment from "moment";
import "moment/locale/es";

import _, { indexOf } from "lodash";

export const estadosPresupuestos = [
  { value: "Pedido", label: "Pedido", class: "badge-info" },
  { value: "Presupuestado", label: "Presupuestado", class: "badge-warning" },
  { value: "Aceptado", label: "Aceptado", class: "badge-success" },
  { value: "Rechazado", label: "Rechazado", class: "badge-danger" },
];

export const tiposDocumentos = [
  { value: "DNI", label: "DNI" },
  { value: "LC", label: "LC" },
  { value: "LE", label: "LE" },
  { value: "PAS", label: "PAS" },
];

export const tiposCargos = [
  { value: "", label: "" },
  { value: "Presidente", label: "Presidente" },
  { value: "Socio Gerente", label: "Socio Gerente" },
  { value: "Director", label: "Director" },
  { value: "Gerente", label: "Gerente" },
  { value: "Representate Por Poder", label: "Representate Por Poder" },
];

export const ubicaciones = [
  { label: "", value: "" },
  { label: "Al Frente", value: "Al Frente" },
  { label: "Contrafrente", value: "Contrafrente" },
  { label: "Lateral", value: "Lateral" },
  { label: "Interno", value: "Interno" },
  { label: "En Galería", value: "En Galería" },
  { label: "A la calle", value: "A la calle" },
];

export const tiposTelefonos = [
  { value: "Celular", label: "Celular" },
  { value: "Fijo", label: "Fijo" },
];

export const ddns = [
  { localidad: "25 DE MAYO (PROV. BUENOS AIRES)", ddn: "2345" },
  { localidad: "28 DE NOVIEMBRE", ddn: "2902" },
  { localidad: "9 DE JULIO (PROV. BUENOS AIRES)", ddn: "2317" },
  { localidad: "ACEBAL", ddn: "3469" },
  { localidad: "ADELIA MARIA", ddn: "3585" },
  { localidad: "AGUILARES", ddn: "3865" },
  { localidad: "AIMOGASTA", ddn: "3827" },
  { localidad: "ALCARAZ", ddn: "3438" },
  { localidad: "ALCORTA", ddn: "3465" },
  { localidad: "ALEJANDRO KORN", ddn: "2225" },
  { localidad: "ALLEN", ddn: "2941" },
  { localidad: "ALMAFUERTE (PROV.CORDOBA)", ddn: "3571" },
  { localidad: "ALTA GRACIA", ddn: "3547" },
  { localidad: "AMAICHA DEL VALLE", ddn: "3892" },
  { localidad: "AMBA", ddn: "11" },
  { localidad: "AMERICA", ddn: "2337" },
  { localidad: "ANDALGALA", ddn: "3835" },
  { localidad: "ANILLACO", ddn: "3827" },
  { localidad: "AÑATUYA", ddn: "3844" },
  { localidad: "APOSTOLES", ddn: "3758" },
  { localidad: "ARGÜELLO", ddn: "3543" },
  { localidad: "ARRECIFES", ddn: "2478" },
  { localidad: "ARRIBEÑOS", ddn: "2353" },
  { localidad: "ARROYITO (PROV. CORDOBA)", ddn: "3576" },
  { localidad: "ARROYO SECO", ddn: "3402" },
  { localidad: "ARRUFO", ddn: "3491" },
  { localidad: "ASCENCION", ddn: "2353" },
  { localidad: "AYACUCHO", ddn: "2296" },
  { localidad: "AZUL", ddn: "2281" },
  { localidad: "BAHIA BLANCA", ddn: "291" },
  { localidad: "BALCARCE", ddn: "2266" },
  { localidad: "BALLESTEROS", ddn: "353" },
  { localidad: "BALNEARIA", ddn: "3563" },
  { localidad: "BANDERA", ddn: "3857" },
  { localidad: "BARADERO", ddn: "3329" },
  { localidad: "BARRANCAS (PROV. SANTA FE)", ddn: "3466" },
  { localidad: "BARREAL", ddn: "2648" },
  { localidad: "BARRIO LOS PESCADORES", ddn: "3722" },
  { localidad: "BELL VILLE", ddn: "3534" },
  { localidad: "BENITO JUAREZ", ddn: "2292" },
  { localidad: "BERNARDO DE IRIGOYEN", ddn: "3741" },
  { localidad: "BERNASCONI", ddn: "2925" },
  { localidad: "BOLIVAR", ddn: "2314" },
  { localidad: "BOUCHARD", ddn: "3387" },
  { localidad: "BOVRIL", ddn: "3438" },
  { localidad: "BRAGADO", ddn: "2342" },
  { localidad: "BUENA ESPERANZA", ddn: "2658" },
  { localidad: "BURRUYACU", ddn: "3894" },
  { localidad: "CAA CATI", ddn: "3781" },
  { localidad: "CAFAYATE", ddn: "3868" },
  { localidad: "CALETA OLIVIA", ddn: "297" },
  { localidad: "CALEUFU", ddn: "2335" },
  { localidad: "CALINGASTA", ddn: "2648" },
  { localidad: "CAMPANA", ddn: "3489" },
  { localidad: "CAMPO GALLO", ddn: "3846" },
  { localidad: "CANALS", ddn: "3463" },
  { localidad: "CANDELARIA (PROV. MISIONES)", ddn: "3752" },
  { localidad: "CANDELARIA (PROV. SAN LUIS)", ddn: "2651" },
  { localidad: "CAÑADA DE GOMEZ", ddn: "3471" },
  { localidad: "CAÑUELAS", ddn: "2226" },
  { localidad: "CAPILLA DEL SEÑOR", ddn: "2323" },
  { localidad: "CAPITAN SARMIENTO", ddn: "2478" },
  { localidad: "CARCARAÑA", ddn: "341" },
  { localidad: "CARHUE", ddn: "2936" },
  { localidad: "CARILO", ddn: "2254" },
  { localidad: "CARLOS CASARES", ddn: "2395" },
  { localidad: "CARLOS SPEGAZZINI", ddn: "2274" },
  { localidad: "CARLOS TEJEDOR", ddn: "2357" },
  { localidad: "CARMEN DE ARECO", ddn: "2273" },
  { localidad: "CASBAS", ddn: "2929" },
  { localidad: "CASILDA", ddn: "3464" },
  { localidad: "CASTELLI", ddn: "2245" },
  { localidad: "CATAMARCA", ddn: "3833" },
  { localidad: "CAUCETE", ddn: "264" },
  { localidad: "CERES", ddn: "3491" },
  { localidad: "CHACABUCO", ddn: "2352" },
  { localidad: "CHAJAN", ddn: "3582" },
  { localidad: "CHAJARI", ddn: "3456" },
  { localidad: "CHAMICAL", ddn: "3826" },
  { localidad: "CHARADAI", ddn: "3721" },
  { localidad: "CHARATA", ddn: "3731" },
  { localidad: "CHASCOMUS", ddn: "2241" },
  { localidad: "CHEPES", ddn: "3821" },
  { localidad: "CHILECITO", ddn: "3825" },
  { localidad: "CHIVILCOY", ddn: "2346" },
  { localidad: "CHOELE CHOEL", ddn: "2946" },
  { localidad: "CHOS MALAL", ddn: "2948" },
  { localidad: "CINCO SALTOS", ddn: "299" },
  { localidad: "CLAROMECO", ddn: "2982" },
  { localidad: "CLORINDA", ddn: "3718" },
  { localidad: "COLON (PROV. BUENOS AIRES)", ddn: "2473" },
  { localidad: "COLON (PROV. ENTRE RIOS)", ddn: "3447" },
  { localidad: "COLONIA BARON", ddn: "2333" },
  { localidad: "COLONIA BENITEZ", ddn: "3722" },
  { localidad: "COMANDANTE FONTANA", ddn: "3716" },
  { localidad: "COMANDANTE LUIS PIEDRA BUENA", ddn: "2962" },
  { localidad: "COMANDANTE N. OTAMENDI", ddn: "2291" },
  { localidad: "COMODORO RIVADAVIA", ddn: "297" },
  { localidad: "CONCEPCION (PROV.TUCUMAN)", ddn: "3865" },
  { localidad: "CONCEPCION DEL URUGUAY", ddn: "3442" },
  { localidad: "CONCORDIA", ddn: "345" },
  { localidad: "CORDOBA", ddn: "351" },
  { localidad: "CORONDA", ddn: "342" },
  { localidad: "CORONEL BRANDSEN", ddn: "2223" },
  { localidad: "CORONEL DORREGO", ddn: "2921" },
  { localidad: "CORONEL MOLDES (PROV. CORDOBA)", ddn: "3582" },
  { localidad: "CORONEL PRINGLES", ddn: "2922" },
  { localidad: "CORONEL SUAREZ", ddn: "2926" },
  { localidad: "CORONEL VIDAL", ddn: "2265" },
  { localidad: "CORRAL DE BUSTOS", ddn: "3468" },
  { localidad: "CORRIENTES", ddn: "3783" },
  { localidad: "COSQUIN", ddn: "3541" },
  { localidad: "CRUZ ALTA", ddn: "3467" },
  { localidad: "CRUZ DEL EJE", ddn: "3549" },
  { localidad: "CURUZU CUATIA", ddn: "3774" },
  { localidad: "CUTRAL CO", ddn: "299" },
  { localidad: "DAIREAUX", ddn: "2316" },
  { localidad: "DARREGUEIRA", ddn: "2924" },
  { localidad: "DEAN FUNES", ddn: "3521" },
  { localidad: "DESAGUADERO", ddn: "2626" },
  { localidad: "DOLORES (PROV. BUENOS AIRES)", ddn: "2245" },
  { localidad: "DUDIGNAC", ddn: "2317" },
  { localidad: "EDUARDO CASTEX", ddn: "2334" },
  { localidad: "EL CALAFATE", ddn: "2902" },
  { localidad: "EL SOMBRERO", ddn: "3783" },
  { localidad: "EL TREBOL", ddn: "3401" },
  { localidad: "ELDORADO", ddn: "3751" },
  { localidad: "EMBAJADOR MARTINI", ddn: "2335" },
  { localidad: "ESCOBAR", ddn: "3488" },
  { localidad: "ESPERANZA (PROV. SANTA FE)", ddn: "3496" },
  { localidad: "ESQUEL", ddn: "2945" },
  { localidad: "FEDERAL", ddn: "3454" },
  { localidad: "FIRMAT", ddn: "3465" },
  { localidad: "FORMOSA", ddn: "3717" },
  { localidad: "FORTIN OLAVARRIA", ddn: "2337" },
  { localidad: "FRIAS", ddn: "3854" },
  { localidad: "GALVEZ", ddn: "3404" },
  { localidad: "GARUPA", ddn: "3752" },
  { localidad: "GENERAL ACHA", ddn: "2952" },
  { localidad: "GENERAL ALVEAR (PROV. BUENOS AIRES)", ddn: "2344" },
  { localidad: "GENERAL ALVEAR (PROV. MENDOZA)", ddn: "2625" },
  { localidad: "GENERAL ARENALES", ddn: "2353" },
  { localidad: "GENERAL BELGRANO", ddn: "2243" },
  { localidad: "GENERAL CONESA (PROV. BUENOS AIRES)", ddn: "2245" },
  { localidad: "GENERAL CONESA (PROV. RIO NEGRO)", ddn: "2931" },
  { localidad: "GENERAL DEHEZA", ddn: "358" },
  { localidad: "GENERAL GUIDO", ddn: "2268" },
  { localidad: "GENERAL JOSE DE SAN MARTIN", ddn: "3725" },
  { localidad: "GENERAL LAMADRID", ddn: "2286" },
  { localidad: "GENERAL MADARIAGA", ddn: "2267" },
  { localidad: "GENERAL MOSCONI", ddn: "3711" },
  { localidad: "GENERAL PICO", ddn: "2302" },
  { localidad: "GENERAL PINTO", ddn: "2356" },
  { localidad: "GENERAL PIRAN", ddn: "2265" },
  { localidad: "GENERAL ROCA (PROV. RIO NEGRO)", ddn: "2941" },
  { localidad: "GENERAL RODRIGUEZ", ddn: "237" },
  { localidad: "GENERAL VILLEGAS", ddn: "3388" },
  { localidad: "GLEW", ddn: "2224" },
  { localidad: "GOBERNADOR CRESPO", ddn: "3498" },
  { localidad: "GONZALEZ CATAN", ddn: "2202" },
  { localidad: "GOYA", ddn: "3777" },
  { localidad: "GUALEGUAY", ddn: "3444" },
  { localidad: "GUALEGUAYCHU", ddn: "3446" },
  { localidad: "GUAMINI", ddn: "2929" },
  { localidad: "GUERNICA", ddn: "2224" },
  { localidad: "HUANGUELEN SUR", ddn: "2933" },
  { localidad: "HUINCA RENANCO", ddn: "2336" },
  { localidad: "HUMAHUACA", ddn: "3887" },
  { localidad: "IBARRETA", ddn: "3716" },
  { localidad: "INGENIERO GUILLERMO N. JUAREZ", ddn: "3711" },
  { localidad: "INGENIERO JACOBACCI", ddn: "2940" },
  { localidad: "INGENIERO LUIGGI", ddn: "2335" },
  { localidad: "INRIVILLE", ddn: "3467" },
  { localidad: "ITUZAINGO", ddn: "3786" },
  { localidad: "JACHAL", ddn: "2647" },
  { localidad: "JESUS MARIA", ddn: "3525" },
  { localidad: "JOAQUIN V. GONZALEZ", ddn: "3877" },
  { localidad: "JOSE C. PAZ", ddn: "2320" },
  { localidad: "JUAN B. ALBERDI", ddn: "2354" },
  { localidad: "JUAN MARIA GUTIERREZ", ddn: "2229" },
  { localidad: "JUAN N. FERNANDEZ", ddn: "2264" },
  { localidad: "JUNIN (PROV. BUENOS AIRES)", ddn: "2362" },
  { localidad: "JUNIN DE LOS ANDES", ddn: "2972" },
  { localidad: "LA CARLOTA", ddn: "3584" },
  { localidad: "LA DORMIDA", ddn: "2623" },
  { localidad: "LA DULCE", ddn: "2264" },
  { localidad: "LA FALDA", ddn: "3548" },
  { localidad: "LA PAZ (PROV. ENTRE RIOS)", ddn: "3437" },
  { localidad: "LA PAZ (PROV. MENDOZA)", ddn: "2626" },
  { localidad: "LA PLATA", ddn: "221" },
  { localidad: "LA PUERTA (PROV. CORDOBA)", ddn: "3575" },
  { localidad: "LA QUIACA", ddn: "3885" },
  { localidad: "LA RIOJA", ddn: "3822" },
  { localidad: "LA TOMA", ddn: "2655" },
  { localidad: "LABOULAYE", ddn: "3385" },
  { localidad: "LAGUNA BRAVA", ddn: "3783" },
  { localidad: "LAMADRID", ddn: "3891" },
  { localidad: "LAPRIDA", ddn: "2285" },
  { localidad: "LAS ARMAS", ddn: "2268" },
  { localidad: "LAS FLORES (PROV. BUENOS AIRES)", ddn: "2244" },
  { localidad: "LAS LAJITAS", ddn: "3877" },
  { localidad: "LAS LOMITAS", ddn: "3715" },
  { localidad: "LAS TOSCAS (PROV. SANTA FE)", ddn: "3482" },
  { localidad: "LAS VARILLAS", ddn: "3533" },
  { localidad: "LEANDRO N. ALEM (PROV. MISIONES)", ddn: "3754" },
  { localidad: "LEONES", ddn: "3472" },
  { localidad: "LEZAMA", ddn: "2242" },
  { localidad: "LIBERTAD", ddn: "220" },
  { localidad: "LIBERTADOR GENERAL SAN MARTIN", ddn: "3886" },
  { localidad: "LINCOLN", ddn: "2355" },
  { localidad: "LLAMBI CAMPBELL", ddn: "3497" },
  { localidad: "LOBERIA", ddn: "2261" },
  { localidad: "LOBOS", ddn: "2227" },
  { localidad: "LONCOPUE", ddn: "2948" },
  { localidad: "LOPEZ CAMELO", ddn: "3327" },
  { localidad: "LOS CARDALES", ddn: "2322" },
  { localidad: "LOS MOLINOS (PROV. CORDOBA)", ddn: "3547" },
  { localidad: "LOS TOLDOS (PROV. BUENOS AIRES)", ddn: "2358" },
  { localidad: "LUJAN (PROV. BUENOS AIRES)", ddn: "2323" },
  { localidad: "LUJAN DE CUYO", ddn: "261" },
  { localidad: "MACACHIN", ddn: "2953" },
  { localidad: "MACHAGAI", ddn: "3734" },
  { localidad: "MACIEL", ddn: "3476" },
  { localidad: "MAGDALENA", ddn: "2221" },
  { localidad: "MAIPU (PROV. BUENOS AIRES)", ddn: "2268" },
  { localidad: "MAIPU (PROV. MENDOZA)", ddn: "261" },
  { localidad: "MALARGÜE", ddn: "2627" },
  { localidad: "MAR DE AJO", ddn: "2257" },
  { localidad: "MAR DEL PLATA", ddn: "223" },
  { localidad: "MARCOS JUAREZ", ddn: "3472" },
  { localidad: "MARCOS PAZ", ddn: "220" },
  { localidad: "MARGARITA BELEN", ddn: "3722" },
  { localidad: "MEDANOS (PROV. BUENOS AIRES)", ddn: "2927" },
  { localidad: "MENDOZA", ddn: "261" },
  { localidad: "MERCEDES (PROV. BUENOS AIRES)", ddn: "2324" },
  { localidad: "MERCEDES (PROV. CORRIENTES)", ddn: "3773" },
  { localidad: "MERCEDES (PROV. SAN LUIS)", ddn: "2657" },
  { localidad: "MERLO (PROV. BUENOS AIRES)", ddn: "220" },
  { localidad: "MERLO (PROV. SAN LUIS)", ddn: "2656" },
  { localidad: "METAN", ddn: "3876" },
  { localidad: "MIRAMAR (PROV. BUENOS AIRES)", ddn: "2291" },
  { localidad: "MOISES VILLE", ddn: "3409" },
  { localidad: "MOJON DE FIERRO", ddn: "3717" },
  { localidad: "MONTE", ddn: "2271" },
  { localidad: "MONTE CASEROS", ddn: "3775" },
  { localidad: "MONTE HERMOSO", ddn: "2921" },
  { localidad: "MONTE QUEMADO", ddn: "3841" },
  { localidad: "MONTEAGUDO", ddn: "3891" },
  { localidad: "MONTEROS", ddn: "3863" },
  { localidad: "MORENO", ddn: "237" },
  { localidad: "MORTEROS", ddn: "3562" },
  { localidad: "NASCHEL", ddn: "2656" },
  { localidad: "NAVARRO", ddn: "2272" },
  { localidad: "NECOCHEA", ddn: "2262" },
  { localidad: "NEUQUEN", ddn: "299" },
  { localidad: "NOGOYA", ddn: "3435" },
  { localidad: "NONOGASTA", ddn: "3825" },
  { localidad: "NORBERTO DE LA RIESTRA", ddn: "2343" },
  { localidad: "NUEVA ESPERANZA", ddn: "3861" },
  { localidad: "O' BRIEN", ddn: "2342" },
  { localidad: "OBERA", ddn: "3755" },
  { localidad: "OJO DE AGUA (PROV. SANTIAGO DEL ESTERO)", ddn: "3856" },
  { localidad: "OLAVARRIA", ddn: "2284" },
  { localidad: "OLIVA", ddn: "3532" },
  { localidad: "ONCATIVO", ddn: "3572" },
  { localidad: "ORAN", ddn: "3878" },
  { localidad: "ORENSE", ddn: "2982" },
  { localidad: "PARANA", ddn: "343" },
  { localidad: "PASO DE LOS LIBRES", ddn: "3772" },
  { localidad: "PEDRO LURO", ddn: "2928" },
  { localidad: "PEHUAJO", ddn: "2396" },
  { localidad: "PERGAMINO", ddn: "2477" },
  { localidad: "PERITO MORENO", ddn: "2963" },
  { localidad: "PIGÜE", ddn: "2923" },
  { localidad: "PILA", ddn: "2242" },
  { localidad: "PILAR (PROV. BUENOS AIRES)", ddn: "2322" },
  { localidad: "PINAMAR", ddn: "2254" },
  { localidad: "PLAYA CHAPADMALAL", ddn: "223" },
  { localidad: "POSADAS", ddn: "3752" },
  { localidad: "PRESIDENCIA DE LA PLAZA", ddn: "3734" },
  { localidad: "PRESIDENCIA ROQUE SAENZ PEÑA", ddn: "3732" },
  { localidad: "PUERTO BASTIANI", ddn: "3722" },
  { localidad: "PUERTO DESEADO", ddn: "297" },
  { localidad: "PUERTO IGUAZU", ddn: "3757" },
  { localidad: "PUERTO MADRYN", ddn: "2965" },
  { localidad: "PUERTO RICO", ddn: "3743" },
  { localidad: "PUERTO SANTA CRUZ", ddn: "2962" },
  { localidad: "PUERTO TIROL", ddn: "3722" },
  { localidad: "PUNTA ALTA", ddn: "2932" },
  { localidad: "QUEMU QUEMU", ddn: "2333" },
  { localidad: "QUIMILI", ddn: "3843" },
  { localidad: "QUINES", ddn: "2651" },
  { localidad: "QUIROGA", ddn: "2317" },
  { localidad: "RAFAEL OBLIGADO", ddn: "2475" },
  { localidad: "RAFAELA", ddn: "3492" },
  { localidad: "RAMALLO", ddn: "3407" },
  { localidad: "RANCHILLOS", ddn: "3869" },
  { localidad: "RAPELLI", ddn: "3861" },
  { localidad: "RAUCH", ddn: "2297" },
  { localidad: "RAWSON (PROV. CHUBUT)", ddn: "2965" },
  { localidad: "REALICO", ddn: "2331" },
  { localidad: "RECONQUISTA", ddn: "3482" },
  { localidad: "RECREO (PROV. CATAMARCA)", ddn: "3832" },
  { localidad: "RESISTENCIA", ddn: "3722" },
  { localidad: "RIACHUELO", ddn: "3783" },
  { localidad: "RIO CEBALLOS", ddn: "3543" },
  { localidad: "RIO COLORADO (PROV. RIO NEGRO)", ddn: "2931" },
  { localidad: "RIO CUARTO", ddn: "358" },
  { localidad: "RIO GALLEGOS", ddn: "2966" },
  { localidad: "RIO GRANDE (PROV. TIERRA DEL FUEGO)", ddn: "2964" },
  { localidad: "RIO MAYO", ddn: "2903" },
  { localidad: "RIO PRIMERO", ddn: "3574" },
  { localidad: "RIO SEGUNDO", ddn: "3572" },
  { localidad: "RIO TERCERO", ddn: "3571" },
  { localidad: "RIO TURBIO", ddn: "2902" },
  { localidad: "RIVERA", ddn: "2935" },
  { localidad: "ROJAS", ddn: "2475" },
  { localidad: "ROSARIO", ddn: "341" },
  { localidad: "ROSARIO DEL TALA", ddn: "3445" },
  { localidad: "RUFINO", ddn: "3382" },
  { localidad: "SALADAS", ddn: "3782" },
  { localidad: "SALADILLO (PROV. BUENOS AIRES)", ddn: "2344" },
  { localidad: "SALAZAR", ddn: "2393" },
  { localidad: "SALLIQUELO", ddn: "2394" },
  { localidad: "SALSACATE", ddn: "3542" },
  { localidad: "SALTA", ddn: "387" },
  { localidad: "SALTO", ddn: "2474" },
  { localidad: "SAMPACHO", ddn: "3582" },
  { localidad: "SAN AGUSTIN DEL VALLE FERTIL", ddn: "2646" },
  { localidad: "SAN ANDRES DE GILES", ddn: "2325" },
  { localidad: "SAN ANTONIO DE ARECO", ddn: "2326" },
  { localidad: "SAN ANTONIO OESTE", ddn: "2934" },
  { localidad: "SAN BERNARDO (PROV. BUENOS AIRES)", ddn: "2257" },
  { localidad: "SAN CARLOS CENTRO", ddn: "3404" },
  { localidad: "SAN CARLOS DE BARILOCHE", ddn: "294" },
  { localidad: "SAN CLEMENTE DEL TUYU", ddn: "2252" },
  { localidad: "SAN CRISTOBAL", ddn: "3408" },
  { localidad: "SAN FRANCISCO (PROV. CORDOBA)", ddn: "3564" },
  { localidad: "SAN FRANCISCO DEL CHAÑAR", ddn: "3522" },
  { localidad: "SAN JAVIER (PROV. MISIONES)", ddn: "3754" },
  { localidad: "SAN JAVIER (PROV. SANTA FE)", ddn: "3405" },
  { localidad: "SAN JORGE (PROV. SANTA FE)", ddn: "3406" },
  { localidad: "SAN JOSE DE FELICIANO", ddn: "3458" },
  { localidad: "SAN JUAN", ddn: "264" },
  { localidad: "SAN JULIAN", ddn: "2962" },
  { localidad: "SAN JUSTO (PROV. SANTA FE)", ddn: "3498" },
  { localidad: "SAN LORENZO (PROV. SANTA FE)", ddn: "3476" },
  { localidad: "SAN LUIS (PROV. SAN LUIS)", ddn: "2652" },
  { localidad: "SAN LUIS DEL PALMAR", ddn: "3783" },
  { localidad: "SAN MANUEL (PROV. BUENOS AIRES)", ddn: "2261" },
  { localidad: "SAN MARTIN (PROV. MENDOZA)", ddn: "2623" },
  { localidad: "SAN MARTIN (PROV. SAN JUAN)", ddn: "264" },
  { localidad: "SAN MARTIN DE LOS ANDES", ddn: "2972" },
  { localidad: "SAN MIGUEL DE TUCUMAN", ddn: "381" },
  { localidad: "SAN NICOLAS", ddn: "3461" },
  { localidad: "SAN PEDRO (PROV. BUENOS AIRES)", ddn: "3329" },
  { localidad: "SAN PEDRO (PROV. JUJUY)", ddn: "3884" },
  { localidad: "SAN RAFAEL (PROV. MENDOZA)", ddn: "2627" },
  { localidad: "SAN SALVADOR DE JUJUY", ddn: "388" },
  { localidad: "SAN VICENTE (PROV. BUENOS AIRES)", ddn: "2225" },
  { localidad: "SANTA ANA (PROV. CORRIENTES)", ddn: "3783" },
  { localidad: "SANTA CLARA DEL MAR", ddn: "223" },
  { localidad: "SANTA FE", ddn: "342" },
  { localidad: "SANTA MARIA (PROV. CATAMARCA)", ddn: "3838" },
  { localidad: "SANTA ROSA (PROV. LA PAMPA)", ddn: "2954" },
  { localidad: "SANTA ROSA DE CALAMUCHITA", ddn: "3546" },
  { localidad: "SANTA TERESA (PROV. SANTA FE)", ddn: "3460" },
  { localidad: "SANTA TERESITA", ddn: "2246" },
  { localidad: "SANTIAGO DEL ESTERO", ddn: "385" },
  { localidad: "SANTO TOME", ddn: "3756" },
  { localidad: "SIERRA GRANDE", ddn: "2934" },
  { localidad: "SUIPACHA", ddn: "2324" },
  { localidad: "SUMAMPA", ddn: "3856" },
  { localidad: "SUNCHALES", ddn: "3493" },
  { localidad: "SUNCHO CORRAL", ddn: "3855" },
  { localidad: "TAFI DEL VALLE", ddn: "3867" },
  { localidad: "TANDIL", ddn: "2293" },
  { localidad: "TAPALQUE", ddn: "2283" },
  { localidad: "TARTAGAL (PROV. SALTA)", ddn: "3875" },
  { localidad: "TERMAS DE RIO HONDO", ddn: "3858" },
  { localidad: "TILISARAO", ddn: "2656" },
  { localidad: "TINOGASTA", ddn: "3837" },
  { localidad: "TINTINA", ddn: "3846" },
  { localidad: "TORTUGAS", ddn: "3471" },
  { localidad: "TRANCAS", ddn: "3862" },
  { localidad: "TRANSITO", ddn: "3576" },
  { localidad: "TRELEW", ddn: "2965" },
  { localidad: "TRENQUE LAUQUEN", ddn: "2392" },
  { localidad: "TRES ARROYOS", ddn: "2983" },
  { localidad: "TRES LOMAS", ddn: "2394" },
  { localidad: "TUNUYAN", ddn: "2622" },
  { localidad: "UNQUILLO", ddn: "3543" },
  { localidad: "USHUAIA", ddn: "2901" },
  { localidad: "USPALLATA", ddn: "2624" },
  { localidad: "VEDIA", ddn: "2354" },
  { localidad: "VENADO TUERTO", ddn: "3462" },
  { localidad: "VERA", ddn: "3483" },
  { localidad: "VERONICA", ddn: "2221" },
  { localidad: "VICTORIA", ddn: "3436" },
  { localidad: "VICTORICA", ddn: "2338" },
  { localidad: "VICUÑA MACKENNA", ddn: "3583" },
  { localidad: "VIEDMA", ddn: "2920" },
  { localidad: "VILLA ANGELA", ddn: "3735" },
  { localidad: "VILLA ATUEL", ddn: "2625" },
  { localidad: "VILLA CARLOS PAZ", ddn: "3541" },
  { localidad: "VILLA CONSTITUCION", ddn: "3400" },
  { localidad: "VILLA DE MARIA DE RIO SECO", ddn: "3522" },
  { localidad: "VILLA DEL ROSARIO (PROV. CORDOBA)", ddn: "3573" },
  { localidad: "VILLA DEL TOTORAL", ddn: "3524" },
  { localidad: "VILLA DOLORES", ddn: "3544" },
  { localidad: "VILLA GESELL", ddn: "2255" },
  { localidad: "VILLA HUIDOBRO", ddn: "2336" },
  { localidad: "VILLA IRIS", ddn: "2925" },
  { localidad: "VILLA LA ANGOSTURA", ddn: "2944" },
  { localidad: "VILLA MARIA", ddn: "353" },
  { localidad: "VILLA REGINA", ddn: "2941" },
  { localidad: "VILLA SAN MARTIN", ddn: "3845" },
  { localidad: "VILLAGUAY", ddn: "3455" },
  { localidad: "VILLALONGA", ddn: "2928" },
  { localidad: "VIVORATA", ddn: "2265" },
  { localidad: "WINIFREDA", ddn: "2333" },
  { localidad: "ZAPALA", ddn: "2942" },
  { localidad: "ZARATE", ddn: "3487" },
];

export const formatter = (value) => {
  return value.toLocaleString("de-DE", { minimumFractionDigits: 2 });
};

export const dateFormatter = (value) => {
  if (value.substr(value.length - 1) === "Z") return moment(value).format("DD/MM/YYYY");
  else return moment(value).format("DD/MM/YYYY");
};

export const formattedToFloat = (value) => {
  if (value) {
    if (typeof value === "number") return value;
    if (value === "") return "0.00";
    const valor = value.split(".").join("");
    return parseFloat(valor.replace(",", "."));
  }
  return undefined;
};

export const numberWithCommas = (value) => {
  if (!value) return "0,00";
  if (typeof value === "number") {
    value = value.toFixed(2);
    value = value.toString();
  }
  if (value.indexOf(".") === -1) value = value + ".00";
  let valor = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  valor = valor.replace(",", "#");
  valor = valor.replace(".", ",");
  valor = valor.replace("#", ".");
  return valor;
};

export const formatCurrencyToFloat = (value) => {
  if (typeof value === "number") return value;
  if (value === "") return "0.00";
  let valor = value.substring(value.indexOf(" "));
  valor = valor.split(".").join("");
  return parseFloat(valor.replace(",", "."));
};

export const timeSince = (value) => {
  if (!value) return "Nunca";
  const date = moment(value);
  const today = moment();
  const dias = today.dayOfYear() - date.dayOfYear();
  if (dias === 0) {
    const duration = moment.duration(today.diff(date));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes());

    if (hours === 0) {
      if (minutes === 0) return "Ahora";
      else return minutes + (minutes === 1 ? " minuto" : " minutos");
    }
    return hours + (hours === 1 ? " hora" : " horas");

    // return "Hoy";
  }

  if (dias === 1) return "Ayer";
  if (dias === 2) return "Anteayer";
  if (dias > 2 && dias < 7) return titleCase(date.locale("es").format("dddd"));
  if (dias === 7) return "Una Semana";
  if (dias > 7 && dias < 14) return dias + " días";
  if (dias === 14) return "Dos Semana";
  if (dias > 14 && dias < 21) return dias + " días";
  if (dias === 21) return "Tres Semanas";
  if (dias > 21 && dias < 30) return dias + " días";
  if (dias === 30 || dias === 31) return "Un Mes";
  return "Más De Un Mes";
};

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const imageExists = (url) => {
  var xhr = new XMLHttpRequest();
  xhr.open("HEAD", url, false);
  xhr.send();
  if (xhr.status >= 400) {
    return false;
  } else {
    return true;
  }
};

import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const RoundButton = ({ onClick, iconClass, className, tooltip, placement }) => {
  const btnClass = "btn-circle " + className;
  if (!placement) placement = "top";
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip>{tooltip}</Tooltip>}
      delay={{ show: 250, hide: 400 }}
    >
      <button
        type="button"
        className={btnClass}
        aria-hidden="true"
        onClick={onClick}
      >
        <i className={iconClass}></i>
      </button>
    </OverlayTrigger>
  );
};

export default RoundButton;

import http from "./httpService";

import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/permisos";

export async function getPermisos(profileId) {
  try {
    const response = await http.get(serviceEndpoint, {
      params: { profileId: profileId },
    });
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

// export async function getPermiso(recordId) {
//   try {
//     const response = await http.get(serviceEndpoint + "/" + recordId);
//     return response.data.record;
//   } catch (error) {
//     if (error.message.toUpperCase().includes("NETWORK ERROR"))
//       return error.message.toUpperCase();
//     else return null;
//   }
// }

// export async function savePermiso(data) {
//   if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
//   else return await http.post(serviceEndpoint, data);
// }

// export async function deletePermiso(recordId) {
//   return await http.delete(serviceEndpoint + "/" + recordId);
// }

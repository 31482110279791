import React from "react";
import Joi from "joi-browser";
import Form from "../common/newForm";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer";
import { withRouter } from "react-router-dom";
import { getCategoria, saveCategoria } from "../../services/categoriasService";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import RoundButton from "../common/roundButton";
import ChildPagination from "../common/childPagination";
import Input from "../common/input";

class CategoriasForm extends Form {
  title = "Categorias";
  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Categorias",
      link: "/categorias",
    },
    {
      title: "Detalles",
    },
  ];

  validateForm = true;

  state = {
    data: { descripcion: "", rubros: [] },
    childIndex: 0,
    child: { descripcion: "" },
    childAction: "",
    childPage: 1,
    errors: {},
    showModal: false,
  };

  schema = {
    _id: Joi.string(),
    descripcion: Joi.string()
      .label("Descripcion")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            // case "string.min":
            //   return { message: "first msg" };
            // case "string.max":
            //   return { message: "second msg" };
            case "any.empty":
              return { message: "Debe ingresar un nombre" };
            default:
              return { message: error };
          }
        });
      }),
    rubros: Joi.array().items(),
  };

  getDataFromServer = async (recordId) => {
    return await getCategoria(recordId);
  };

  mapToViewModel = (categoria) => {
    return {
      _id: categoria._id,
      descripcion: categoria.descripcion,
      rubros: categoria.rubros,
    };
  };

  doSubmit = async () => {
    await saveCategoria(this.state.data);
    this.props.history.push("/categorias");
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = (status) => {
    const child = { ...this.state.data };
    if (status === "A") {
      child.descripcion = "";
    }
    this.setState({ show: true, child, childAction: status });
  };

  handleChildPageChange = (newPage) => {
    let childPage = newPage;
    if (newPage < 1) childPage = 1;
    if (newPage > this.totalPages) childPage = this.totoalPages;
    this.setState({ childPage });
  };

  handleSaveChild = () => {
    const { child, childAction } = this.state;
    let rubros = [...this.state.data.rubros];
    let data = { ...this.state.data };
    if (childAction === "A") {
      rubros.push(child);
    }
    if (childAction === "M") {
      rubros[this.state.childIndex].descripcion = child.descripcion;
    }
    const ordered = _.orderBy(rubros, (item) => item.descripcion.toLocaleUpperCase(), "asc");
    data.rubros = ordered;
    this.setState({
      data,
      childAction: "",
      child: { descripcion: "" },
      show: false,
    });
  };

  handleChildChange = ({ currentTarget: input }) => {
    let child = { ...this.state.child };
    child.descripcion = input.value;
    this.setState({ child });
  };

  handleChildEdit = (index) => {
    const { rubros } = this.state.data;

    this.setState({
      show: true,
      childAction: "M",
      child: { descripcion: rubros[index].descripcion },
      childIndex: index,
    });
  };

  handleChildDelete = (index) => {
    let rubros = [...this.state.data.rubros];
    rubros.splice(index, 1);
    let data = { ...this.state.data };
    data.rubros = rubros;
    this.setState({ data });
  };

  render() {
    const { child, childPage, action } = this.state;
    const { rubros } = this.state.data;

    // const totalPages = Math.ceil(rubros.length / this.childPageLength);
    // const minPage = Math.max(1, childPage - 2);
    // const maxPage = Math.min(totalPages, minPage + 4);

    const ordered = _.orderBy(rubros, (item) => item.descripcion.toLocaleUpperCase(), "asc");

    const childRecords = ordered.slice((childPage - 1) * this.childPageLength, childPage * this.childPageLength);
    // const pages = _.range(minPage, maxPage + 1);
    return (
      <React.Fragment>
        {this.renderContentHeader(this.title, this.breadcrumbs, this.goBack)}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              {/* left column */}
              <div className="col-12">
                <div className="card card-secondary mt-2">
                  <div className="card-header">
                    <h3 className="card-title">
                      <RoundButton onClick={() => this.goBack()} iconClass="fas fa-arrow-left" className="btn-transparent mr-2" tooltip="Volver" />
                      Detalles
                    </h3>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      <ReactTabHotkeyReplacer>
                        {this.renderInput("descripcion", "Descripción", "text", {
                          float: true,
                          columns: 8,
                          newRow: true,
                          autoFocus: true,
                        })}
                      </ReactTabHotkeyReplacer>
                      <div className="row ">
                        <div className="col-8">
                          {/* <label htmlFor="tableRubros" className="form-label">
                            Rubros
                          </label> */}
                          <table id="tableRubros" className="table table-sm table-hover centered-header">
                            <thead className="thead-light">
                              <tr>
                                <th width="90%">Rubros</th>
                                <th width="5%"></th>
                                <th width="5%">{action !== "C" && <RoundButton onClick={() => this.handleShow("A")} iconClass="fas fa-plus black" className="btn-transparent " tooltip="Nuevo Rubro" />}</th>
                              </tr>
                            </thead>

                            <tbody>
                              {childRecords.map((rubro, index) => {
                                return (
                                  <tr key={`rubro${index}`}>
                                    <td>{rubro.descripcion}</td>
                                    <td>{action !== "C" && <i className="fas fa-pencil-alt text-info mr-2 clickable" data-toggle="modal" data-target="#modal-default" onClick={() => this.handleChildEdit(index)}></i>}</td>
                                    <td>{action !== "C" && <i className="fas fa-trash-alt text-danger mr-2 clickable" onClick={() => this.handleChildDelete((childPage - 1) * this.childPageLength + index)}></i>}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <ChildPagination onPageChange={(page) => this.handleChildPageChange(page)} itemsCount={rubros.length} pageSize={this.childPageLength} currentPage={childPage} />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">{this.renderSubmit("Guardar")}</div>
                  </form>
                </div>
              </div>
              {/* /.row */}
            </div>
          </div>
          {/* /.container-fluid */}

          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header>
              <Modal.Title>
                <strong>Rubro</strong>
              </Modal.Title>
              <button type="button" className="close" onClick={this.handleClose}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Cerrar</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <ReactTabHotkeyReplacer>
                <Input name="descripcionRubro" label="Descripción" float columns="8" newRow autoFocus onChange={this.handleChildChange} value={child.descripcion} />
              </ReactTabHotkeyReplacer>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Cerrar
              </Button>
              <Button variant="secondary" onClick={this.handleSaveChild}>
                Guardar
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(CategoriasForm);

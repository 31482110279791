import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <strong>Copyright © 2024 {/* <a href="https://www.brainworks.com.ar">Brainworks Sistemas</a>. */}</strong>

        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.1.0
        </div>
      </footer>
    </div>
  );
}

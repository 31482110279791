import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const ChildPagination = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange,
}) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;

  let fromPage = Math.max(1, currentPage - 3);
  let toPage = Math.min(fromPage + 6, pagesCount);
  if (toPage < fromPage + 6) fromPage = Math.max(1, toPage - 6);
  const pages = _.range(fromPage, toPage + 1);

  if (pages.length === 0) return null;

  return (
    <div className="card-tools">
      {pagesCount > 1 && (
        <ul className="pagination pagination-sm m-0 float-right">
          <li
            className={`paginate_button page-item previous ${
              currentPage === 1 ? "disabled" : ""
            }`}
          >
            <a
              className="page-link"
              onClick={() => onPageChange(currentPage - 1)}
            >
              «
            </a>
          </li>
          {pages.map((page) => {
            return (
              <li
                className={
                  "page-item " + (page === currentPage ? "active" : "")
                }
              >
                <a className="page-link " onClick={() => onPageChange(page)}>
                  {page}
                </a>
              </li>
            );
          })}

          <li
            className={`paginate_button page-item next ${
              currentPage === toPage ? "disabled" : ""
            }`}
          >
            <a
              className="page-link"
              onClick={() => onPageChange(currentPage + 1)}
            >
              »
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

ChildPagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default ChildPagination;

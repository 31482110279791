import http from "./httpService";
import Records from "../components/common/records";

import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/profiles";

export async function getProfiles() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getProfile(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function saveProfile(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else return await http.post(serviceEndpoint, data);
}

export async function deleteProfile(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

export async function getProfilesSelect() {
  let profiles = [];
  const response = await http.get(serviceEndpoint);
  response.data.records.map((profile) => {
    profiles.push({ value: profile._id, label: profile.descripcion });
  });
  return profiles;
}

export const profilesPromiseOptions = (inputValue) =>
  http
    .get(serviceEndpoint, { params: { search: inputValue } })
    .then(function (response) {
      let options = response.data.records.map((record) => ({
        value: record._id,
        label: Records.descripcion,
      }));
      return options;
    })
    .catch(function (error) {
      // console.log(error);
    });

import React from "react";

const SearchBox = ({ searchQuery, onChange }) => {
  return (
    <div className="col-sm-12 col-md-6">
        <input

          id="searchBox"
          type="search"
          placeholder="Buscar..."
          onChange={(e) => onChange(e.currentTarget.value)}
          className="form-control form-control-sm"
          value={searchQuery}          
          aria-controls="datatable"
        />
  </div>
  );
};

export default SearchBox;

import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

const Table = ({ columns, data, sortColumn, onSort }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <table
          id="datatable"
          className="table table-bordered table-striped table-hover dataTable dtr-inline"
          role="grid"
          aria-describedby="datatable_info"
        >
          <TableHeader
            columns={columns}
            sortColumn={sortColumn}
            onSort={onSort}
          />
          <TableBody data={data} columns={columns} />
        </table>
      </div>
    </div>
  );
};

export default Table;

const config = require("./config.json");

export const getConfig = () => {
  let customConfig = config["development"];

  if (process.env.NODE_ENV === "production") {
    customConfig = config["production"];
  }

  return customConfig;
};

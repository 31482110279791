import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../services/userService";
import Input from "../common/input";

class ForgotPassword extends Component {
  state = {
    data: {
      email: "",
    },
    sent: false,
  };

  handleSubmit = async () => {
    await forgotPassword(this.state.data);

    this.setState({ sent: true });
  };

  handleMailChange = (e) => {
    const { currentTarget: input } = e;
    const inputMode = e.target.inputMode;
    const errors = { ...this.state.errors };
    let { data } = this.state;
    data.email = input.value;
    this.setState({ data });
  };

  goHome = () => {
    this.props.history.push({
      pathname: "/login",
    });
  };

  render() {
    const { email } = this.state.data;
    const { sent } = this.state;
    return (
      <div>
        <div style={{ backgroundColor: "#e9ecef" }} className="fullscreen flex-column justify-content-center align-items-center">
          <div className="login-box">
            <div className="login-logo">
              <b>Khios</b>Web
            </div>
            {/* /.login-logo */}
            <div className="card">
              <div className="card-body login-card-body">
                {!sent && (
                  <>
                    <p className="login-box-msg">Ingrese su dirección de correo y le enviaremos un enlace para recuperar su contraseña.</p>
                    <form onSubmit={this.handleSubmit}>
                      {/* <div className="input-group mb-3"> */}
                      <Input float name="email" label="Correo Electrónico" append="fas fa-envelope" value={email} onChange={this.handleMailChange} />

                      {/* <input type="email" className="form-control" placeholder="Email" />
                <div className="input-group-append">
                <div className="input-group-text">
                <span className="fas fa-envelope" />
                </div>
              </div> */}
                      {/* </div> */}
                      <div className="row">
                        <div className="col-12">
                          <button type="button" className="btn btn-primary btn-block" disabled={this.state.data.email === ""} onClick={this.handleSubmit}>
                            Solicitar Nueva Contraseña
                          </button>
                        </div>
                        {/* /.col */}
                      </div>
                    </form>
                  </>
                )}
                {sent && (
                  <>
                    <p className="login-box-msg mb-4">Mail enviado correctamente. En unos instantes recibirá un mail con instrucciones para reestablecer su contraseña.</p>
                    <div className="row">
                      <div className="col-12">
                        <button type="button" className="btn btn-primary btn-block" onClick={this.goHome}>
                          Aceptar
                        </button>
                      </div>
                      {/* /.col */}
                    </div>
                  </>
                )}
                {!sent && (
                  <>
                    <p className="mt-3 mb-1">
                      <Link to="/login">Ingreso al sistema</Link>
                    </p>
                    <p className="mb-0">
                      <Link to="/login">Crear una nueva cuenta</Link>
                    </p>
                  </>
                )}
              </div>
              {/* /.login-card-body */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPassword);

import React from "react";
import zxcvbn from "zxcvbn";

const PasswordStrengthMeter = ({ password }) => {
  const testValue = zxcvbn(password);
  const passwordLabel = ["Muy Débil", "Débil", "Normal", "Fuerte", "Muy Fuerte"];
  const colors = ["#cccccc", "#e77e89", "#fcac75", "#fed678", "#84bca3"];

  const changeMeter = () => {
    const percent = testValue.score * 25;
    return {
      width: `${percent}%`,
      backgroundColor: colors[testValue.score],
      height: "7px",
    };
  };

  return (
    <>
      <div className="progress" style={{ height: "7px" }}>
        <div className="progress-bar" style={changeMeter()}></div>
      </div>
      <p className="text-right" style={{ color: colors[testValue.score] }}>
        {passwordLabel[testValue.score]}
      </p>
    </>
  );
};

export default PasswordStrengthMeter;

import React, { Component } from "react";
import Input from "./input";
import CheckBox from "./checkBox";
import Joi from "joi-browser";
import BigCombo from "./bigCombo";
import Combo from "./combo";
import FloatingLabel from "./floatingLabel";
import MaskedInput from "./maskedInput";
import TextArea from "./textArea";
import moment from "moment";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    isSaving: false,
  };
  schema = {};

  validate = () => {
    let errors = {};
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    error.details.map((validationError) => {
      return (errors[validationError.path[0]] = validationError.message);
    });
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const object = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(object, schema);
    return error ? error.details[0].message : null;
  };

  validateSelect = (name, value) => {
    const object = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(object, schema);
    return error ? error.details[0].message : null;
  };

  beforeDateChange = (states) => {
    const value = states.nextState.value;
    var selection = states.nextState.selection;
    const dia = value.substring(0, 2);
    const mes = value.substring(3, 5);
    if (dia > 31) {
      selection = { start: 0, end: 2, length: 2 };
    }
    if (mes > 12) {
      selection = { start: 3, end: 5, length: 2 };
    }
    return { ...states.nextState, selection };
  };

  handleDateBlur = (event) => {
    const myMomentObject = moment(event.currentTarget.value, "DD/MM/YYYY");
    if (!myMomentObject.isValid()) {
      event.currentTarget.value = "";
      event.preventDefault();
      const target = event.currentTarget;
      setTimeout(function () {
        target.focus();
      }, 5);
    }
  };

  handleBlur = (a, b, c, d) => {
    // let errors = {};
    // const options = { abortEarly: false };
    // const { error } = Joi.validate(this.state.data, this.schema, options);
    // if (!error) return null;
    // error.details.map((validationError) => {
    //   errors[validationError.path[0]] = validationError.message;
    // });
    // this.setState({ errors });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleCheckChange = (event) => {
    const input = event.target.name;
    const value = event.target.checked;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateSelect(input, value);
    if (errorMessage) errors[input] = errorMessage;
    else delete errors[input];
    const data = { ...this.state.data };
    data[input] = value;
    this.setState({ data, errors });
  };

  handleSelectChange = (selectedOption, name) => {
    const input = name.name;
    const value = selectedOption.value;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateSelect(input, value);
    if (errorMessage) errors[input] = errorMessage;
    else delete errors[input];
    const data = { ...this.state.data };
    data[input] = value;
    this.setState({ [input]: selectedOption });
    this.setState({ data, errors });
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    const errors = this.validate();
    console.log(errors);
    this.setState({ errors: errors || {} });
    if (errors) {
      return;
    }
    this.doSubmit();
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      let index = Array.prototype.indexOf.call(form, event.target);
      if (form.elements[index + 1].type === "hidden") index++;
      form.elements[index + 1].focus();
    }
  };

  handleEnterButton = (event) => {
    event.preventDefault();
    this.handleSubmit();
  };

  renderButton(label) {
    // return (
    //   <button disabled={this.validate()} className="btn btn-primary">
    //     {label}
    //   </button>
    // );
    return (
      <button type="button" onClick={this.handleSubmit} className="btn btn-primary">
        {label}
      </button>
    );
  }

  renderInput = (name, label, type = "text", ref) => {
    const { errors, data } = this.state;
    if (ref) return <Input type={type} name={name} focus={this[ref]} label={label} value={data[name]} error={errors[name]} onKeyDown={this.handleEnter} onChange={this.handleChange} onBlur={this.handleBlur} />;
    else return <Input type={type} name={name} label={label} value={data[name]} error={errors[name]} onChange={this.handleChange} onKeyDown={this.handleEnter} onBlur={this.handleBlur} />;
  };

  renderTextArea = (name, label, type = "text", ref) => {
    const { errors, data } = this.state;
    if (ref) return <TextArea name={name} focus={this[ref]} label={label} value={data[name]} error={errors[name]} onKeyDown={this.handleEnter} onChange={this.handleChange} onBlur={this.handleBlur} />;
    else return <TextArea name={name} label={label} value={data[name]} error={errors[name]} onChange={this.handleChange} onKeyDown={this.handleEnter} onBlur={this.handleBlur} />;
  };

  renderDateInput = (name, label, type = "text", ref) => {
    const { errors, data } = this.state;
    if (ref)
      return (
        <MaskedInput
          type={type}
          name={name}
          focus={this[ref]}
          label={label}
          value={data[name]}
          error={errors[name]}
          mask="99/99/9999"
          maskPlaceholder="-"
          onKeyDown={this.handleEnter}
          onChange={this.handleChange}
          beforeMaskedStateChange={this.beforeDateChange}
          onBlur={this.handleDateBlur}
        />
      );
    else
      return (
        <MaskedInput
          type={type}
          name={name}
          label={label}
          value={data[name]}
          error={errors[name]}
          mask="99/99/9999"
          maskPlaceholder="-"
          onChange={this.handleChange}
          onKeyDown={this.handleEnter}
          beforeMaskedStateChange={this.beforeDateChange}
          onBlur={this.handleDateBlur}
        />
      );
  };
  renderMaskedInput = (name, label, mask, maskPlaceHolder, type = "text", ref) => {
    const { errors, data } = this.state;
    if (ref)
      return (
        <MaskedInput type={type} name={name} focus={this[ref]} label={label} value={data[name]} error={errors[name]} mask={mask} maskPlaceHolder={maskPlaceHolder} onKeyDown={this.handleEnter} onChange={this.handleChange} onBlur={this.handleBlur} />
      );
    else return <MaskedInput type={type} name={name} label={label} value={data[name]} error={errors[name]} mask={mask} maskPlaceHolder={maskPlaceHolder} onChange={this.handleChange} onKeyDown={this.handleEnter} onBlur={this.handleBlur} />;
  };

  renderFloating = (name, label, type = "text", ref) => {
    const { errors, data } = this.state;
    if (ref) return <FloatingLabel type={type} name={name} focus={this[ref]} label={label} value={data[name]} error={errors[name]} onKeyDown={this.handleEnter} onChange={this.handleChange} onBlur={this.handleBlur} />;
    else return <FloatingLabel type={type} name={name} label={label} value={data[name]} error={errors[name]} onChange={this.handleChange} onKeyDown={this.handleEnter} onBlur={this.handleBlur} />;
  };

  renderCheckBox = (name, label, ref) => {
    const { errors, data } = this.state;
    if (ref) return <CheckBox name={name} focus={this[ref]} label={label} checked={data[name]} error={errors[name]} onKeyDown={this.handleEnter} onChange={this.handleCheckChange} onBlur={this.handleBlur} />;
    else return <CheckBox name={name} label={label} checked={data[name]} error={errors[name]} onKeyDown={this.handleEnter} onChange={this.handleCheckChange} onBlur={this.handleBlur} />;
  };

  renderSelect = (name, label, options, ref) => {
    const { errors } = this.state;
    if (ref)
      return (
        <Combo
          id={name}
          name={name}
          label={label}
          options={options}
          focus={this[ref]}
          value={this.state[name]}
          error={errors[name]}
          placeholder="Seleccione..."
          noOptionsMessage={() => "Sin resultados"}
          onKeyDown={this.handleEnter}
          onChange={this.handleSelectChange}
          onBlur={this.handleBlur}
        />
      );
    else {
      return (
        <Combo
          id={name}
          name={name}
          label={label}
          options={options}
          value={this.state[name]}
          error={errors[name]}
          placeholder="Seleccione..."
          noOptionsMessage={() => "Sin resultados"}
          onKeyDown={this.handleEnter}
          onChange={this.handleSelectChange}
          onBlur={this.handleBlur}
        />
      );
    }
  };

  renderBigSelect = (name, label, options, ref) => {
    const { errors } = this.state;
    if (ref)
      return (
        <BigCombo
          id={name}
          name={name}
          label={label}
          options={options}
          focus={this[ref]}
          value={this.state[name]}
          error={errors[name]}
          placeholder="Seleccione..."
          noOptionsMessage={() => "Sin resultados"}
          onKeyDown={this.handleEnter}
          onChange={this.handleSelectChange}
          onBlur={this.handleBlur}
        />
      );
    else {
      return (
        <BigCombo
          id={name}
          name={name}
          label={label}
          options={options}
          value={this.state[name]}
          error={errors[name]}
          placeholder="Seleccione..."
          noOptionsMessage={() => "Sin resultados"}
          onKeyDown={this.handleEnter}
          onChange={this.handleSelectChange}
          onBlur={this.handleBlur}
        />
      );
    }
  };
}
export default Form;

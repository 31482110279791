import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/empresas";

export async function getEmpresas() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getEmpresa(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function saveEmpresa(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else {
    try {
      const response = await http.post(serviceEndpoint, data);
      return response.data.record._id;
    } catch (error) {
      if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
      else return null;
    }
  }
}

export async function uploadLogo(id, data, destino, oldFile) {
  const newData = new FormData();
  const file = data.get("file");
  newData.append("destino", destino);
  newData.append("oldfile", oldFile);
  newData.append("file", file);
  try {
    const response = await http.post(serviceEndpoint + "/uploadLogo/" + id, newData);
    return response.data.filename;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function deleteEmpresa(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

import React from "react";
import Form from "../common/newForm";
import { withRouter } from "react-router-dom";
import Joi from "joi-browser";
import ReactTabHotkeyReplacer from "react-tab-hotkey-replacer/dist/ReactTabHotkeyReplacer";
import DraggableGallery from "../draggableGallery/draggableGallery";
import FileUploadContainer from "../common/fileUploader";
import ImageEditor from "../imageEditor/imageEditor";
import PillList from "../pillList/pillList";
import RoundButton from "../common/roundButton";
import { getAmbientesSelect } from "../../services/ambientesService";
import { getServiciosSelect } from "../../services/serviciosService";
import { getInmueble, saveInmueble, localidadCompleta } from "../../services/inmueblesService";
import { localidadesPromiseOptions } from "../../services/localidadesService";
import { estadosPromiseOptions } from "../../services/estadosService";
import { uploadImages, updateImages } from "../../services/filesService";
import { getConfig } from "../../config/config.js";
import { tiposPromiseOptions } from "../../services/tiposService";
import { ubicaciones } from "../utils/utils";

// Gneral Focus Hook
// const utilizeFocus = () => {
//   const ref = React.createRef();
//   const setFocus = () => {
//     ref.current && ref.current.focus();
//   };
//   return { setFocus, ref };
// };

class InmueblesForm extends Form {
  constructor() {
    super();
    //   super(props);
    this.closeButtonRef = React.createRef();
  }

  title = "Ficha de Inmueble";

  serviceFolder = "inmuebles";
  config = getConfig();
  filesUrl = this.config.filesUrl + "/" + this.serviceFolder + "/";

  breadcrumbs = [
    {
      title: "Inicio",
      link: "/main",
    },
    {
      title: "Inmuebles",
      link: "/Inmuebles",
    },
    {
      title: "Ficha de Inmueble",
    },
  ];

  validateForm = true;
  //   representantesFormat = [
  //     { label: "Cargo", width: "20%", column: "cargo", type: "combo", options: tiposCargos },
  //     { label: "Apellido", width: "15%", column: "apellido", type: "text", options: [] },
  //     { label: "Nombre", width: "15%", column: "nombre", type: "text", options: [] },
  //     { label: "Teléfono ", width: "15%", column: "telefono", type: "phone", options: [] },
  //     { label: "Documento ", width: "5%", column: "tipoDocumento", type: "combo", options: tiposDocumentos },
  //     { label: "Nº", width: "15%", column: "documento", type: "dni", options: [] },
  //   ];

  //   telefonosFormat = [
  //     { label: "Tipo", width: "20%", column: "tipo", type: "combo", options: tiposTelefonos },
  //     { label: "Nº", width: "15%", column: "numero", type: "phone", options: [] },
  //   ];

  state = {
    force: 1,
    menuLink: "/inmuebles",
    action: "C",
    dragId: "",
    listAmbientes: [],
    listServicios: [],
    // representante: {
    //   apellido: "",
    //   nombre: "",
    //   cargo: tiposCargos[0].value,
    //   tipoDocumento: tiposDocumentos[0].value,
    //   telefono: "",
    //   documento: "",
    // },
    // telefono: {
    //   tipo: tiposTelefonos[0].value,
    //   numero: "",
    // },
    editingImage: null,
    imgFile: null,
    archivo: {
      titulo: "",
      archivo: "",
    },
    data: {
      direccion: "",
      nro: "",
      piso: "",
      dto: "",
      localidad: "",
      ubicacion: "",
      estado: "",
      fotos: [],
      ambientes: [],
      servicios: [],
      dormitorios: "",
      cantidadAmbientes: "",
      banos: "",
      plantas: "",
      toilette: false,
    },
    errors: {},
  };

  schema = {};

  preSchema = {
    _id: Joi.string(),
    direccion: Joi.string()
      .label("Dirección")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar una dirección" };
          }
        });
      }),
    nro: Joi.string().allow(""),
    piso: Joi.string().allow(""),
    dto: Joi.string().allow(""),
    localidad: Joi.any(),
    estado: Joi.any(),
    tipos: Joi.array(),
    fotos: Joi.array(),
    ambientes: Joi.array(),
    servicios: Joi.array(),
    dormitorios: Joi.any(),
    ubicacion: Joi.any(),
    cantidadAmbientes: Joi.any(),
    banos: Joi.any(),
    plantas: Joi.any(),
    toilette: Joi.any(),
  };

  postSchema = {
    _id: Joi.string(),
    direccion: Joi.string()
      .label("Dirección")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar una dirección" };
          }
        });
      }),
    nro: Joi.string().allow(""),
    piso: Joi.string().allow(""),
    dto: Joi.string().allow(""),
    dormitorios: Joi.number().integer().allow("").allow(null),
    cantidadAmbientes: Joi.number().integer().allow("").allow(null),
    banos: Joi.number().integer().allow("").allow(null),
    plantas: Joi.number().integer().allow("").allow(null),
    toilette: Joi.boolean(),
    localidad: Joi.string()
      .label("Localidad")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "any.empty":
              return { message: "Debe ingresar una localidad" };
          }
        });
      }),
    estado: Joi.string(),
    tipos: Joi.array()
      .label("Tipo de Inmueble")
      .required()
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Debe ingresar algún tipo de inmueble" };
        });
      }),
    fotos: Joi.array(),
    ambientes: Joi.array(),
    servicios: Joi.array(),
    ubicacion: Joi.string().allow(""),
  };

  render() {
    const { action, listAmbientes, listServicios } = this.state;
    const { _id, direccion, nro, piso, dto, localidad, fotos, ambientes, servicios, dormitorios, cantidadAmbientes, banos, toilette, estado } = this.state.data;

    // console.log(this.config.filesUrl);
    // console.log(this.serviceFolder);

    if (this.state.data._id) this.schema = this.postSchema;
    else this.schema = this.preSchema;
    if (fotos) {
      fotos.sort((a, b) => {
        if (a.orden < b.orden) {
          return -1;
        }
        if (a.orden > b.orden) {
          return 1;
        }
        return 0;
      });
    }

    return (
      <React.Fragment>
        {/* Content Header (Page header) */}

        {this.renderContentHeader(this.title, this.breadcrumbs, this.goBack)}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="card card-secondary ">
                  <div className="card-header card-outline card-outline-tabs">
                    <h3 className="card-title">
                      <RoundButton onClick={() => this.goBack()} iconClass="fas fa-arrow-left" className="btn-transparent mr-2" tooltip="Volver" />
                      Ficha de Inmueble
                    </h3>
                  </div>
                  <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="general-tab" data-toggle="pill" href="#general" role="tab" aria-controls="general" aria-selected="true">
                          General
                        </a>
                      </li>
                      {this.state.data._id && (
                        <>
                          <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">
                              Alquiler
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill" href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false">
                              Venta
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-four-settings-tab" data-toggle="pill" href="#custom-tabs-four-settings" role="tab" aria-controls="custom-tabs-four-settings" aria-selected="false">
                              Datos Adicionales
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      <div className="tab-content" id="custom-tabs-four-tabContent">
                        <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                          <div className="row">
                            <div className="col-2 text-center">
                              {this.state.data._id && (
                                <>
                                  <DraggableGallery
                                    action={action}
                                    items={fotos}
                                    uploadPath={this.filesUrl + _id}
                                    onRearrange={(fotos) => this.onRearrange(fotos)}
                                    onDeleteImage={(e, index) => {
                                      this.onDeleteImage(e, index);
                                    }}
                                    onEditImage={(e, index) => {
                                      this.onEditImage(e, index);
                                    }}
                                  />
                                  {(action === "E" || action === "A") && (
                                    <div>
                                      <FileUploadContainer uploadingImages="true" onUpload={(files) => this.onUploadImages(_id, files, this.serviceFolder, "fotos")} multiple={true} />
                                    </div>
                                  )}
                                </>
                              )}
                              {action === "C" && fotos && fotos.length === 0 && <img style={{ width: "inherit" }} src="/dist/img/sinimagen.jpg"></img>}
                            </div>
                            <div className="col-10">
                              <ReactTabHotkeyReplacer>
                                <div className="row">
                                  <div className="col-8">
                                    {this.renderInput("direccion", "Direccion", "text", {
                                      float: true,
                                    })}
                                  </div>
                                  <div className="col-2">
                                    {this.renderInput("nro", "Nº", "text", {
                                      float: true,
                                    })}
                                  </div>
                                  <div className="col-1">
                                    {this.renderInput("piso", "Piso", "text", {
                                      float: true,
                                    })}
                                  </div>
                                  <div className="col-1">
                                    {this.renderInput("dto", "Dto", "text", {
                                      float: true,
                                    })}
                                  </div>
                                </div>
                                {this.state.data._id && (
                                  <>
                                    <div className="row">
                                      <div className="col-6">
                                        {this.renderInput("localidad", "Localidad", "asyncCombo", {
                                          float: true,
                                          // formatCreateLabel: "Agregar nueva localidad: ",
                                          // onCreateOption: (value) => this.onCreateLocalidad(value),
                                          loadOptions: localidadesPromiseOptions,
                                        })}
                                      </div>
                                      <div className="col-6">
                                        {this.renderInput("tipos", "Tipo de Inmueble", "asyncCombo", {
                                          float: true,
                                          defaultOptions: true,
                                          isMulti: true,
                                          loadOptions: tiposPromiseOptions,
                                        })}
                                      </div>
                                    </div>
                                    <div className="row align-items-center">
                                      <div className="col-1">
                                        {this.renderInput("dormitorios", "Dorm.", "integer", {
                                          float: true,
                                        })}
                                      </div>
                                      <div className="col-1">
                                        {this.renderInput("cantidadAmbientes", "Amb.", "integer", {
                                          float: true,
                                        })}
                                      </div>
                                      <div className="col-1">
                                        {this.renderInput("banos", "Baños", "integer", {
                                          float: true,
                                        })}
                                      </div>
                                      <div className="col-1">
                                        {this.renderInput("plantas", "Plantas", "integer", {
                                          float: true,
                                        })}
                                      </div>
                                      <div className="col-1">{this.renderInput("toilette", "Toilette", "toggle")}</div>
                                      <div className="col-6">
                                        {this.renderInput("estado", "Estado", "asyncCombo", {
                                          float: true,
                                          // formatCreateLabel: "Agregar nuevo Estado: ",
                                          // onCreateOption: (value) => this.onCreateLocalidad(value),
                                          loadOptions: estadosPromiseOptions,
                                        })}
                                      </div>
                                    </div>
                                    <div className="row">
                                      {this.renderInput("ubicacion", "Ubicación", "combo", {
                                        float: true,
                                        columns: 2,
                                        options: ubicaciones,
                                      })}
                                    </div>
                                    <div className="row">
                                      <PillList label="Ambientes" action={action} options={listAmbientes} placeholder="Agregar abmiente..." pillList={ambientes} onChange={(list) => this.onChangeAmbientes(list)} />
                                    </div>
                                    <div className="row">
                                      <PillList label="Servicios" action={action} options={listServicios} placeholder="Agregar servicio..." pillList={servicios} onChange={(list) => this.onChangeServicios(list)} />
                                    </div>
                                  </>
                                )}
                              </ReactTabHotkeyReplacer>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab"></div>
                        <div className="tab-pane fade" id="custom-tabs-four-messages" role="tabpanel" aria-labelledby="custom-tabs-four-messages-tab"></div>
                        <div className="tab-pane fade" id="custom-tabs-four-settings" role="tabpanel" aria-labelledby="custom-tabs-four-settings-tab"></div>
                      </div>
                    </div>
                    <div className="card-footer">{this.renderSubmit(this.state.data._id ? "Guardar" : "Continuar")}</div>
                  </form>
                  {/* /.card */}
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
          <div id="modal-imgedit" className="modal fade">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body justify-content-center">
                  <ImageEditor url={this.state.editingImage} id={_id} imgFile={this.state.imgFile} onSave={this.onSaveImage} onClose={this.onCloseModal} />
                </div>
                <div className="modal-footer justify-content-between d-none">
                  <button type="button" ref={this.closeButtonRef} className="btn btn-default" data-dismiss="modal">
                    Cerrar
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.handleSaveChild}
                  >
                    Guardar
                  </button> */}
                </div>
              </div>
              {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
          </div>
          {/* /.modal */}
        </section>
      </React.Fragment>
    );
  }

  getDataFromServer = async (recordId) => {
    return await getInmueble(recordId);
  };

  getExtraData = async (record) => {
    // const estados = await getEstadosSelect();
    const allAmbientes = await getAmbientesSelect();
    if (record.ambientes && record.ambientes.length > 0) {
      const listAmbientes = allAmbientes.filter((ambiente) => !record.ambientes.some((item) => item.value === ambiente.value));
      this.setState({ listAmbientes });
    } else this.setState({ listAmbientes: allAmbientes });
    const allServicios = await getServiciosSelect();
    if (record.servicios && record.servicios.length > 0) {
      const listServicios = allServicios.filter((servicio) => !record.servicios.some((item) => item.value === servicio.value));
      this.setState({ listServicios });
    } else this.setState({ listServicios: allServicios });
    return;
  };

  onUploadImages = async (recordId, uploadData, serviceFolder, subfolder) => {
    console.log(recordId);
    console.log(serviceFolder);
    console.log(subfolder);
    console.log(uploadData);
    const files = await uploadImages(recordId, serviceFolder, subfolder, uploadData);
    const data = { ...this.state.data };
    const fotos = data.fotos;
    if (files) {
      // console.log(files);
      files.forEach((foto) => {
        const found = fotos.find((item) => item.archivo === foto.archivo);
        if (!found) {
          fotos.push({ archivo: foto.archivo, orden: fotos.length + 1 });
        }
      });
      data.fotos = fotos;
      this.setState({ data });
    }
  };

  onEditImage = async (e, editIndex) => {
    e.preventDefault();
    this.setState({ editingImage: this.filesUrl + this.state.data._id + "/fotos/" + this.state.data.fotos[editIndex].archivo, imgFile: this.state.data.fotos[editIndex].archivo });
  };

  onSaveImage = async (id, imgFile, editedImageObject) => {
    const response = await updateImages(id, this.serviceFolder, "fotos", imgFile, editedImageObject.imageBase64);
    const { data } = this.state;
    const originalFotos = [...this.state.data.fotos];
    data.fotos = originalFotos;
    this.setState({ data });
  };

  onDeleteImage = async (e, deleteIndex) => {
    e.preventDefault();
    const { data } = this.state;
    const originalFotos = [...this.state.data.fotos];
    const fotos = originalFotos.filter((value, index) => index !== deleteIndex);
    data.fotos = fotos;
    this.setState({ data });
  };

  onCloseModal = () => {
    this.setState({ editingImage: null });
    this.closeButtonRef.current.click();
  };

  onRearrange = (fotos) => {
    const { data } = this.state;
    data.fotos = fotos;
    this.setState({ data });
  };

  onChangeAmbientes = (list) => {
    const data = { ...this.state.data };
    data.ambientes = list;
    this.setState({ data });
  };

  onChangeServicios = (list) => {
    const data = { ...this.state.data };
    data.servicios = list;
    this.setState({ data });
  };

  mapToViewModel = (inmueble) => {
    let tipos = [];
    inmueble.tipos.forEach((tipo) => {
      tipos.push({ value: tipo._id, label: tipo.descripcion });
    });
    return {
      _id: inmueble._id,
      direccion: inmueble.direccion,
      nro: inmueble.nro,
      piso: inmueble.piso,
      dto: inmueble.dto,
      dormitorios: inmueble.dormitorios,
      cantidadAmbientes: inmueble.cantidadAmbientes,
      banos: inmueble.banos,
      banos: inmueble.plantas,
      toilette: inmueble.toilette,
      ubicacion: inmueble.ubicacion
        ? {
            value: inmueble.ubicacion,
            label: inmueble.ubicacion,
          }
        : { label: "", value: "" },
      localidad: inmueble.localidad
        ? {
            value: inmueble.localidad._id,
            label: localidadCompleta(inmueble),
          }
        : { label: "", value: "" },
      estado: inmueble.estado
        ? {
            value: inmueble.estado._id,
            label: inmueble.estado.descripcion,
          }
        : { label: "", value: "" },
      tipos: tipos,
      fotos: inmueble.fotos,
      ambientes: inmueble.ambientes,
      servicios: inmueble.servicios,
    };
  };

  prepareData = () => {
    const { data } = this.state;
    console.log(data);
    data.localidad = data.localidad.value;
    data.estado = data.estado.value;
    data.ubicacion = data.ubicacion.value ? data.ubicacion.value : "";
    this.setState({ data });
  };

  doSubmit = async () => {
    try {
      const newId = await saveInmueble(this.state.data);
      if (this.state.data._id) this.props.history.push("/inmuebles");
      else {
        if (newId) {
          const data = { ...this.state.data };
          this.setState({
            data: { ...data, _id: newId },
          });
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };
}

export default withRouter(InmueblesForm);

import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/personas";

export async function getPersonas() {
  try {
    const response = await http.get(serviceEndpoint);
    const records = response.data.records;
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getPersona(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function savePersona(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else {
    try {
      const response = await http.post(serviceEndpoint, data);
      return response.data.record._id;
    } catch (error) {
      if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
      else return null;
    }
  }
}

export async function deletePersona(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

// export async function uploadFilesPersona(id, subfolder, data) {
//   const newData = new FormData();
//   if (subfolder === "") subfolder = "__null";
//   data.forEach((file, index) => {
//     newData.append("file", file);
//   });
//   try {
//     const response = await http.post(serviceEndpoint + "/uploadFiles/" + id + "/" + subfolder.replace("/", "º"), newData);
//     return response.data.archivos;
//   } catch (error) {
//     if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
//     else return null;
//   }
// }

// export async function getFilesPersona(id, subfolder) {
//   try {
//     const response = await http.post(serviceEndpoint + "/getFiles/" + id, { subfolder: subfolder });
//     return response.data.archivos;
//   } catch (error) {
//     if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
//     else return null;
//   }
// }

// export async function renameFile(id, original, newName, subfolder) {
//   try {
//     const response = await http.post(serviceEndpoint + "/renameFile/" + id, { original: original, newName: newName, subfolder: subfolder });
//     return response.data.archivos;
//   } catch (error) {
//     if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
//     else return null;
//   }
// }

// export async function deleteFile(id, subfolder, file) {
//   if (subfolder === "") subfolder = "__null";
//   return await http.post(serviceEndpoint + "/deleteFile/" + id + "/" + subfolder.replace("/", "º"), {
//     archivo: file,
//   });
// }

// export async function createFolder(id) {
//   try {
//     const response = await http.post(serviceEndpoint + "/createFolder/" + id);
//     return response.data.archivos;
//   } catch (error) {
//     if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
//     else return null;
//   }
// }

export async function getPersonasSelect() {
  let personas = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((persona) => {
      personas.push({
        value: persona._id,
        label: persona.apellido + " " + persona.nombre,
      });
    });
    return personas;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export const personasPromiseOptions = (inputValue) =>
  http
    .get(serviceEndpoint, { params: { search: inputValue } })
    .then(function (response) {
      let options = response.data.records.map((persona) => ({
        value: persona._id,
        label: persona.apellido + " " + persona.nombre,
      }));
      return options;
    })
    .catch(function (error) {
      console.log(error);
    });

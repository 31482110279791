import http from "./httpService";
import { getConfig } from "../config/config.js";
const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/localidades";

export async function getLocalidades() {
  try {
    const response = await http.get(serviceEndpoint);
    return response.data.records;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function getLocalidad(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return null;
  }
}

export async function saveLocalidad(data) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else return await http.post(serviceEndpoint, data);
}

export async function deleteLocalidad(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

export async function getLocalidadesSelect() {
  let condiciones = [];
  try {
    const response = await http.get(serviceEndpoint);
    response.data.records.map((localidad) => {
      condiciones.push({ value: localidad._id, label: localidad.nombre });
    });
    return condiciones;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export const localidadesPromiseOptions = (inputValue) =>
  http
    .get(serviceEndpoint, { params: { search: inputValue } })
    .then(function (response) {
      let options = response.data.records.map((localidad) => ({
        value: localidad._id,
        label: localidad.nombre + ", " + localidad.provincia[0].nombre + ", " + localidad.pais[0].nombre,
      }));
      return options;
    })
    .catch(function (error) {
      console.log(error);
    });

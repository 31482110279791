import React, { useState, useEffect, useRef } from "react";

export default function DraggableGallery({ items, action, uploadPath, onRearrange, onDeleteImage, onEditImage }) {
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [fotos, setFotos] = useState(items);

  const handleDrag = (e, position) => {
    dragItem.current = position;
  };

  useEffect(() => {
    setFotos(items);
  }, [items]);

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyFotos = [...fotos];
    const dragItemContent = copyFotos[dragItem.current];
    copyFotos.splice(dragItem.current, 1);
    copyFotos.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFotos(copyFotos);
    onRearrange(copyFotos);
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          {fotos &&
            fotos.length > 0 &&
            fotos.map((foto, index) => {
              // var checkImg = new Image();
              // var exists = true;
              // checkImg.src = config.filesUrl + "/" + serviceFolder + "/" + _id + "/fotos/" + foto.archivo;
              // checkImg.onerror = function () {
              //   exists = false;
              //   fotos[index].archivo = null;
              // };
              // checkImg.onload = function () {
              if (index === 0) {
                if (action !== "C")
                  return (
                    <div key={Math.random()} className="col-12 p-1" draggable="true" onDragStart={(e) => handleDrag(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop}>
                      <img className="img-fluid" src={uploadPath + "/fotos/" + foto.archivo}></img>
                      <button
                        className="btn-circle imgUpButton bg-olive-disabled"
                        data-toggle="modal"
                        data-target="#modal-imgedit"
                        onClick={(e) => {
                          onEditImage(e, index);
                        }}
                      >
                        <i className="fas fa-paint-brush"></i>
                      </button>
                      <button
                        className="btn-circle imgButton bg-danger-disabled"
                        onClick={(e) => {
                          onDeleteImage(e, index);
                        }}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  );
                else
                  return (
                    <div key={Math.random()} className="col-12 p-1" draggable="false">
                      <img className="img-fluid" src={uploadPath + "/fotos/" + foto.archivo}></img>
                    </div>
                  );
              } else {
                if (action !== "C")
                  return (
                    <div key={Math.random()} className="col-6 p-1" draggable="true" onDragStart={(e) => handleDrag(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop}>
                      <img className="img-fluid" src={uploadPath + "/fotos/" + foto.archivo}></img>
                      <button
                        className="btn-circle btn-sm imgUpButton bg-olive-disabled"
                        data-toggle="modal"
                        data-target="#modal-imgedit"
                        onClick={(e) => {
                          onEditImage(e, index);
                        }}
                      >
                        <i className="fas fa-paint-brush"></i>
                      </button>
                      <button
                        className="btn-circle btn-sm imgButton bg-danger-disabled"
                        onClick={(e) => {
                          onDeleteImage(e, index);
                        }}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  );
                else
                  return (
                    <div key={Math.random()} className="col-6 p-1" draggable="false">
                      <img className="img-fluid" src={uploadPath + "/fotos/" + foto.archivo}></img>
                    </div>
                  );
              }
              // };
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

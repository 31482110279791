import http from "./httpService";
import { getConfig } from "../config/config.js";

const config = getConfig();
const serviceEndpoint = config.apiEndpoint + "/users";

export async function getUser(recordId) {
  try {
    const response = await http.get(serviceEndpoint + "/" + recordId);
    return response.data.record;
  } catch (error) {
    if (error.message.toUpperCase().includes("NETWORK ERROR")) return error.message.toUpperCase();
    else return [];
  }
}

export async function saveUser(data, parentId) {
  if (data._id) return await http.put(serviceEndpoint + "/" + data._id, data);
  else {
    data.parentId = parentId;
    return await http.post(serviceEndpoint, data);
  }
}

export async function deleteUser(recordId) {
  return await http.delete(serviceEndpoint + "/" + recordId);
}

export async function forgotPassword(data) {
  return await http.post(config.apiEndpoint + "/password/request", data);
}

export async function resetPassword(id, token, data) {
  return await http.post(config.apiEndpoint + "/password/reset/" + id + "/" + token, data);
}

import "./notAllowed.css";

import React from "react";

export default function NotAllowed(props) {
  const goHome = () => {
    props.history.replace("/");
  };

  return (
    <React.Fragment>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h3>Epa! ¿ Dónde vas ?</h3>
            <div>
              <img className="mt-2" src="/dist/img/notAllowed.png" style={{ height: "200px" }} />
            </div>
          </div>
          <h2>No tenés permiso para ir a esa página</h2>
          <div className="col text-center">
            <button className="mb-5 btn btn-secondary" onClick={goHome}>
              Volver
            </button>
          </div>{" "}
        </div>
      </div>
    </React.Fragment>
  );
}
